import axios from '../axios';

async function fetchUserProfileInfo(token: string) {
  try {
    const request = await axios.get(`/admin-users/fetch-user-profile`, {
      headers: { Authorization: token },
    });
    return request.data;
  } catch (err) {
    const error: any = err;
    console.error(error);
    return error.response;
  }
}

export { fetchUserProfileInfo };
