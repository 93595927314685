import React from 'react';
import styled from 'styled-components';
import RayButton from '../RayButton';
import { useAuthContext } from '../../utils/hooks/useAuthContext';
import { useOktaAuth } from '@okta/okta-react';

const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 0%;
  width: 85%;
  background-color: #fff;
  padding: 1.75em;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 1000;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  justify-content: flex-end;
  align-items: center;
`;

function Navbar() {
  const { userInfo } = useAuthContext()!;
  const { oktaAuth } = useOktaAuth();

  const logout = async () =>oktaAuth.signOut();

  return (
    <NavbarWrapper>
      <Info>
        <p>{userInfo && userInfo.email ? userInfo.email : 'NA'}</p>
        <a
          href="https://weworkhelpdesk.freshservice.com/a/tickets/new"
          target="_blank"
          rel="noopener noreferrer"
          className="ray-button ray-button--tertiary"
        >
          Support
        </a>
        <RayButton onClick={logout} buttonType="tertiary">
          Logout
        </RayButton>
      </Info>
    </NavbarWrapper>
  );
}
export default Navbar;
