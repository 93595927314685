import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const StyledSwitch = withStyles({
  switchBase: {
    color: '#B3B3B3',
    '&$checked': {
      color: '#1d00ff',
    },
    '&$checked + $track': {
      backgroundColor: '#cdccff',
    },
  },
  checked: {},
  track: {},
})(Switch);

export const BlueSwitch = withStyles({
  switchBase: {
    color: '#0000ff',
    '&$checked': {
      color: '#0000ff',
    },
    '&$checked + $track': {
      backgroundColor: '#0000ff',
    },
  },
  checked: {
    color: '#0000ff',
    '&$checked': {
      color: '#0000ff',
    },
    '&$checked + $track': {
      backgroundColor: '#0000ff',
    },
  },
  track: {},
})(Switch);

const RaySwitch = ({
  id,
  name,
  onChange,
  label,
  style,
  ref,
  className,
  checked,
  disabled = false,
  value,
}: Props) => {
  return (
    <FormControlLabel
      control={
        <StyledSwitch
          checked={checked}
          onChange={onChange}
          name={name}
          className={className}
          disabled={disabled}
          id={id}
          inputRef={ref}
          value={value}
          style={style}
        />
      }
      label={label}
    />
  );
};

export default RaySwitch;

type Props = {
  name?: string;
  label?: string;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  ref?: React.Ref<any>;
  style?: React.CSSProperties;
  id?: string;
  className?: string;
  disabled?: boolean;
  checked: boolean | undefined;
  value?: unknown;
};
