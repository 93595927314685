import { Grid, IconButton } from '@material-ui/core';
import { FormMultiProductInputField } from './types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import React from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import RenderFormItem from './RenderFormItem';
import RayButton from '../RayButton';

const FormMultipleProductInput = ({
  name,
  label,
  ...rest
}: FormMultiProductInputField) => {
  const { fieldArrayFields: productFields, meta } = rest;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });
  return (
    <Grid container item style={{ columnGap: '12px' }}>
      {productFields &&
        fields.map((item, index) => (
          <React.Fragment key={item.id}>
            <Grid item xs={5}>
              <RenderFormItem
                {...productFields[0].field1}
                name={`${name}[${index}].field1`}
              />
            </Grid>
            <Grid item xs={5}>
              <RenderFormItem
                {...productFields[0].field2}
                name={`${name}[${index}].field2`}
              />
            </Grid>
            <Grid item xs={1}>
              {index > 0 && (
                <IconButton
                  aria-label="remove"
                  onClick={() => remove(index)}
                  color="secondary"
                >
                  <RiDeleteBinLine />
                </IconButton>
              )}
            </Grid>
          </React.Fragment>
        ))}

      {meta && fields.length < meta?.maxProducts && (
        <Grid item xs={2}>
          <RayButton
            buttonType="secondary"
            onClick={() => append({ field1: '', field2: '' })}
            style={{ marginBottom: '20px' }}
          >
            {meta?.appendCTA}
          </RayButton>
        </Grid>
      )}
    </Grid>
  );
};

export default FormMultipleProductInput;
