import React from 'react';
import { format } from 'date-fns';
import { TLog } from '../';

type Props = {
  data: TLog;
};

function TableCell({ data }: Props) {
  return (
    <tr key={data.auditId} className="purchase_row">
      <td>{data?.location || '--'}</td>
      <td>{data?.buildingName || '--'}</td>
      <td>{data.activity}</td>
      <td>{data.actionedBy}</td>
      <td>{data.role}</td>
      <td>{data.status}</td>
      <td>{format(new Date(data.lastUpdatedAt), 'dd/MM/yyyy h:mm a')}</td>
      <td>
        <a target="_blank" rel="noreferrer" href={data.fileUrl}>
          Link
        </a>
      </td>
    </tr>
  );
}

export default TableCell;
