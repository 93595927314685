import { differenceInCalendarDays } from 'date-fns';
import React from 'react';
import { BsClockHistory } from 'react-icons/bs';

interface Props {
    name: string;
    voExpiryDate: number | string;
}

function UserNameComponent({ name, voExpiryDate }: Props) {
    if(differenceInCalendarDays(new Date(voExpiryDate), new Date()) <= 15 && differenceInCalendarDays(new Date(voExpiryDate), new Date()) >= -15) {
        return <>{name}<span style={{ verticalAlign: 'text-top', paddingLeft: '5px' }}><BsClockHistory color="red" /></span></>
    }
    return <>{name}</>
}

export default UserNameComponent;