import { ApolloClient, InMemoryCache } from '@apollo/client';

export const weworkClient = new ApolloClient({
  uri: process.env.REACT_APP_PUBLIC_SHOPIFY_STOREFRONT_API_ENDPOINT,
  cache: new InMemoryCache(),
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env
      .REACT_APP_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN
      ? process.env.REACT_APP_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN
      : '',
  },
});

export const myHqClient = new ApolloClient({
  uri: process.env.REACT_APP_MYHQ_SHOPIFY_STOREFRONT_API_ENDPOINT,
  cache: new InMemoryCache(),
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env
      .REACT_APP_MYHQ_SHOPIFY_STOREFRONT_API_TOKEN
      ? process.env.REACT_APP_MYHQ_SHOPIFY_STOREFRONT_API_TOKEN
      : '',
  },
});
