import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import RayButton from 'components/RayButton';
import { H3 } from 'components/Typography/Heading';
import CommonFilterComponent from 'components/FilterComponent';
import { Grid } from '@material-ui/core';
import { BuildingOption } from 'containers/Bookings/types';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import TableContainer from './TableContainer';
import { Wrapper } from 'containers/Bookings/styles';
// import { DropdownTypes } from './types';
import DialogModal from 'components/ModalDialog';
import ExportCSVForm from 'components/ExportCSVForm';
import { useVO } from 'utils/VOContext';
interface RefObj {
  refreshLoad: (page: number) => void;
}

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
`;

const VODashboard = () => {
  const {
    results,
    setResults,
    searchString,
    setSearchString,
    orderType,
    setOrderType,
    selectedDateRange,
    setSelectedDateRange,
    selectedBuilding,
    setSelectedBuilding,
    expiryMonthSelected,
    setExpiryMonthSelected,
    expiryYearSelected,
    setExpiryYearSelected,
    filterType,
    setFilterType,
    currentPage,
  } = useVO()!;
  const isDateRangeAllowed =
    (expiryMonthSelected?.value !== 'ALL' && expiryMonthSelected?.value) ||
    expiryYearSelected?.value;
  // ||
  // (selectedBuilding !== -1 ? selectedBuilding : undefined) ||
  // (orderType?.value !== 'ALL' && orderType?.value);

  const [showCSVModal, toggleCSVModal] = useState<boolean>(false);
  const [buildingsData] = useFetchRequest('/buildings/get-user-buildings');
  const childRef = useRef<RefObj>(null);

  const OrderOptions = [
    { value: 'ALL', label: 'ALL' },
    { value: 'PENDING', label: 'KYC Pending' },
    { value: 'COMPLETED', label: 'Incorporated' },
    { value: 'UNINCORPORATED', label: 'Unincorporated' },
    { value: 'DEREGISTRATION', label: 'Deregistration' },
    { value: 'CANCELLED', label: 'Cancelled' },
  ];

  const refetchData = (page: number) => {
    if (childRef.current) {
      childRef.current && childRef.current.refreshLoad(page);
    }
  };

  React.useEffect(() => {
    if (childRef.current) {
      childRef.current && childRef.current.refreshLoad(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeBuilding = (id: string) => {
    if (id) setSelectedBuilding(Number(id));
    else if (id === null) {
      setSelectedBuilding(-1);
    }
    refetchData(1);
  };

  const handleChange = (value: any, setState: React.SetStateAction<any>) => {
    // except search string and building change, all other filter changes go though here
    setState(value);
    refetchData(1);
  };

  return (
    <>
      <Helmet>
        <title>Virtual Office Documents</title>
        <meta
          name="description"
          content="WeWork On Demand Dashboard - Virtual Office Documents"
        />
      </Helmet>
      <ButtonRow>
        <H3 mb0>Virtual Office Bookings</H3>
        <RayButton buttonType="secondary" onClick={() => toggleCSVModal(true)}>
          Export Documents
        </RayButton>
      </ButtonRow>
      <FilterWrapper>
        <Grid item xs={12}>
          <CommonFilterComponent
            searchString={searchString}
            setSearchString={setSearchString}
            refetchData={refetchData}
            buildingList={
              buildingsData && buildingsData.length > 0
                ? buildingsData
                    .filter(({ disabled }: BuildingOption) => !disabled)
                    .concat({ id: -1, name: 'All' })
                : []
            }
            buildingOnChange={handleChangeBuilding}
            setOrderType={(value: any) => {
              handleChange(value, setOrderType);
            }}
            orderType={orderType}
            setSelectedDateRange={(value: any) => {
              handleChange(value, setSelectedDateRange);
            }}
            selectedDateRange={selectedDateRange}
            setResults={(value: any) => {
              handleChange(value, setResults);
            }}
            results={results}
            OrderOptions={OrderOptions}
            page="VirtualOffice"
            setExpiryMonthSelected={(value: any) => {
              handleChange(value, setExpiryMonthSelected);
            }}
            expiryMonthSelected={expiryMonthSelected}
            setExpiryYearSelected={(value: any) => {
              handleChange(value, setExpiryYearSelected);
            }}
            expiryYearSelected={expiryYearSelected}
            filterType={filterType}
            setFilterType={(value: any) => {
              handleChange(value, setFilterType);
            }}
          />
        </Grid>
      </FilterWrapper>
      <Wrapper>
        <TableContainer
          queryType={orderType && orderType.value ? orderType.value : 'ALL'}
          hits={results}
          searchString={searchString}
          reference={childRef}
          selectedBuilding={selectedBuilding}
          expiryMonthSelected={expiryMonthSelected}
          expiryYearSelected={expiryYearSelected}
          filterType={filterType}
        />
      </Wrapper>
      <DialogModal
        isOpen={showCSVModal}
        fullWidth
        showModal={toggleCSVModal}
        maxWidth="sm"
        title="Export VO Documents"
      >
        <ExportCSVForm
          type="vo-bookings"
          toggleDetailModal={toggleCSVModal}
          secondaryText="Virtual Office Documents CSV"
          currentState="ALL"
          showDatePicker={!isDateRangeAllowed}
          buildingData={
            buildingsData && buildingsData.length > 0
              ? buildingsData.filter(
                  ({ disabled }: BuildingOption) => !disabled,
                )
              : []
          }
        />
      </DialogModal>
    </>
  );
};

export default VODashboard;
