import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  useTheme,
} from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';
import DropDownComponent from 'components/DropDown';
import NativeSelect from 'components/MaterialSelect/NativeSelect';
import { BuildingOption } from 'containers/Bookings/types';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { BsArrowDownShort } from 'react-icons/bs';
import { MdSearch } from 'react-icons/md';
import styled from 'styled-components';
import { useVO } from 'utils/VOContext';

const StyledWrapper = styled.div`
  .datePickerContainer {
    & .rdrDefinedRangesWrapper {
      width: 0;
    }
  }
  .button-field {
    text-align: end;
  }
  .apply-btn {
    background: rgb(0, 0, 255);
    color: #ffffff;
  }
  .cancel-btn {
    color: #0000ff;
  }
  .dropdown-class {
    width: 100%;
  }
`;

const hitsOptions = [
  {
    name: '10',
    value: 10,
  },
  {
    name: '25',
    value: 25,
  },
  {
    name: '50',
    value: 50,
  },
];

const initialTimePickerState = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    color: '#0000ff',
    showDateDisplay: true,
    autoFocus: true,
  },
];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthNameOptions = monthNames
  .map(month => {
    return { label: month, value: month };
  })
  .concat({ label: 'ALL', value: 'ALL' });

const exportCSV = [
  { label: 'Conference Room', value: 'conference-room-seat' },
  { label: 'Parking', value: 'parking' },
  { label: 'Gift cards', value: 'gift-card' },
  { label: 'Printing', value: 'printing' },
  { label: 'Lost keycard', value: 'lost-keycard' },
  { label: 'Custom events', value: 'custom-events' },
  { label: 'Postpaid events', value: 'postpaid-events' },
  { label: 'Security deposit', value: 'security-deposit' },
  { label: 'Event Space', value: 'event-space' },
  { label: 'All', value: '' },
];

const filters = [
  { label: 'Email id', value: 'primaryUser' },
  { label: 'Order id', value: 'latestOrderNumber' },
  { label: 'All', value: 'All' },
];

const CommonFilterComponent = ({
  searchString,
  setSearchString,
  refetchData,
  buildingList,
  buildingOnChange,
  orderType,
  setOrderType,
  setSelectedDateRange,
  selectedDateRange,
  results,
  setResults,
  OrderOptions,
  page,
  setExpiryMonthSelected,
  expiryMonthSelected,
  expiryYearSelected,
  setExpiryYearSelected,
  setExportType,
  exportType,
  checked,
  setChecked,
  filterType,
  setFilterType,
}: any) => {
  const theme = useTheme();
  const [showDatePickerModal, setShowDatePickerModal] = useState<boolean>(
    false,
  );
  const {
    buildingSelected,
    setBuildingSelected,
    setSelectedBuilding: setSelectedBuildingVO,
    setSearchString: setSearchStringVO,
    setResults: setResultsVO,
    setExpiryMonthSelected: setExpiryMonthSelectedVO,
    setExpiryYearSelected: setExpiryYearSelectedVO,
    setOrderType: setOrderTypeVO,
    setSelectedDateRange: setSelectedDateRangeVO,
    setCurrentPage,
  } = useVO()!;

  useEffect(() => {
    if (page !== 'VirtualOffice') reset();
    return () => {
      page !== 'VirtualOffice' && reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const reset = () => {
    setResultsVO(10);
    setSearchStringVO('');
    setBuildingSelected(null);
    setSelectedBuildingVO(-1);
    setExpiryMonthSelectedVO(null);
    setExpiryYearSelectedVO(null);
    setOrderTypeVO(null);
    setSelectedDateRangeVO([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: '#0000ff',
        showDateDisplay: true,
        autoFocus: true,
      },
    ]);
    setCurrentPage(1);
  };
  const currentYear = new Date().getUTCFullYear();

  useEffect(() => {
    if (buildingSelected) {
      buildingOnChange(buildingSelected.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingSelected]);

  const renderTextSearchField = (placeholder: string) => (
    <TextField
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      id="search"
      name="search"
      className="searchbar"
      onChange={e =>
        setSearchString(
          e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
        )
      }
      type="text"
      onKeyDown={ev => {
        if (ev.key === 'Enter') {
          refetchData();
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MdSearch color="#979797" />
          </InputAdornment>
        ),
      }}
      value={searchString}
    />
  );

  const renderFilterField = (placeholder: string, options: Array<any>, selectedOption: any, setSelectedOption: any) => (
    <DropDownComponent
      placeHolder={placeholder}
      options={options}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
    />
  );

  return (
    <StyledWrapper>
      <Grid
        container
        spacing={page === 'VirtualOffice' ? 0 : 2}
        alignItems="center"
      >
        {page === 'VirtualOffice' ? 
        <>
          <Grid
            container
            item
            xs={4}
            alignItems="center"
            style={{marginBottom: "12px"}}
          >
            {renderTextSearchField('Search by name, email, order id or company')}
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={2}>
              {renderFilterField("Filter On", filters, filterType, setFilterType)}
            </Grid>

            <Grid
              item
              xs={2}
            >
              {renderFilterField("Expiry Month", monthNameOptions, expiryMonthSelected, setExpiryMonthSelected)}
            </Grid>

            <Grid item xs={2}>
              {renderFilterField("Expiry Year", [-1, 0, 1, 2].map(item => ({
                  label: currentYear - item,
                  value: currentYear - item,
                })), expiryYearSelected, setExpiryYearSelected)}
            </Grid>

            <Grid item xs={2}>
              {renderFilterField("Building", buildingList &&
                buildingList.map((building: BuildingOption) => ({ label: building.name, value: building.id }
                )), buildingSelected, setBuildingSelected)}
            </Grid>

            <Grid item xs={2}>
              {renderFilterField("Order Status", OrderOptions, orderType, setOrderType)}
            </Grid>

            <Grid item xs={2}>
              <NativeSelect
                label="Show Results"
                onChange={e => {
                  setResults(Number(e.target.value));
                }}
                value={results}
                options={hitsOptions}
                fullWidth
                defaultValue={hitsOptions[0]?.value}
                className="dropdown-class"
              />
            </Grid>
          </Grid>
        </> : 
        <>
          {page === 'ShopPurchases' && (
          <Grid item xs={6}>
            <TextField
              placeholder="Search by name, email, company or invoice number"
              variant="outlined"
              fullWidth
              id="search"
              name="search"
              className="searchbar"
              onChange={e =>
                setSearchString(
                  e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                )
              }
              type="text"
              onKeyDown={ev => {
                if (ev.key === 'Enter') {
                  refetchData();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch color="#979797" />
                  </InputAdornment>
                ),
              }}
              value={searchString}
            />
          </Grid>
        )}
        {page !== 'ShopPurchases' && (
          <Grid
            container
            item
            xs={page === 'ShopPurchases' ? 3 : 4}
            spacing={1}
          >
            <Grid item xs={12}>
              <TextField
                placeholder={`Search by name, email or ${
                        page === 'ConferenceRoomReservations'
                          ? 'location'
                          : 'company'
                      }`
                }
                variant="outlined"
                fullWidth
                id="search"
                name="search"
                className="searchbar"
                onChange={e =>
                  setSearchString(
                    e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                  )
                }
                type="text"
                onKeyDown={ev => {
                  if (ev.key === 'Enter') {
                    refetchData();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdSearch color="#979797" />
                    </InputAdornment>
                  ),
                }}
                value={searchString}
              />
            </Grid>
          </Grid>
        )}
        <Grid container item xs={page === 'ShopPurchases' ? 9 : 6} spacing={1}>
          {page === 'ShopPurchases' && (
            <Grid item xs={3}>
              <DropDownComponent
                placeHolder="Export Document"
                options={exportCSV}
                selectedOption={exportType}
                setSelectedOption={setExportType}
              />
            </Grid>
          )}
          {(page === 'DayPassBooking' ||
            page === 'ConferenceRoomReservations') && (
            <Grid
              item
              container
              xs={3}
              style={{ position: 'relative', height: '100%' }}
            >
              <TextField
                placeholder="Date"
                onClick={() => setShowDatePickerModal(true)}
                variant="outlined"
                value={
                  format(selectedDateRange[0].startDate, 'dd/MM') +
                  '-' +
                  format(selectedDateRange[0].endDate, 'dd/MM')
                }
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <BsArrowDownShort color="#979797" />
                    </InputAdornment>
                  ),
                }}
              />
              {showDatePickerModal && page !== 'ShopPurchases' && (
                <Grid
                  className="datePickerContainer"
                  style={{
                    position: 'absolute',
                    top: '70px',
                    left: 0,
                    width: 'fit-content',
                    backgroundColor: 'white',
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={3}
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Start Date"
                        disabled={true}
                        value={selectedDateRange[0].startDate.toDateString()}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Ending Date"
                        disabled={true}
                        value={selectedDateRange[0].endDate.toDateString()}
                      />
                    </Grid>
                  </Grid>
                  <DateRangePicker
                    className="rangeHighlighter"
                    startDatePlaceholder="Start Date"
                    endDatePlaceholder="End Date"
                    rangeColors={[theme.palette.primary.main]}
                    ranges={selectedDateRange}
                    onChange={item => setSelectedDateRange([item.selection])}
                    inputRanges={[]}
                    staticRanges={[]}
                    showDateDisplay={false}
                    moveRangeOnFirstSelection={false}
                    showMonthAndYearPickers={false}
                    dragSelectionEnabled={true}
                    showPreview={true}
                    months={2}
                    direction="horizontal"
                  />
                  <Grid item className="button-field">
                    <Button
                      variant="text"
                      className="cancel-btn"
                      onClick={() => {
                        setSelectedDateRange(initialTimePickerState);
                        setShowDatePickerModal(false);
                        refetchData();
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      className="apply-btn"
                      onClick={() => {
                        refetchData();
                        setShowDatePickerModal(false);
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={6}>
            <DropDownComponent
              placeHolder="Building"
              options={
                buildingList &&
                buildingList.map((building: BuildingOption) => {
                  return { label: building.name, value: building.id };
                })
              }
              selectedOption={buildingSelected}
              setSelectedOption={setBuildingSelected}
            />
          </Grid>
          <Grid item xs={3}>
            <DropDownComponent
              placeHolder={'Order Type'}
              options={OrderOptions}
              selectedOption={orderType}
              setSelectedOption={setOrderType}
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <NativeSelect
            label="Show Results"
            onChange={e => {
              setResults(Number(e.target.value));
            }}
            value={results}
            options={hitsOptions}
            fullWidth
            defaultValue={hitsOptions[0]?.value}
            className="dropdown-class"
          />
        </Grid>
        <Grid item xs={1}>
          {page === 'ShopPurchases' && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={() => setChecked((prev: boolean) => !prev)}
                  />
                }
                label={'B2B'}
              />
            </FormGroup>
          )}
        </Grid>
      </>}
      </Grid>
    </StyledWrapper>
  );
};

export default CommonFilterComponent;
