import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  useTheme,
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { BsArrowDownShort } from 'react-icons/bs';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

const initialTimePickerState = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    color: '#0000ff',
    showDateDisplay: true,
    autoFocus: true,
  },
];

export type initialTimePickerStateType = typeof initialTimePickerState;

const StyledWrapper = styled.div`
  .datePickerContainer {
    z-index: 10;
    & .rdrDefinedRangesWrapper {
      width: 0;
    }
  }
  .button-field {
    text-align: end;
  }
  .apply-btn {
    background: rgb(0, 0, 255);
    color: #ffffff;
  }
  .cancel-btn {
    color: #0000ff;
  }
  .dropdown-class {
    width: 100%;
  }
`;

type RangePickerProps = {
  selectedDateRange: initialTimePickerStateType;
  setSelectedDateRange: React.Dispatch<
    React.SetStateAction<initialTimePickerStateType>
  >;
};
const RangePicker = ({
  selectedDateRange,
  setSelectedDateRange,
}: RangePickerProps) => {
  const theme = useTheme();

  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const queryClient = useQueryClient();

  return (
    <StyledWrapper>
      <Grid item container style={{ position: 'relative', height: '100%' }}>
        <TextField
          placeholder="Date"
          label="Date Range"
          onClick={() => setShowDatePickerModal(true)}
          variant="outlined"
          value={
            format(selectedDateRange[0].startDate, 'dd/MM') +
            '-' +
            format(selectedDateRange[0].endDate, 'dd/MM')
          }
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <BsArrowDownShort color="#979797" />
              </InputAdornment>
            ),
          }}
        />
        {showDatePickerModal && (
          <Grid
            className="datePickerContainer"
            style={{
              position: 'absolute',
              top: '70px',
              left: 0,
              width: 'fit-content',
              backgroundColor: 'white',
            }}
          >
            <Grid
              container
              item
              xs={12}
              spacing={3}
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Start Date"
                  disabled={true}
                  value={selectedDateRange[0].startDate.toDateString()}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Ending Date"
                  disabled={true}
                  value={selectedDateRange[0].endDate.toDateString()}
                />
              </Grid>
            </Grid>
            <DateRangePicker
              className="rangeHighlighter"
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
              rangeColors={[theme.palette.primary.main]}
              ranges={selectedDateRange}
              onChange={item => {
                const startDate = item.selection.startDate || new Date();
                const endDate = item.selection.endDate || new Date();
                setSelectedDateRange([
                  {
                    startDate,
                    endDate,
                    key: item.selection.key || 'selection',
                    color: item.selection.color || '#0000ff',
                    showDateDisplay:
                      item.selection.showDateDisplay !== undefined
                        ? item.selection.showDateDisplay
                        : true,
                    autoFocus:
                      item.selection.autoFocus !== undefined
                        ? item.selection.autoFocus
                        : true,
                  },
                ]);
              }}
              inputRanges={[]}
              staticRanges={[]}
              showDateDisplay={false}
              moveRangeOnFirstSelection={false}
              showMonthAndYearPickers={false}
              dragSelectionEnabled={true}
              showPreview={true}
              months={2}
              direction="horizontal"
            />
            <Grid item className="button-field">
              <Button
                variant="text"
                className="cancel-btn"
                onClick={() => {
                  setSelectedDateRange(initialTimePickerState);
                  setShowDatePickerModal(false);
                  queryClient.invalidateQueries(['inventory']);
                }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                className="apply-btn"
                onClick={() => {
                  queryClient.invalidateQueries(['inventory']);
                  setShowDatePickerModal(false);
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </StyledWrapper>
  );
};

export default RangePicker;
