import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import axios from 'utils/axios';
import styled from 'styled-components';
import DropDownComponent from 'components/DropDown';
import RayInput from 'components/RayInput';
import React, { useEffect, useMemo, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import RayButton from 'components/RayButton';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import {
  format,
  getMonth,
  getYear,
  getDaysInMonth,
  setMonth,
  differenceInDays,
} from 'date-fns';
import { useOktaAuth } from '@okta/okta-react';

type Month = {
  label: string;
  value: string;
  year: number;
  monthNumber: number;
};

type ExportType = { label: string; value: string };

type DateRanger = {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  key?: string | undefined;
  color?: string | undefined;
  showDateDisplay?: boolean | undefined;
  autoFocus?: boolean | undefined;
};

const exportTypes: ExportType[] = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Date Range',
    value: 'date-range',
  },
];

const initDateRange: DateRanger = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
  color: '#0000ff',
  showDateDisplay: true,
  autoFocus: true,
};

const RootWrapper = styled.div`
  .layout {
    margin-top: 20px;
    display: flex;
    gap: 100px;
    width: 100%;
  }
  .fields-wrapper {
    margin-top: 20px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .ray-text-field__label {
      z-index: 0;
    }
    .ray-text-field {
      border: 1px solid hsl(0, 0%, 80%);
      height: 48px;
    }
  }
  .helper-text {
    font-size: 14px;
    color: #808080;
  }
  .error {
    font-size: 16px;
    font-weight: bold;
    margin-top: -6px !important;
    color: red;
  }
  .export-btn-wrapper {
    margin-top: 40px;
  }

  .rangePicker {
    margin-bottom: -10px;
  }

  .rangeHighlighter {
    .rdrInRange {
      color: #8d8dff !important;
    }
  }
  .rdrCalendarWrapper {
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 8px;
  }
  .rdrDefinedRangesWrapper {
    width: 0;
    border: none;
  }
  .rdrMonthAndYearWrapper {
    height: none;
    padding-top: 0;
    border-bottom: 1px solid hsl(0, 0%, 80%);
    margin-bottom: 10px;
  }
  .rdrMonth {
    padding: 0px;
  }
  .rdrDayToday .rdrDayNumber {
    span {
      &:after {
        content: '';
        width: 0;
      }
    }
  }
  .rangeHighlighter {
    .rdrInRange {
      color: #8d8dff !important;
    }
  }
`;

const customDropdownStyles = {
  control: (base: any, state: any) => ({
    ...base,
    height: 48,
    backgroundColor: state.isSelected ? '#0000ff' : 'white',
    color: state.isSelected ? 'white' : 'black',
  }),
};

const startMonthIndex = 9;
const years = [
  2020,
  2021,
  2022,
  2023,
  2024,
  2025,
  2026,
  2027,
  2028,
  2029,
  2030,
];

const getMonths = (): Month[] => {
  const months: Month[] = [];
  const currentDate = Date.now();

  // Retrives the months starting from current month, year to Oct, 2020 in descending order
  for (let yr = years.length - 1; yr >= 0; yr--) {
    if (years[yr] > getYear(currentDate)) continue;

    // Loop initialize logic: For current year, take months from Jan to current month otherwise take all months

    // Loop condition logic: For start year i.e 2020, take month from Oct to Dec otherwise take all months
    for (
      let month = years[yr] < getYear(currentDate) ? 11 : getMonth(currentDate);
      month >= (yr === 0 ? startMonthIndex : 0);
      month--
    ) {
      // Pushed all months from Oct, 2020 to currentMonth, currentYear to months dropdown array
      const monthEndDate = getDaysInMonth(new Date(years[yr], month));
      const date = new Date();
      date.setDate(monthEndDate);
      date.setMonth(month);

      const fullMonthName = format(date, 'MMMM');
      months.push({
        label: `${fullMonthName}, ${years[yr]}`,
        value: `${month}_${years[yr]}`,
        year: years[yr],
        monthNumber: month + 1,
      });
    }
  }

  return months;
};

const ShopifyOrders = () => {
  const { userInfo, setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();
  const theme = useTheme();

  const [months, setMonths] = useState<Month[]>([]);
  const [exportType, setExportType] = useState<ExportType>(exportTypes[0]);
  const [selectedMonth, setSelectedMonth] = useState<Month>(months[0]);
  const [selectedDateRange, setSelectedDateRange] = useState<[DateRanger]>([
    initDateRange,
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setMonths(getMonths());
  }, []);

  useEffect(() => {
    if (months.length > 0) handleSelectedMonth(months[0]);
  }, [months]);

  const setSelectedOption = (selectedOptionObj: ExportType) => {
    setExportType(selectedOptionObj);
    if (selectedOptionObj.value === 'date-range') {
      setSelectedDateRange([initDateRange]);
    } else {
      setSelectedMonth(months[0]);
      handleSelectedMonth(months[0]);
    }
  };

  const handleSelectedMonth = (selectedMonthObj: Month) => {
    setSelectedMonth(selectedMonthObj);
    const { year, monthNumber } = selectedMonthObj;
    const endDate = getDaysInMonth(
      setMonth(new Date(year, monthNumber - 1), monthNumber - 1),
    );

    const dateObj = {
      startDate: new Date(
        `${monthNumber < 10 ? `0${monthNumber}` : monthNumber}/01/${year}`,
      ),
      endDate: new Date(
        `${
          monthNumber < 10 ? `0${monthNumber}` : monthNumber
        }/${endDate}/${year}`,
      ),
      key: 'selection',
      color: '#0000ff',
      showDateDisplay: true,
      autoFocus: true,
    };
    setSelectedDateRange([dateObj]);
  };

  const handleExport = async () => {
    try {
      setIsLoading(true);
      const body = {
        startDate: selectedDateRange[0].startDate,
        endDate: selectedDateRange[0].endDate,
        email: userInfo?.email,
        type: 'order-reports',
      };
      await axios.post('/shop/exports', body, {
        params: {
          type: 'order-reports',
        },
        headers: {
          authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: `CSV sent to ${userInfo?.email}`,
      });
      if (exportType.value === 'monthly') {
        handleSelectedMonth(months[0]);
      } else {
        setSelectedDateRange([initDateRange]);
      }
    } catch (err) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Something went wrong while exporting. Please try again',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const daysDiff = useMemo(() => {
    return differenceInDays(
      new Date(selectedDateRange[0].endDate as Date),
      new Date(selectedDateRange[0].startDate as Date),
    );
  }, [selectedDateRange]);

  return (
    <RootWrapper>
      <Grid>
        To download the Shopify report, please select your report type and click
        on "Export"
      </Grid>
      <Grid className="fields-wrapper">
        <Grid>
          <Grid>Export Type</Grid>
          <DropDownComponent
            placeHolder="Select export type"
            options={exportTypes}
            selectedOption={exportType}
            setSelectedOption={setSelectedOption}
            styles={customDropdownStyles}
          />
        </Grid>
        <Grid>
          {exportTypes[0].value === exportType.value && (
            <>
              <Grid>Month</Grid>
              <DropDownComponent
                placeHolder="Select export type"
                options={months}
                selectedOption={selectedMonth}
                setSelectedOption={handleSelectedMonth}
                styles={customDropdownStyles}
              />
            </>
          )}
          {exportTypes[1].value === exportType.value && (
            <Grid className="rangePicker">
              <DateRangePicker
                className="rangeHighlighter"
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                rangeColors={[theme.palette.primary.main]}
                ranges={selectedDateRange}
                onChange={item => {
                  setSelectedDateRange([item.selection]);
                }}
                inputRanges={[]}
                staticRanges={[]}
                showDateDisplay={false}
                moveRangeOnFirstSelection={false}
                minDate={new Date(`${startMonthIndex + 1}/01/${years[0]}`)}
                maxDate={new Date()}
                showMonthAndYearPickers={true}
                dragSelectionEnabled={true}
                showPreview={false}
              />
              {daysDiff >= 31 && (
                <p className="error">You can only export orders upto 31 days</p>
              )}
            </Grid>
          )}
        </Grid>
        <Grid>
          <Grid>Email</Grid>
          <RayInput
            placeholder="arya.stark@wework.co.in"
            id="email__id"
            fullWidth
            autoMargin={false}
            value={userInfo.email}
            disabled={true}
          />
          <Grid className="helper-text">
            Bookings CSV will be sent to {userInfo?.email}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <RayButton
            fullWidth
            onClick={handleExport}
            disabled={isLoading || daysDiff >= 31}
          >
            Export
          </RayButton>
        </Grid>
      </Grid>
    </RootWrapper>
  );
};

export default ShopifyOrders;
