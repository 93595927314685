import React, { useState, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import ModalDialog from 'components/ModalDialog';
import ViewReservationDetails from './ViewReservationDetails';

import { makeStyles } from '@material-ui/core/styles';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { Pagination } from '@material-ui/lab';
import Loader from 'components/Loader';
import axios from 'utils/axios';

import { DropdownProps } from '../Bookings/types';
import { format, set } from 'date-fns';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthContext } from 'utils/hooks/useAuthContext';

const Wrapper = styled.div`
  width: 100%;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  table {
    font-size: 14px;
    width: 100%;
  }
  tr.booking_row:hover {
    cursor: pointer;
  }
  .no-bookings {
    margin-top: 20%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .user-annotation-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }
  .yellow-box {
    background: #efa942;
    color: black;
  }
  .green-box {
    background: #12872a;
    color: white;
  }
  .user-annotation-banner {
    display: flex;
    margin-left: 5px;
  }

  .user-annotation-banner > span {
    vertical-align: middle;
    height: 20px;
    width: 42px;
    align-items: center;
    padding: 0px 5px;
    border-radius: 3px;
  }
  .user-annotation-sticker {
    display: flex;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  className?: string;
  bookingsType: string;
  setBookingsTypeCount: React.Dispatch<number>;
  searchString: string;
  reference: any;
  buildingId?: number;
  hits: number;
  orderType: DropdownProps | null;
  selectedDateRange: any;
  fetchReservations: (buildingId: number, conferenceRoomId?: string) => void;
  buildingsMap: any;
};

export type InvoiceData = {
  pdfURL: string | null;
  typeOfInvoice: string | null;
  isDiscountedOrder: boolean | null;
  GSTINValidity: string | null;
  checkoutUserEmail: string | null;
};

const formatToDesiredDate = (epochDate: any): string => {
  const dateObj = new Date(epochDate);
  return format(dateObj, 'hh:mm a');
};

const TableContainer = React.forwardRef(
  ({
    bookingsType,
    setBookingsTypeCount,
    hits,
    searchString,
    reference,
    buildingId,
    orderType,
    selectedDateRange,
    fetchReservations,
  }: Props) => {
    const classes = useStyles();
    const { useAlgolia } = useAuthContext()!;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { data, isLoading, isFetching, refetch } = useReactQuery(
      ['confRoomBookings', bookingsType, hits, currentPage],
      {
        url: useAlgolia
          ? '/orders/dashboard-conference-bookings'
          : '/api/v1/admin/conference-rooms/reservations',
        params: {
          bookingType: bookingsType,
          searchString,
          buildingId,
          isB2bOrder: orderType === null ? 'nil' : orderType.label,
          ...(useAlgolia
            ? {
                pageNo: currentPage,
                pageSize: hits,
                reservationsStartDate: selectedDateRange[0].startDate.getTime(),
                reservationsEndDate: set(selectedDateRange[0].endDate, {
                  hours: 23,
                  minutes: 59,
                  seconds: 59,
                }).getTime(),
              }
            : {
                pageNumber: currentPage - 1,
                limit: hits,
                bookingStartDate: selectedDateRange[0].startDate.getTime(),
                bookingEndDate: set(selectedDateRange[0].endDate, {
                  hours: 23,
                  minutes: 59,
                  seconds: 59,
                }).getTime(),
              }),
        },
      },
      {
        enabled: !!bookingsType,
        // keepPreviousData: true,
      },
    );
    useEffect(() => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
      if (buildingId) {
        refetch();
      }
      refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hits, buildingId]);
    const [detailModal, toggleDetailModal] = useState<boolean>(false);
    const [selectedBooking, setSelectedBooking] = useState<any>();
    const [isFutureReservation, setIsFutureResrrvation] = useState<any>();
    const { authState } = useOktaAuth();
    const [invoiceResponse, setInvoiceResponse] = useState<InvoiceData>({
      GSTINValidity: null,
      isDiscountedOrder: null,
      pdfURL: null,
      typeOfInvoice: null,
      checkoutUserEmail: null,
    });
    useImperativeHandle(reference, () => ({
      refreshLoad() {
        setCurrentPage(1);
        setTimeout(() => {
          refetch();
        }, 1000);
      },
    }));
    useEffect(() => {
      if (!isLoading) {
        setBookingsTypeCount(
          useAlgolia ? data.data.nbHits : data.data.pagination.count,
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading]);
    const handleBookingRowClick = async (cell: any) => {
      setSelectedBooking(cell);
      setIsFutureResrrvation(Number(cell.bookingDate) > Number(Date.now()));
      // Reset Previous State.
      setInvoiceResponse({
        pdfURL: null,
        isDiscountedOrder: null,
        GSTINValidity: null,
        typeOfInvoice: null,
        checkoutUserEmail: null,
      });
      if (!cell.is_b2b_order && cell.orderId) {
        try {
          const { data } = await axios.get('/invoices/admin/invoices', {
            params: {
              order_id: cell.orderId,
            },
            headers: {
              Authorization: authState?.accessToken?.accessToken,
            },
          });

          const { data: invoiceData } = data;
          setInvoiceResponse(invoiceData as InvoiceData);
          toggleDetailModal(true);
          return;
        } catch (error) {
          console.log('Error Fetching invoice info', error);
        }
      }
      toggleDetailModal(true);
    };

    const handlePageChange = (
      event: React.ChangeEvent<unknown>,
      pageNo: number,
    ) => {
      setCurrentPage(pageNo);
    };
    if (isLoading || isFetching) {
      return <Loader customHeight="75vh" />;
    }
    return (
      <>
        <Wrapper>
          {data.data && data.data.hits && data.data.hits.length > 0 && (
            <table className="ray-table">
              <thead>
                <tr>
                  <th>Start Time</th>
                  <th>User Name</th>
                  <th>Location</th>
                  <th>Hours</th>
                  <th>Room</th>
                  <th>Members</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(
                  data.data.hits.map((cell: any) => (
                    <tr
                      className="booking_row"
                      onClick={() => handleBookingRowClick(cell)}
                    >
                      <td>
                        {cell.bookingDate
                          ? formatToDesiredDate(cell.bookingDate)
                          : '--'}
                      </td>
                      <u>{`${cell.customerName ? cell.customerName : '--'}`}</u>
                      <td>{cell.buildingName ? cell.buildingName : '--'}</td>
                      <td>{cell.slotLength ? cell.slotLength : '--'}</td>
                      <td>{cell.name ? cell.name : '--'}</td>
                      <td>{cell.memberCount ? cell.memberCount : '--'}</td>
                      <td>{cell.customerEmail ? cell.customerEmail : '--'}</td>
                    </tr>
                  )),
                )}
              </tbody>
            </table>
          )}
          {!isLoading &&
            data &&
            data.data.hits &&
            data.data.hits.length === 0 && (
              <p className="no-bookings">
                No bookings available for the selected filter
              </p>
            )}
          {data &&
            (useAlgolia
              ? data?.data?.nbPages
              : data?.data?.pagination?.totalPages) > 0 && (
              <PaginationWrapper>
                <Pagination
                  count={
                    useAlgolia
                      ? data?.data?.nbPages
                      : data?.data?.pagination?.totalPages
                  }
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                  classes={{ ul: classes.ul }}
                />
              </PaginationWrapper>
            )}
        </Wrapper>
        <ModalDialog
          isOpen={detailModal}
          showModal={toggleDetailModal}
          fullWidth
          maxWidth="md"
          title="Booking Info"
        >
          <ViewReservationDetails
            reservationInfo={selectedBooking}
            isUpComing={isFutureReservation}
            fetchReservations={fetchReservations}
            invoiceResponse={invoiceResponse}
          />
        </ModalDialog>
      </>
    );
  },
);

export default TableContainer;

const useStyles = makeStyles(() => ({
  ul: {
    '& .Mui-selected': {
      backgroundColor: '#0000ff',
      color: '#fff',
    },
  },
}));
