import React from 'react';
import InvoiceOMS from './InvoiceOMS';
import InvoiceShopify from './InvoiceShopify';

const InvoiceSection = ({ purchaseData, isCancelledOrder }) => {
  return (
    <>
      {purchaseData.order_id ? (
        <InvoiceOMS order_id={purchaseData.order_id} />
      ) : (
        <InvoiceShopify
          purchaseData={purchaseData}
          isCancelledOrder={isCancelledOrder}
        />
      )}
    </>
  );
};

export default InvoiceSection;
