import React, { useEffect, useState } from 'react';
import { useReactQueryNew } from 'utils/hooks/useReactQuery';
import InventoryRow from './InventoryRow';
import ShopPagination from 'containers/ShopPage/pagination';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Loader from 'components/Loader';
import { PaginationResponse } from 'containers/ShopPage/types';
import { handleAxiosError } from 'utils/errorHelper';
import { initialTimePickerStateType } from './RangePicker';
import { format } from 'date-fns';
import { Grid } from '@material-ui/core';
import { defaultPagination } from 'utils/constants';

export type PaginationObject = {
  direction: string;
  cursor: string;
};

type InventoryTableProps = {
  selectedBuilding: any;
  selectedProduct: any;
  selectedDateRange: initialTimePickerStateType;
};

type ResponseData = {
  records: InventoryItem[];
  pagination: PaginationResponse;
};

export type InventoryItem = {
  uid: string;
  date: string;
  available_capacity: number;
  committed_capacity: number;
  increased_capacity: number;
  decreased_capacity: number;
  reason?: string;
};

const InventoryTable = ({
  selectedBuilding,
  selectedProduct,
  selectedDateRange,
}: InventoryTableProps) => {
  const [pagination, setPagination] = useState<PaginationObject>(
    defaultPagination,
  );
  const { setToastType } = useAuthContext()!;

  useEffect(() => {
    setPagination(defaultPagination);
  }, [selectedDateRange]);

  const {
    isLoading: selectedProductLoading,
    isError: selectedProductError,
    data: selectedProductData,
  } = useReactQueryNew<ResponseData>(
    [
      'inventory',
      selectedBuilding?.value,
      selectedProduct?.product_uid,
      pagination.direction,
      pagination.cursor,
      selectedDateRange[0].startDate,
      selectedDateRange[0].endDate,
    ],
    {
      url: `/api/v1/admin/daywise-inventories`,
      params: {
        size: 10,
        direction: pagination.direction,
        cursor: pagination.cursor,
        product_uid: selectedProduct?.product_uid,
        ...(selectedDateRange &&
          format(selectedDateRange[0].startDate, 'yyyy-MM-dd') !==
            format(selectedDateRange[0].endDate, 'yyyy-MM-dd') && {
            start_date: format(selectedDateRange[0].startDate, 'yyyy-MM-dd'),
            end_date: format(selectedDateRange[0].endDate, 'yyyy-MM-dd'),
          }),
      },
    },
    {
      enabled: !!selectedProduct?.product_uid,
      onError: (error: any) => {
        const errorMessage = handleAxiosError(
          error,
          'Something went wrong when fetching inventory',
        );
        setToastType({
          show: true,
          message: errorMessage,
          type: 'error',
        });
        return;
      },
    },
  );

  if (selectedProductLoading) return <Loader />;

  if (selectedProductError)
    return (
      <Grid container justifyContent="center" alignItems="center" xs={12}>
        <p className="error">Something went wrong when fetching inventory</p>
      </Grid>
    );

  if (selectedProductData?.data?.records?.length === 0)
    return (
      <Grid container justifyContent="center" alignItems="center" xs={12}>
        Inventory not added, please add inventory to perform operations on the
        same.
      </Grid>
    );

  return (
    <>
      <table className="ray-table">
        <thead>
          <tr>
            <th className="center" style={{ width: '10%' }}>
              Date
            </th>
            <th className="center" style={{ width: '15%' }}>
              Available capacity
            </th>
            <th className="center" style={{ width: '15%' }}>
              Increase capacity
            </th>
            <th className="center" style={{ width: '15%' }}>
              Decrease capacity
            </th>
            <th className="center" style={{ width: '20%' }}>
              Reason
            </th>
            <th className="center" style={{ width: '10%' }}>
              Price
            </th>
          </tr>
        </thead>

        {selectedProductData?.data?.records?.map(
          (item: InventoryItem, index) => (
            <InventoryRow
              index={index}
              item={item}
              // price={selectedProduct?.price}
              key={item.uid}
            />
          ),
        )}
      </table>
      {selectedProductData?.data && (
        <ShopPagination
          setPagination={setPagination}
          pagination={selectedProductData?.data?.pagination}
        />
      )}
    </>
  );
};

export default InventoryTable;
