import React from 'react';
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { MdClose } from 'react-icons/md';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function DialogModal({
  isOpen,
  children,
  showModal,
  title = 'Modal Title',
  maxWidth = 'sm',
  fullWidth = false,
  ...props
}: DialogProps) {
  return (
    <Dialog
      onClose={() => showModal(false)}
      aria-labelledby={`${title}-dialog-title`}
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      {...props}
    >
      <DialogTitle
        id={`${title}-dialog-title`}
        onClose={() => showModal(false)}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}

type DialogProps = {
  isOpen: boolean;
  children: React.ReactNode;
  showModal: (x: boolean) => void;
  title?: string;
  maxWidth?: DialogMaxWidth;
  fullWidth?: boolean;
};

type DialogMaxWidth = 'lg' | 'sm' | 'md' | 'xl' | 'xs' | false;
