import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

type Props = {
  error?: FieldError;
};

const FormError = ({ error }: Props) => {
  return (
    <ErrorWrapper className={`error-field ${error && 'active'}`}>
      {error?.message}
    </ErrorWrapper>
  );
};

export default FormError;

const ErrorWrapper = styled.div`
  visibility: hidden;
  margin-top: 2px;
  font-size: 0.75rem;
  height: 20px;
  color: #d32f2f;
  &.active {
    visibility: visible;
  }
`;
