import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "utils/hooks/useAuthContext";
import DialogModal from "components/ModalDialog";
import RequestSection from "./RequestSection";
import WarningSection from "./WarningSection";
import {
  VirtualOfficeDocument,
  MainDirector,
} from "containers/VirtualOfficePage/types";
import {
  PlanDetails,
  ExtraPlanDetails,
  changeStatus,
  RequestBody as ChangeStatusRequestBody,
} from "../ApiCalls/ChangeStatus";
import { isAfter } from "date-fns";
import { useOktaAuth } from "@okta/okta-react";

type Props = {
  showModal: boolean;
  documentData: VirtualOfficeDocument;
  toggleOpenModal: React.Dispatch<boolean>;
};

function UpdateDocumentStatus({
  showModal,
  toggleOpenModal,
  documentData,
}: Props) {
  const { push: changeRoute } = useHistory();
  const queryClient = useQueryClient();

  const {  setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const [selectedRequestType, setSelectedRequestType] = useState<string | null>(
    null
  );
  const [selectedDirector, setSelectedDirector] = useState<
    MainDirector | undefined
  >(undefined);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState<
    (PlanDetails & ExtraPlanDetails) | undefined
  >(undefined);
  const [modalSection, setModalSection] = useState<string>("REQUEST_SECTION");

  const { isLoading, mutate } = useMutation(changeStatus, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("voDocuments");
      queryClient.invalidateQueries("vo-document-detail");
      toggleOpenModal(false);
      selectedRequestType === "COMPLETE_RESET" && changeRoute("/vo-bookings");
    },
    onError: (error: any) => {
      const errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : "Error occurred while updating status of VO Document";
      setToastType({ show: true, type: "error", message: errorMessage });
    },
  });

  useEffect(() => {
    if (!showModal) {
      setModalSection("REQUEST_SECTION");
      setSelectedRequestType(null);
      setSelectedPlanDetails(undefined);
    }
  }, [showModal]);

  const triggerRequest = async () => {
    const body: ChangeStatusRequestBody = {
      documentId: documentData.id,
      requestType: selectedRequestType || "",
      authToken: (authState?.accessToken?.accessToken) || "",
    };
    if (selectedRequestType === "OVERRIDE_PAYMENT")
      body.planDetails = selectedPlanDetails;
    if (selectedRequestType === "MANUAL_AGREEMENT")
      body.directorDetails = selectedDirector;
    mutate(body);
  };

  return (
    <DialogModal
      fullWidth
      title="Change Status"
      maxWidth="sm"
      isOpen={showModal}
      showModal={toggleOpenModal}
    >
      {modalSection === "REQUEST_SECTION" && (
        <RequestSection
          selectedRequestType={selectedRequestType}
          selectedPlanDetails={selectedPlanDetails}
          selectedDirector={selectedDirector}
          directors={
            documentData && documentData.directors ? documentData.directors : []
          }
          setSelectedRequestType={setSelectedRequestType}
          setSelectedPlanDetails={setSelectedPlanDetails}
          setModalSection={setModalSection}
          setSelectedDirector={setSelectedDirector}
          isDocumentExpired={isAfter(new Date(), new Date(documentData?.expiresOn || ''))}
        />
      )}
      {modalSection === "WARNING_SECTION" && (
        <WarningSection
          setModalSection={setModalSection}
          triggerRequest={triggerRequest}
          loading={isLoading}
        />
      )}
    </DialogModal>
  );
}

export default UpdateDocumentStatus;
