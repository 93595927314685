import { Grid, InputAdornment, TextField } from '@material-ui/core';
import DialogModal from 'components/ModalDialog';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import styled from 'styled-components';
import axios from 'utils/axios';
import ConferenceRoomsTable from './ConferenceRoomsTable';
import EditConferenceRoomForm from './EditConferenceRoomForm';
import { useOktaAuth } from '@okta/okta-react';

function ConferenceRoomsPage() {

  const limit = 10;

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalState, toggleModal] = useState<boolean>(false);

  const [roomsData, setRoomsData] = useState<any>([]);
  const [roomsLoader, setRoomsLoader] = useState(true);
  const [roomsError, setRoomsError] = useState<any>('');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<string | null>(null);

  const [searchString, setSearchString] = useState<string>("")

  const [searching, setSearching] = useState(false)

const {authState} = useOktaAuth();
  const selectConferenceRoom = (data: any) => {
    setSelectedItem(data);
    toggleModal(true);
  };

  const fetchRooms = async (pageNo?: any, searched?: boolean) => {
    const queryString = !(searched || searching) ? `/shop/get-all-conference-rooms?page=${
      pageNo ? pageNo : 1
    }&limit=${limit}` : `/shop/get-all-conference-rooms?page=${
      pageNo ? pageNo : 1
    }&limit=${limit}&buildingName=${searchString}`;
    setRoomsLoader(true);
    try {
      const token = authState?.accessToken?.accessToken;
      const response = await axios.get(queryString, {
        headers: { Authorization: token },
      });
      const data = response.data;
      setRoomsData(data);
      if(data?.data?.page)
        setCurrentPage(data?.data?.page)
      //eslint-disable-next-line
      if(data?.data?.totalPages) {
        setLastPage(data?.data?.totalPages)
      }
      setRoomsError('');
      setRoomsLoader(false);
    } catch (error) {
      setRoomsError(error);
      setRoomsLoader(false);
      setRoomsData([]);
    }
  };

  useEffect(() => {
    fetchRooms(currentPage)
    //eslint-disable-next-line
  }, [currentPage]);

  const updateRoomsData = async () => {
    fetchRooms(currentPage)
  }

  const refetchData = async () => {
    if(!searchString) {
      setSearching(false)
      return fetchRooms(1)
    }
    setSearching(true)
    fetchRooms(1, true);
  }

  const handleSearch = (ev: any) => { 
    if (ev.key === "Enter") {
      if(!searchString) return refetchData()
      if(searchString.trim()) return refetchData()
    }
  }

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid className='text-field-parent' item xs={12} md={8} lg={4}>
            <TextField
              placeholder={`Search by Building's name`}
              variant="outlined"
              fullWidth
              id="search"
              name="search"
              className="searchbar"
              value={searchString}
              onChange={e => setSearchString(e.target.value.replace( /[*|":<>[\]{}`\\()';&$=]/g,
              '',))}
              type="text"
              onKeyDown={(ev) => handleSearch(ev)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch color="#979797" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <ConferenceRoomsTable
            selectRoom={selectConferenceRoom}
            roomsData={roomsData?.data}
            roomsLoader={roomsLoader}
            roomsError={roomsError}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            lastPage={lastPage}
          />
        </Grid>
      </Grid>
      <DialogModal
        isOpen={modalState}
        title="Update Conference Room"
        maxWidth="md"
        fullWidth
        showModal={toggleModal}
      >
        <EditConferenceRoomForm
          toggleModal={toggleModal}
          selectedValue={selectedItem}
          updateRoomsData={updateRoomsData}
        />
      </DialogModal>
    </Wrapper>
  );
}

export default ConferenceRoomsPage;

const Wrapper = styled.div`
  .searchbar {
    margin-bottom: 10px;
  }
  .text-field-parent {
    margin-left: auto;
  }
`;