import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { useParams } from 'react-router';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import Loader from 'components/Loader';
import { H2 } from 'components/Typography';
import { Grid } from '@material-ui/core';
import RayButton from 'components/RayButton';
import RayInput from 'components/RayInput';
import CustomInput from './CustomInput';
import DownloadUploadButtons from './DownloadUploadButtons';
import styled from 'styled-components';
import RaySwitch from 'components/RaySwitch';
import { useOktaAuth } from '@okta/okta-react';

export interface BuildingObject {
  name: string;
  city: string;
  micromarket: string;
  disabled?: boolean;
  virtualOfficeContractAddress: string;
  parking_disabled: boolean;
  conference_rooms_disabled: boolean;
  display_on_vo: boolean;
  display_on_od: boolean;
  show_covid_guidelines: boolean;
  isBookingEnabled: boolean;
}

const BuildingDetailsPage = () => {
  const { id } = useParams<Param>();
  const [loader, setLoader] = useState<boolean>(false);
  const { setToastType, userInfo } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const [buildingData, setBuildingData] = useState<BuildingObject | null>(null);
  const [
    virtualOfficeContractAddress,
    setVirtualOfficeContractAddress,
  ] = useState<string>('');
  const [displayOnVO, setDisplayOnVO] = useState<boolean>(false);
  const [isBookingEnabled, setIsBookingEnabled] = useState<boolean>(true);
  const [parkingDisabled, setParkingDisabled] = useState<boolean>(true);
  const [confRoomDisabled, setConfRoomDisabled] = useState<boolean>(true);
  const [displayOnOD, setDisplayOnOD] = useState<boolean>(true);
  const [showCovidGuidelines, setShowCovidGuidelines] = useState<boolean>(
    false,
  );
  const [data, fetchLoader, error] = useFetchRequest(
    'buildings/fetch-building-info',
    {
      building_id: id,
    },
  );

  useEffect(() => {
    if (data && data.buildingInfo) {
      setBuildingData(data.buildingInfo);
    }

    if (buildingData) {
      const {
        virtualOfficeContractAddress,
        display_on_vo,
        show_covid_guidelines,
        isBookingEnabled,
        parking_disabled,
        conference_rooms_disabled,
        display_on_od: displayOnOnDemand,
      } = buildingData;

      setDisplayOnOD(displayOnOnDemand ? displayOnOnDemand : false);
      if (virtualOfficeContractAddress) {
        setVirtualOfficeContractAddress(
          buildingData.virtualOfficeContractAddress,
        );
      }
      if (display_on_vo === true || display_on_vo === false)
        setDisplayOnVO(display_on_vo);

      if (show_covid_guidelines === true || show_covid_guidelines === false)
        setShowCovidGuidelines(show_covid_guidelines);

      if (isBookingEnabled === true || isBookingEnabled === false)
        setIsBookingEnabled(isBookingEnabled);

      if (parking_disabled === true || parking_disabled === false)
        setParkingDisabled(parking_disabled);
      if (
        conference_rooms_disabled === true ||
        conference_rooms_disabled === false
      )
        setConfRoomDisabled(conference_rooms_disabled);
    }
  }, [data, buildingData]);

  if (fetchLoader) return <Loader maxHeight />;
  if (error) return <p>Error fetching records</p>;

  const handleDisplayOnVOChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (userInfo.is_super_admin || userInfo.vo_admin) {
      if (event.target.value === 'false') {
        setDisplayOnVO(true);
      } else if (event.target.value === 'true') {
        setDisplayOnVO(false);
      }
    }
  };

  const handleIsBookingEnabled = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.value === 'true'
      ? setIsBookingEnabled(false)
      : setIsBookingEnabled(true);

  const handleShowCovidGuidelined = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    event.target.value === 'true'
      ? setShowCovidGuidelines(false)
      : setShowCovidGuidelines(true);

  const handleParkingChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.value === 'true'
      ? setParkingDisabled(false)
      : setParkingDisabled(true);

  const handleConfRoomChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.value === 'true'
      ? setConfRoomDisabled(false)
      : setConfRoomDisabled(true);

  const handleDisplayOnODChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    event.target.value === 'true'
      ? setDisplayOnOD(false)
      : setDisplayOnOD(true);

  const handleSubmit = async () => {
    setLoader(true);
    try {
      await axios.patch(
        '/buildings/update-building-details',
        {
          building_id: id,
          virtualOfficeContractAddress: virtualOfficeContractAddress,
          displayOnVO: displayOnVO,
          show_covid_guidelines: showCovidGuidelines,
          isBookingEnabled,
          parking_disabled: parkingDisabled,
          conference_rooms_disabled: confRoomDisabled,
          displayOnOnDemand: displayOnOD,
        },
        { headers: { Authorization: authState?.accessToken?.accessToken } },
      );
      setToastType({
        type: 'success',
        message: 'Building details updated successfully.',
        show: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
      setLoader(false);
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message:       
          error?.response?.data?.message
            ? error.response.data.message
            : 'Error occurred updating the building details',
      });
      setLoader(false);
    }
  };

  return (
    <>
      {buildingData && (
        <Grid container>
          <Grid item xs={12}>
            <H2>Building Info</H2>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item xs={12}>
                <CustomInput
                  id="name"
                  label="Building Name"
                  value={buildingData.name}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInput id="city" label="City" value={buildingData.city} />
              </Grid>
              <Grid item xs={5}>
                <CustomInput
                  id="micromarket"
                  label="Micro market"
                  value={buildingData.micromarket}
                />
              </Grid>
              {!userInfo.isPartnerAdminUser && (userInfo.is_super_admin || userInfo.vo_admin) ? (
                <Grid item xs={12}>
                  <RayInput
                    id="vo_contract_address"
                    name="vo_contract_address"
                    label="Virtual Office Contract Address"
                    type="text"
                    value={virtualOfficeContractAddress}
                    onChange={e =>
                      setVirtualOfficeContractAddress(e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <CustomInput
                    id="vo_contract_address"
                    label="Virtual Office Contract Address"
                    value={virtualOfficeContractAddress}
                  />
                </Grid>
              )}
            </Grid>
            {!userInfo.isPartnerAdminUser && <><Grid item xs={12}>
              <RaySwitch
                id="display_on_od"
                label="Display on On Demand"
                checked={displayOnOD}
                value={displayOnOD}
                onChange={handleDisplayOnODChange}
              />
            </Grid>
              <Grid item xs={12}>
                <RaySwitch
                  id="parking_disabled"
                  label="Parking Active"
                  checked={!parkingDisabled}
                  value={parkingDisabled}
                  onChange={handleParkingChange}
                />
              </Grid>
              <Grid item xs={12}>
                <RaySwitch
                  id="conference_rooms_disabled"
                  label="Conference Room Active"
                  checked={!confRoomDisabled}
                  value={confRoomDisabled}
                  onChange={handleConfRoomChange}
                />
              </Grid>
              <Grid item xs={12}>
                <RaySwitch
                  id="display_on_vo"
                  label="Virtual Office Active"
                  checked={displayOnVO}
                  value={displayOnVO}
                  onChange={handleDisplayOnVOChange}
                />
              </Grid>
              <Grid item xs={12}>
                <RaySwitch
                  id="show_covid_guidelines"
                  label="Show Covid Guidelines"
                  checked={showCovidGuidelines}
                  value={showCovidGuidelines}
                  onChange={handleShowCovidGuidelined}
                />
              </Grid>
              <Grid item xs={12}>
                <RaySwitch
                  id="booking_enabled"
                  label="Enable Bookings"
                  checked={isBookingEnabled}
                  value={isBookingEnabled}
                  onChange={handleIsBookingEnabled}
                />
              </Grid> </>}

            <Grid container>
              <DownloadUploadButtons
                text="NOC"
                fileName="NOC"
                building_id={id}
              />
              <DownloadUploadButtons
                text="Electricity Bill"
                fileName="EB"
                building_id={id}
              />
              <DownloadUploadButtons
                text="Internet Bill"
                fileName="IB"
                building_id={id}
              />
             {!userInfo.isPartnerAdminUser && <Grid item xs={12}>
                <br />
                <StyledButton onClick={handleSubmit} disabled={loader}>
                  Save
                </StyledButton>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BuildingDetailsPage;

const StyledButton = styled(RayButton)`
  margin-top: 2em;
  padding: 1em 5em;
`;

type Param = {
  id: string;
};
