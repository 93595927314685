import axios from 'utils/axios';

const VO_NOTES_ROUTE = '/nest-api/virtual-offices-company-notes';

export const postComment = async ({ authState, noteObject }): Promise<any> => {
  const response = await axios.post(VO_NOTES_ROUTE, noteObject, {
    headers: { Authorization: authState?.accessToken?.accessToken },
  });
  return response.data;
};

export const deleteNote = async ({ authState, noteId }): Promise<any> => {
  const response = await axios.delete(`${VO_NOTES_ROUTE}/${noteId}`, {
    headers: { Authorization: authState?.accessToken?.accessToken },
  });
  return response.data;
};

export const editNote = async ({
  authState,
  noteId,
  noteContent,
}): Promise<any> => {
  const response = await axios.patch(
    `${VO_NOTES_ROUTE}/${noteId}`,
    { note_content: noteContent },
    { headers: { Authorization: authState?.accessToken?.accessToken } },
  );
  return response.data;
};

export const getNotes = async ({
  virtualOfficeId,
  authState,
}): Promise<any> => {
  const response = await axios.get(`${VO_NOTES_ROUTE}/${virtualOfficeId}`, {
    headers: { Authorization: authState?.accessToken?.accessToken },
  });
  return response.data.data;
};
