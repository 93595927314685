import { Grid, createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import RayButton from 'components/RayButton';
import { ThemeProvider } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useEffect, useState } from 'react';
import RayInput from 'components/RayInput';
import axiosCredit from 'utils/axiosCredit';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import moment from 'moment';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';
import { isAfter, startOfDay } from 'date-fns';
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
interface bundleType {
  type: string;
  expiryPeriod: number;
  current: number;
  effectiveDate: Date;
  futureExpiryPeriod?: number;
  willBeEffectiveFrom?: Date;
}

const DayPassBundlesPage = () => {
  const { setToastType } = useAuthContext()!;

  const { authState } = useOktaAuth();

  const { isLoading, error, data, refetch } = useQuery(
    'expiryPeriod',
    async () => {
      const response = await axiosCredit.get(
        '/api/v1/admin-dashboard/expiry-period',
        {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        },
      );
      return response.data.data;
    },
  );

  const [bundles, setBundles] = useState<bundleType[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!isLoading && !error && data) {
      const transformedData = data.map((bundle: bundleType) => ({
        type: bundle.type,
        expiryPeriod: bundle.expiryPeriod,
        current: bundle.expiryPeriod,
        effectiveDate: moment()
          .add(1, 'days')
          .toDate(),
        futureExpiryPeriod: bundle.futureExpiryPeriod,
        willBeEffectiveFrom: bundle.willBeEffectiveFrom,
      }));
      setBundles(transformedData);
    }
  }, [isLoading, error, data]);

  const updateBundle = (
    index: number,
    newExpiryPeriod: number,
    newEffectiveDate: Date,
  ) => {
    setBundles(prevBundles => {
      const updatedBundles = [...prevBundles];
      updatedBundles[index] = {
        ...updatedBundles[index],
        expiryPeriod: newExpiryPeriod,
        effectiveDate: newEffectiveDate,
      };
      return updatedBundles;
    });
  };

  //function to handle the update button click
  const handleUpdate = async (bundle: bundleType) => {
    const token = authState?.accessToken?.accessToken;
    setIsUpdating(true);
    try {
      await axiosCredit.put(
        '/api/v1/admin-dashboard/expiry-period',
        {
          bundleType: bundle.type,
          expiryPeriod: bundle.expiryPeriod,
          effectiveFrom: bundle.effectiveDate,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        },
      );
      setToastType({
        show: true,
        type: 'success',
        message: `Updated bundle expiry for ${convertToLowerCaseWithSpaces(
          bundle.type,
        )} bundle`,
      });
      refetch();
    } catch (err) {
      let error: any = err;
      const errorMessage =
        error?.response?.data?.error ||
        'Something went wrong while updating bundles.';
      setToastType({
        show: true,
        type: 'error',
        message: errorMessage,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  if (isLoading) return <Loader maxHeight />;
  if (error) return <p>Error fetching records</p>;

  return (
    <DayPassBundlesWrapper>
      <p className="heading">Day Pass Bundles Expiry</p>
      <table className="ray-table">
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>Type of day pass</th>
            <th style={{ textAlign: 'center' }}>Expiry period (in days)</th>
            <th style={{ textAlign: 'center' }}>Current</th>
            <th style={{ textAlign: 'center' }}>Effective date</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: 'center' }}>
          {bundles?.map((bundle, index) => (
            <tr className="purchase_row">
              <td>{convertToLowerCaseWithSpaces(bundle.type)}</td>
              <td className="expiry-td">
                <RayInput
                  placeholder="Enter expiry period"
                  id="expiry_date"
                  value={bundle.expiryPeriod}
                  type="text"
                  pattern="[0-9]"
                  label="Expiry period"
                  fullWidth
                  onChange={e => {
                    updateBundle(
                      index,
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : 0,
                      bundle.effectiveDate,
                    );
                  }}
                  className="expiry-input"
                />
              </td>
              <td>{bundle.current}</td>
              <td>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      inputVariant="outlined"
                      disableToolbar
                      variant="inline"
                      format="Do MMMM YYYY"
                      label="Select date"
                      value={bundle.effectiveDate}
                      onChange={e =>
                        updateBundle(
                          index,
                          bundle.expiryPeriod,
                          moment(e).toDate() as Date,
                        )
                      }
                      disablePast
                      shouldDisableDate={date => {
                        // Disable today's date
                        if (date === null) return false;
                        return moment(date).isSame(moment(), 'day');
                      }}
                      autoOk
                      // maxDate={addDays(new Date(), 29)}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </td>
              <td>
                <RayButton
                  type="button"
                  buttonType="primary"
                  onClick={() => handleUpdate(bundle)}
                  disabled={
                    bundle.expiryPeriod === bundle.current ||
                    isUpdating ||
                    bundle.expiryPeriod === 0
                  }
                >
                  {isUpdating ? 'Updating...' : 'Update'}
                </RayButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="note-container">
        {bundles.map((bundle, index) =>
          (!bundle.futureExpiryPeriod && !bundle.willBeEffectiveFrom) ||
          (bundle.willBeEffectiveFrom &&
            !isAfter(
              new Date(bundle.willBeEffectiveFrom),
              startOfDay(new Date()),
            )) ? (
            <></>
          ) : (
            <React.Fragment key={index}>
              <p>
                The expiry period for the{' '}
                <span className="highlight">
                  {convertToLowerCaseWithSpaces(bundle.type)}
                </span>{' '}
                will be changed to{' '}
                <span className="highlight">{bundle.futureExpiryPeriod}</span>{' '}
                days from the date:{' '}
                <span className="highlight">
                  {moment(bundle.willBeEffectiveFrom).format('DD/MM/YYYY')}
                </span>
              </p>
              {index !== bundles.length - 1 && <hr className="separator" />}
            </React.Fragment>
          ),
        )}
      </div>
    </DayPassBundlesWrapper>
  );
};

export default DayPassBundlesPage;

const DayPassBundlesWrapper = styled(Grid)`
  padding: 2rem;
  .heading {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  td,
  th {
    vertical-align: middle; /* Center vertically */
  }
  .expiry-td {
    display: flex;
    justify-content: center;
  }
  .expiry-input {
    width: 50%;
  }
  .note-container {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
    margin-top: 30px;
  }
  .separator {
    border: none;
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }
  .highlight {
    color: #006600;
    font-weight: 600;
  }

  .note-container:empty {
    display: none;
  }

  .note-container p:first-of-type:not(:only-of-type) {
    margin-bottom: 10px;
  }

  .note-container p:first-of-type:not(:only-of-type) ~ hr {
    display: block;
  }

  .note-container hr {
    display: none;
  }
`;

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#0000FF',
      },
      current: {
        color: '#9999FF',
      },
    },
  },
});

function convertToLowerCaseWithSpaces(str: string) {
  return str.toLowerCase().replace(/_/g, ' ');
}
