import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'utils/axios';
import RayInput from 'components/RayInput';
import RayButton from 'components/RayButton';
import { H6 } from 'components/Typography';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { DateRangePicker } from 'react-date-range';
import { useTheme } from '@material-ui/core/styles';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styled from 'styled-components';
import {
  add,
  endOfMonth,
  endOfYear,
  parse,
  startOfYear,
  differenceInDays,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { emailInputRegex, emailValidateRegex } from 'utils/constants';
import { useVO } from 'utils/VOContext';
import { useOktaAuth } from '@okta/okta-react';

const ExportCSVWrapper = styled.div`
  margin-left: 1rem;
  .rangePicker {
    text-align: center;
    margin-bottom: 1rem;
    .rdrDefinedRangesWrapper {
      width: 0;
      border: none;
    }
    .rdrDayToday .rdrDayNumber {
      span {
        &:after {
          content: '';
          width: 0;
        }
      }
    }
    .rangeHighlighter {
      .rdrInRange {
        color: #8d8dff !important;
      }
    }
  }
  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    width: 100%;
  }
`;

const ExportCSVForm = ({
  route = `shop/exports`,
  showDatePicker,
  start_date,
  end_date,
  type,
  status,
  selectedOption,
  building_id,
  toggleDetailModal,
  secondaryText,
  currentState = 'TODAY',
  buildingData = [],
}: Props) => {
  const [email, setEmail] = useState<string>('');
  const { authState} = useOktaAuth()!;
  const [disabled, setDisabled] = useState<boolean>(false);
  const { setToastType, userInfo } = useAuthContext()!;
  const theme = useTheme();
  const [error, setError] = useState<string>('');
  const [selectedDateRange, setSelectedDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#0000ff',
      showDateDisplay: true,
      autoFocus: true,
    },
  ]);

  const {
    orderType,
    selectedBuilding,
    expiryMonthSelected,
    expiryYearSelected,
  } = useVO()!;

  useEffect(() => {
    setEmail(userInfo ? userInfo.email : '');
  }, [userInfo]);

  const handleFullExport = async () => {
    if (!email) {
      setError('Email is required');
      return;
    }
    if (!email.match(emailValidateRegex)) {
      setError('Enter a valid email address');
      return;
    }
    setError('');

    setDisabled(true);
    try {
      const body: any = {
        email,
        type,
        currentState,
      };
      if (!userInfo.is_super_admin && type === 'vo-bookings') {
        body.building_id = buildingData.map((building: any) =>
          building.id?.toString(),
        );
      }
      await axios.post(route, body, {
        params: {
          type,
        },
        headers: {
          authorization:  authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: `CSV sent to ${email}`,
      });
      setDisabled(false);
      toggleDetailModal(false);
    } catch (err) {
      const error: any = err;
      console.log(error);
      let message = 'Error occurred while sending the email';
      if (error.response.status === 401) {
        message = error.response.data.message;
      }
      setToastType({
        show: true,
        type: 'error',
        message: message,
      });
      setDisabled(false);
      toggleDetailModal(false);
    }
  };

  const handleExport = async () => {
    if (!email) {
      setError('Email is required');
      return;
    }
    if (!email.match(emailValidateRegex)) {
      setError('Enter a valid email address');
      return;
    }
    setError('');

    setDisabled(true);

    try {
      let requiredFrom;
      let requiredTo;

      const body: any = {
        handle: selectedOption,
        status,
        building_id,
        email,
        type,
        currentState,
        start_date,
        end_date,
      };
      if (showDatePicker) {
        const from = setHours(
          setMinutes(setSeconds(selectedDateRange[0].startDate, 0), 0),
          0,
        );
        const to = setHours(
          setMinutes(setSeconds(selectedDateRange[0].endDate, 0), 0),
          0,
        );

        requiredFrom =
          from &&
          add(new Date(from.toISOString().split('T')[0]), {
            days: currentState === 'TODAY' ? 0 : 1,
            hours: 5,
            minutes: 30,
            seconds: 0,
          })
            .toISOString()
            .split('T')[0] + 'T00:00:00Z';
        requiredTo =
          to &&
          add(new Date(to.toISOString().split('T')[0]), {
            days: currentState === 'TODAY' ? 0 : 1,
            hours: 5,
            minutes: 30,
            seconds: 0,
          })
            .toISOString()
            .split('T')[0] + 'T23:59:59Z';
        body.start_date = requiredFrom;
        body.end_date = requiredTo;
      }
      if (type === 'vo-bookings') {
        if (orderType?.value !== 'ALL' && orderType?.value)
          body.status = orderType.value;
        if (selectedBuilding !== -1)
          body.building_id = [selectedBuilding.toString()];
        else {
          if (!userInfo.is_super_admin) {
            body.building_id = buildingData.map((building: any) =>
              building.id?.toString(),
            );
          }
        }
      }

      if (type === 'vo-bookings' && !showDatePicker) {
        //case where month is selected.
        if (
          expiryMonthSelected?.value !== 'ALL' &&
          expiryMonthSelected?.value
        ) {
          let from, to;
          //case where month and year are selected.
          if (expiryYearSelected?.value) {
            from = parse(
              `${expiryMonthSelected.value} ${expiryYearSelected?.value}`,
              'MMMM yyyy',
              new Date(),
            );
            to = endOfMonth(
              parse(
                `${expiryMonthSelected.value} ${expiryYearSelected?.value}`,
                'MMMM yyyy',
                new Date(),
              ),
            );
          } else {
            from = parse(expiryMonthSelected.value, 'MMMM', new Date());
            to = endOfMonth(
              parse(expiryMonthSelected.value, 'MMMM', new Date()),
            );
          }

          body.expiry_start_date =
            add(new Date(from.toISOString().split('T')[0]), {
              days: currentState === 'TODAY' ? 0 : 1,
              hours: 5,
              minutes: 30,
              seconds: 0,
            })
              .toISOString()
              .split('T')[0] + 'T00:00:00Z';

          body.expiry_end_date = to.toISOString().split('T')[0] + 'T23:59:59Z';
        }

        //case where only year is selected
        if (
          expiryYearSelected?.value &&
          (!expiryMonthSelected?.value || expiryMonthSelected?.value === 'ALL')
        ) {
          let from, to;
          from = startOfYear(
            parse(expiryYearSelected?.value, 'yyyy', new Date()),
          );
          to = endOfYear(parse(expiryYearSelected?.value, 'yyyy', new Date()));
          body.expiry_start_date =
            add(new Date(from.toISOString().split('T')[0]), {
              days: currentState === 'TODAY' ? 0 : 1,
              hours: 5,
              minutes: 30,
              seconds: 0,
            })
              .toISOString()
              .split('T')[0] + 'T00:00:00Z';

          body.expiry_end_date = to.toISOString().split('T')[0] + 'T23:59:59Z';
        }
      }

      if (currentState !== 'TODAY') {
        const daysDiff = differenceInDays(
          new Date(body.start_date),
          new Date(body.end_date),
        );
        if (daysDiff < -31) {
          setToastType({
            show: true,
            type: 'error',
            message: 'You can only export bookings upto 31 days.',
          });
          setDisabled(false);
          return;
        }
      }
      await axios.post(route, body, {
        params: {
          type,
        },
        headers: {
          authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: `CSV sent to ${email}`,
      });
      setDisabled(false);
      toggleDetailModal(false);
    } catch (err) {
      const error: any = err;
      console.log(error);
      let message = 'Error occurred while sending the email';
      if (error.response.status === 401) {
        message = error.response.data.message;
      }
      setToastType({
        show: true,
        type: 'error',
        message: message,
      });
      setDisabled(false);
      toggleDetailModal(false);
    }
  };

  const checkDatePicker =
    type === 'vo-bookings' &&
    showDatePicker &&
    !(
      selectedBuilding !== -1 ||
      (orderType?.value !== 'ALL' && orderType?.value !== undefined)
    );

  return (
    <ExportCSVWrapper>
      <Grid container spacing={1}>
        {currentState !== 'TODAY' && showDatePicker && (
          <>
            <p>
              Choose the dates for which you require to export the bookings:{' '}
            </p>
            <Grid container justify="center">
              <Grid item xs={10} justify="center" className="rangePicker">
                <DateRangePicker
                  className="rangeHighlighter"
                  startDatePlaceholder="Start Date"
                  endDatePlaceholder="End Date"
                  rangeColors={[theme.palette.primary.main]}
                  ranges={selectedDateRange}
                  onChange={item => setSelectedDateRange([item.selection])}
                  inputRanges={[]}
                  staticRanges={[]}
                  showDateDisplay={false}
                  moveRangeOnFirstSelection={false}
                  minDate={currentState === 'UPCOMING' ? new Date() : undefined}
                  maxDate={currentState === 'PAST' ? new Date() : undefined}
                  showMonthAndYearPickers={false}
                  dragSelectionEnabled={true}
                  showPreview={true}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Grid item container xs={12} alignItems="center" spacing={2}>
            <Grid item xs={checkDatePicker ? 12 : 8}>
              <RayInput
                placeholder="arya.stark@wework.co.in"
                id="email__id"
                fullWidth
                autoMargin={false}
                value={email}
                onChange={e =>
                  setEmail(e.target.value.replace(emailInputRegex, ''))
                }
                label="Customer Email"
              />
            </Grid>
            {checkDatePicker && (
              <Grid item xs={6}>
                <RayButton
                  fullWidth
                  onClick={handleFullExport}
                  disabled={disabled}
                >
                  Export All Data
                </RayButton>
              </Grid>
            )}
            <Grid item xs={checkDatePicker ? 6 : 4}>
              <RayButton fullWidth onClick={handleExport} disabled={disabled}>
                {checkDatePicker ? 'Export selected date range' : 'Export'}
              </RayButton>
            </Grid>
          </Grid>
          <Grid item xs={10} container justify="flex-start">
            <Grid className="error">{error && error}</Grid>
            <Grid>
              <H6>
                {secondaryText} will be sent to {email}
              </H6>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ExportCSVWrapper>
  );
};

export default ExportCSVForm;

type Props = {
  route?: string;
  status?: string;
  selectedOption?: string;
  building_id?: number;
  toggleDetailModal: Function;
  secondaryText: string;
  currentState?: string;
  type?: string;
  start_date?: any;
  end_date?: any;
  showDatePicker?: boolean;
  buildingData?: any;
};
