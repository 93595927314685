import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import TableContainer from './TableContainer';
import { BuildingOption } from './types';
import { Wrapper } from './styles';
import RayButton from '../../components/RayButton';
import { Grid, Typography } from '@material-ui/core';
import ModalDialog from '../../components/ModalDialog';
import ExportCSVForm from 'components/ExportCSVForm';
import CommonFilterComponent from 'components/FilterComponent';
import styled from 'styled-components';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
`;

interface RefObj {
  refreshLoad: () => void;
}

function Dashboard() {
  const [bookingsTypeCount, setBookingsTypeCount] = useState<number>(0);
  const [membersCount, setMembersCount] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const childRef = useRef<RefObj>(null);
  const [results, setResults] = useState<number>(10);
  const [orderType, setOrderType] = useState<any>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#0000ff',
      showDateDisplay: true,
      autoFocus: true,
    },
  ]);

  const OrderOptions = [
    { value: 'B2B', label: 'B2B' },
    { value: 'B2C', label: 'B2C' },
    { value: 'ALL', label: 'ALL' },
  ];

  const [buildingsData] = useFetchRequest('/buildings/get-user-buildings');
  const [selectedBuilding, setSelectedBuilding] = useState<number>(-1);

  const refetchData = () => {
    if (childRef.current) {
      childRef.current && childRef.current.refreshLoad();
    }
  };

  useEffect(() => {
    if (
      orderType &&
      (orderType.value === 'B2B' ||
        orderType.value === 'B2C' ||
        orderType.value === 'ALL')
    ) {
      refetchData();
    }
  }, [orderType]);

  const handleChangeBuilding = (id: string) => {
    if (id) setSelectedBuilding(Number(id));
    else if (id === null) {
      setSelectedBuilding(-1);
    }
    refetchData();
  };

  return (
    <>
      <Helmet>
        <title>Bookings</title>
        <meta
          name="description"
          content="WeWork On Demand Dashboard - Bookings"
        />
      </Helmet>
      <Grid container>
        <Grid container item xs={10} spacing={5}>
          <Grid item>
            <Typography variant="h6">
              Total no. of bookings: {bookingsTypeCount || 0}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h6">
              Total no. of members: {membersCount || 0}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <RayButton
            buttonType="secondary"
            compact
            onClick={() => setModalOpen(true)}
          >
            Export Bookings
          </RayButton>
        </Grid>
      </Grid>
      <FilterWrapper>
        <Grid item xs={12}>
          <CommonFilterComponent
            searchString={searchString}
            setSearchString={setSearchString}
            refetchData={refetchData}
            buildingList={
              buildingsData && buildingsData.length > 0
                ? buildingsData
                    .filter(({ disabled }: BuildingOption) => !disabled)
                    .concat({ id: -1, name: 'All' })
                : []
            }
            buildingOnChange={handleChangeBuilding}
            setOrderType={setOrderType}
            orderType={orderType}
            setSelectedDateRange={setSelectedDateRange}
            selectedDateRange={selectedDateRange}
            setResults={setResults}
            results={results}
            OrderOptions={OrderOptions}
            page="DayPassBooking"
          />
        </Grid>
      </FilterWrapper>
      <Wrapper>
        <TableContainer
          bookingsType={'today'}
          hits={results}
          setBookingsTypeCount={setBookingsTypeCount}
          setMembersCount={setMembersCount}
          searchString={searchString}
          reference={childRef}
          buildingId={selectedBuilding}
          orderType={orderType}
          selectedDateRange={selectedDateRange}
        />
      </Wrapper>
      <ModalDialog
        isOpen={modalOpen}
        showModal={setModalOpen}
        fullWidth
        maxWidth="sm"
        title="Email Bookings"
      >
        <ExportCSVForm
          showDatePicker={true}
          secondaryText="Bookings CSV"
          type="day-pass-bookings"
          building_id={undefined}
          toggleDetailModal={setModalOpen}
          // route="/shop/exports"
          currentState="ALL"
        />
      </ModalDialog>
    </>
  );
}

export default Dashboard;
