import firebase from 'firebase/app';
import app from 'firebase/app';
import 'firebase/auth'; // to access storage
import 'firebase/storage';

const configDev = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, //can be removed
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  // auth: firebase.auth.Auth;
  storage: firebase.storage.Storage;
  constructor() {
    const config =
      process.env.NODE_ENV === 'development'
        ? configDev
        : JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!);
    app.initializeApp(config);
    // app.analytics();
    // this.auth = app.auth();
    this.storage = app.storage();
  }

  getFileFromStorage = async (path: string) =>
    await this.storage.ref(path).getDownloadURL();

  uploadFileToStorage = async (path: string, file: any) => {
    const storageRef = this.storage.ref();
    const fileRef = storageRef.child(path);
    await fileRef.put(file);
  };
}

const firebaseObj = new Firebase();

export default firebaseObj;
