import React from 'react';
import styled from 'styled-components';
import RayButton from '../../components/RayButton';
import Loader from '../../components/Loader';
import { useFetchRequest } from '../../utils/hooks/useFetchRequest';
import { useAuthContext } from '../../utils/hooks/useAuthContext';

const Wrapper = styled.div`
  width: 100%;
  table {
    width: 100%;
    font-size: 14px;
  }
`;

type Props = {
  selectUser: (x: any) => void;
  showDeleteModal: (x: any) => void;
};

function UsersTable({ selectUser, showDeleteModal }: Props) {
  const [usersData, usersLoader, usersError] = useFetchRequest(
    '/admin-users/get',
  );
  const { setToastType } = useAuthContext()!;

  if (usersLoader) return <Loader />;
  if (usersError) {
    setToastType({ show: true, type: 'error', message: 'Error loading Users' });
    return <p>Couldn't fetch Users</p>;
  }
  return (
    <Wrapper>
      <table className="ray-table">
        <thead>
          <tr>
            <th>User Name</th>
            <th>User Email</th>
            <th>Building Access</th>
            <th>Postpaid User</th>
            <th>Super Admin</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {usersData &&
            usersData.length > 0 &&
            usersData.map((cell: any, index: number) => (
              <tr key={+index}>
                <td>{cell.name ? cell.name : '--'}</td>
                <td>{cell.email ? cell.email : '--'}</td>
                <td>
                  {cell.accessibleBuildings
                    ? Object.values(cell.accessibleBuildings).map(
                        (building: any) => <span>{building}, </span>,
                      )
                    : '--'}
                </td>
                <td>{cell && cell.is_postpaid_user ? 'YES' : 'NO'}</td>
                <td>{cell && cell.is_super_admin ? 'YES' : 'NO'}</td>
                <td>
                  <RayButton
                    onClick={() => selectUser(cell)}
                    buttonType="tertiary"
                    compact
                  >
                    Edit
                  </RayButton>
                  <RayButton
                    onClick={() => showDeleteModal(cell)}
                    buttonType="tertiary"
                    compact
                    danger
                  >
                    Delete
                  </RayButton>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Wrapper>
  );
}

export default UsersTable;
