import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Row from '../../components/RayGrid/Row';
import Columm from '../../components/RayGrid/Column';
import RayInput from '../../components/RayInput';
import axios from '../../utils/axios';
import styled from 'styled-components';
import RayButton from '../../components/RayButton';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { emailValidateRegex, nameValidateRegex } from 'utils/constants';
import { useOktaAuth } from '@okta/okta-react';

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .no-margin {
    margin: 0px;
    padding-bottom: 0.5rem;
    height: 100%;
    .select__control {
      height: 100%;
      border: 1px solid black;
    }
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

type Props = {
  showModal: (x: boolean) => void;
  setAlert: (x: any) => void;
  userData?: any;
  userId?: string;
  reload: (x: boolean) => void;
};

interface UserForm {
  email?: string;
  password?: string;
  display_name: string;
  user_id?: string;
  is_super_admin?: boolean;
  is_postpaid_user?: boolean;
  is_legality_user?: boolean;
  dashboard_url?: string;
}

function CreateLegalUserForm({
  showModal,
  userData,
  userId,
  setAlert,
  reload,
}: Props) {
  const [userName, setUserName] = React.useState<string>('');
  const [userEmail, setUserEmail] = React.useState<string>('');
  const [userPswd, setUserPswd] = React.useState<string>('');
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const {  setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const { setUserInfo, userInfo } = useAuthContext()!;

  const classes = useStyles();

  useEffect(() => {
    if (userData) {
      setUserName(userData.name);
    }
  }, [userData]);

  useEffect(() => {
    if (!userId) {
      setUserName('');
    }
  }, [userId]);

  const validateForm = () => {
    if (
      !userId &&
      (!userEmail || userEmail === '' || !userEmail.match(emailValidateRegex))
    ) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid email address',
      });
      return false;
    }
    if (!userId && !userPswd) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Password field is mandatory.',
      });
      return false;
    }
    if (!userName || !userName.match(nameValidateRegex)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please use only characters and avoid extra spaces',
      });
      return false;
    }
    return true;
  };

  const submitUserInfo = async () => {
    if (!validateForm()) return;
    reload(false);
    setDisabled(true);
    try {
      let body: UserForm = {
        email: userEmail,
        password: userPswd,
        display_name: userName,
        is_legality_user: true,
        dashboard_url: process.env.REACT_APP_URL,
      };
      if (userId)
        body = {
          user_id: userId,
          display_name: userName,
          is_legality_user: true,
        };
      const tokenValue = authState?.accessToken?.accessToken;
      const response = await axios.post(`legality-users/create`, body, {
        headers: {
          Authorization: tokenValue,
        },
      });
      if (userInfo.id === userId) {
        setUserInfo(userInfo);
      }
      reload(true);
      setToastType({
        show: true,
        type: 'success',
        message: response.data.message,
      });
      setDisabled(false);
      showModal(false);
    } catch (err) {
      const error: any = err;
      console.error(error);
      reload(true);
      setDisabled(false);
      setToastType({
        show: true,
        type: 'error',
        message: error?.response?.data?.message,
      });
    }
  };

  return (
    <FormWrapper>
      <Row>
        <Columm size="12">
          <h2>{userId ? 'Update User' : 'Create User'}</h2>
        </Columm>
        {!userId && (
          <Columm size="6">
            <RayInput
              id="useremail"
              name="user-email"
              label="Email"
              placeholder="Enter Email"
              className={classes.textField}
              disabled={userId ? true : false}
              fullWidth
              value={userEmail}
              onChange={e => setUserEmail(e.target.value)}
            />
          </Columm>
        )}

        {!userId && (
          <Columm size="6">
            <RayInput
              id="userpswd"
              name="user-pswd"
              label="Password"
              placeholder="Create Password"
              className={classes.textField}
              type="password"
              disabled={userId ? true : false}
              fullWidth
              onChange={e => setUserPswd(e.target.value)}
            />
          </Columm>
        )}
        <Columm size="6">
          <RayInput
            id="user-name"
            name="display-name"
            label="Name"
            placeholder="Enter Name"
            className={classes.textField}
            value={userName}
            type="text"
            fullWidth
            onChange={e =>
              setUserName(
                e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
              )
            }
          />
        </Columm>
        <Columm size="12">
          <ButtonRow>
            <RayButton
              style={{ width: '25%' }}
              disabled={disabled}
              onClick={submitUserInfo}
              compact
            >
              {userId ? 'Update' : 'Add'}
            </RayButton>
          </ButtonRow>
        </Columm>
      </Row>
    </FormWrapper>
  );
}

export default CreateLegalUserForm;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: '0.5em',
    },
  }),
);
