import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import '@wework/ray-core/css/ray-core.css';
import { ApolloProvider } from '@apollo/client';
import { weworkClient } from './utils/apolloClient';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnMount: false } },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={weworkClient}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
