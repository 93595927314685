import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { H5 } from 'components/Typography/Heading';
import {
  Avatar,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ModalDialog from 'components/ModalDialog';
import RayInput from 'components/RayInput';
import RayButton from 'components/RayButton';
import { Option } from 'react-select/src/filters';
import {
  AddDirectorFormInitialState,
  AddDirectorFormReducer,
} from './AddDirectorFormReducer';
import {
  Director,
  VirtualOfficeDocument,
} from 'containers/VirtualOfficePage/types';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import { RiDeleteBinLine } from 'react-icons/ri';
import { UpdateVODetailsMutation } from '../ApiCalls';
import { useOktaAuth } from '@okta/okta-react';

const ErrorDiv = styled.div`
   {
    color: '#CC0000';
  }
`;

type Props = {
  directors: Director[];
  areProposedDirectors?: boolean;
  loader?: boolean;
  documentId: string;
  documentData: VirtualOfficeDocument;
};

const MODIFY_DIRECTOR = 'Modify Directors';
const PAN_REGEX = new RegExp('^[A-Za-z]{5}\\d{4}[A-Za-z]{1}$');
const PASSPORT_REGEX = new RegExp('^[A-PR-WY][1-9]\\d\\s?\\d{4}[1-9]$');
const ERROR_ALL_DIRECTORS_EMPTY = 'Please add at least one director';
const ERROR_ALL_FIELDS_MANDATORY =
  'Please provide: Director Name, ( PAN or Passport number )';
const ERROR_INVALID_FIELDS = 'Please fill in valid details';

function DirectorsTable({
  directors,
  areProposedDirectors = false,
  loader = true,
  documentId,
  documentData,
}: Props) {
  const { userInfo, setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();
  const [openAddDirectorModal, setOpenAddDirectorModal] = useState<boolean>(
    false,
  );
  const [openModifyDirectorsModal, setOpenModifyDirectorsModal] = useState<
    boolean
  >(false);
  const [stateDirectors, setStateDirectors] = useState<Partial<Director>[]>(
    directors.map(director => ({
      ...director,
      is_active: true,
    })),
  );

  const [state, dispatch] = useReducer(
    AddDirectorFormReducer,
    AddDirectorFormInitialState,
  );

  const setAddDirectorModalState = (state: boolean) =>
    setOpenAddDirectorModal(state);

  const setModifyDirectorsModalState = (state: boolean) =>
    setOpenModifyDirectorsModal(state);

  const { isLoading, mutateAsync } = UpdateVODetailsMutation({});

  const handleModifyDirectorsSubmit = () => {
    const isAllDirectorInactive = stateDirectors.every(director => {
      return director.is_active === false;
    });
    if (isAllDirectorInactive) {
      setToastType({
        show: true,
        type: 'error',
        message: ERROR_ALL_DIRECTORS_EMPTY,
      });
    } else {
      mutateAsync({
        authToken: authState?.accessToken?.accessToken || '',
        documentId: documentId,
        payload: {
          fieldToBeUpdated: 'directors',
          directors: stateDirectors,
        },
      }).then(data => {
        setToastType({
          show: true,
          type: 'success',
          message: 'Successfully Modified Directors in VO',
        });
        setModifyDirectorsModalState(false);
      });
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_NAME', payload: event.target.value });
  };

  const handlePanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    dispatch({ type: 'SET_PAN', payload: value });
    dispatch({
      type: 'SET_PAN_ERROR',
      payload: PAN_REGEX.test(value) ? '' : 'Invalid PAN number',
    });
  };

  const handlePassportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    const isValid = PASSPORT_REGEX.test(value);
    dispatch({ type: 'SET_PASSPORT', payload: value });
    dispatch({
      type: 'SET_PASSPORT_ERROR',
      payload: isValid ? '' : 'Invalid Passport number',
    });
  };

  const handleDocumentChange = (option: Option | null) => {
    dispatch({ type: 'SET_DOCUMENT', payload: option });
  };

  const handleAddDirectorSubmit = () => {
    if (
      (state.document?.value === 'pan' && state.panError) ||
      (state.document?.value === 'passport' && state.passportError)
    ) {
      setToastType({
        show: true,
        type: 'error',
        message: ERROR_INVALID_FIELDS,
      });
    } else if (!(state.name && (state.pan || state.passport))) {
      setToastType({
        show: true,
        type: 'error',
        message: ERROR_ALL_FIELDS_MANDATORY,
      });
    } else {
      setStateDirectors([
        ...stateDirectors,
        {
          name: state.name,
          ...(state.document?.value === 'pan' && { pan: state.pan }),
          ...(state.document?.value === 'passport' && {
            passportNumber: state.passport,
          }),
          is_active: true,
        },
      ]);
      dispatch({ type: 'RESET_FORM', payload: null });
      setAddDirectorModalState(false);
    }
  };

  const deleteDirectorClick = (index: number) => {
    const ModifiedDirectors = stateDirectors.map((director, i) => {
      if (i === index) {
        return {
          ...director,
          is_active: false,
        };
      }
      return director;
    });
    setStateDirectors(ModifiedDirectors);
  };

  const handleModifyDirectorsCancel = () => {
    setModifyDirectorsModalState(false);
    setStateDirectors(
      directors.map(director => ({ ...director, is_active: true })),
    );
  };

  const getDocumentComponent = (document: string) => {
    switch (document) {
      case 'pan':
        return (
          <Grid item xs={12}>
            <RayInput
              fullWidth
              id="pan"
              name="pan"
              label="PAN Number"
              placeholder="Enter the PAN Number"
              type="text"
              value={state.pan}
              onChange={handlePanChange}
              required
            />
            {state.panError && (
              <ErrorDiv>
                <small>{state.panError}</small>
              </ErrorDiv>
            )}
          </Grid>
        );

      case 'passport':
        return (
          <Grid item xs={12}>
            <RayInput
              fullWidth
              id="passport"
              name="passport"
              label="Passport Number"
              placeholder="Enter the Passport Number"
              type="text"
              value={state.passport}
              onChange={handlePassportChange}
              required
            />
            {state.passportError && (
              <ErrorDiv>
                <small>{state.passportError}</small>
              </ErrorDiv>
            )}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <H5>
        {areProposedDirectors
          ? 'Proposed Director Details'
          : 'Director Details'}
      </H5>
      <table className="ray-table">
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Name</th>
            <th style={{ width: '10%' }}>Verified</th>
            <th style={{ width: '10%' }}>Aadhaar</th>
            <th style={{ width: '10%' }}>PAN</th>
            <th style={{ width: '10%' }}>Gender</th>
            <th style={{ width: '10%' }}>Date of Birth</th>
            <th style={{ width: '30%' }}>Address</th>
            {/* <th style={{ width: '10%' }}>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {directors &&
            directors.length > 0 &&
            React.Children.toArray(
              directors.map((cell, index: number) => (
                <tr className="documents_row">
                  <td>{cell.name ? cell.name : '--'}</td>
                  <td>{cell.status === 'VERIFIED' ? 'YES' : 'NO'}</td>
                  <td>{cell.eaadhar && cell.eaadhar.name ? 'YES' : 'NO'}</td>
                  <td>
                    {cell.pan && (userInfo.is_super_admin || userInfo.vo_admin)
                      ? cell.pan
                      : '--'}
                  </td>
                  <td>
                    {cell.eaadhar &&
                    (userInfo.is_super_admin || userInfo.vo_admin)
                      ? cell.eaadhar.gender
                      : '--'}
                  </td>
                  <td>
                    {cell.eaadhar &&
                    (userInfo.is_super_admin || userInfo.vo_admin)
                      ? cell.eaadhar.dob
                      : '--'}
                  </td>
                  <td>
                    {cell.eaadhar &&
                    (userInfo.is_super_admin || userInfo.vo_admin)
                      ? cell.eaadhar.address
                      : '--'}
                  </td>
                </tr>
              )),
            )}
        </tbody>
      </table>

      {userInfo.is_super_admin && (
        <Grid item xs={3}>
          <RayButton
            disabled={loader}
            style={{ width: '100%', marginTop: 16 }}
            onClick={() => setOpenModifyDirectorsModal(true)}
          >
            {!loader ? (
              MODIFY_DIRECTOR
            ) : (
              <CircularProgress size={15} style={{ color: '#fff' }} />
            )}
          </RayButton>
        </Grid>
      )}

      <ModalDialog
        isOpen={openModifyDirectorsModal}
        showModal={handleModifyDirectorsCancel}
        fullWidth
        maxWidth="sm"
        title={MODIFY_DIRECTOR}
        key={'modify-director-modal'}
      >
        <List>
          {stateDirectors &&
            stateDirectors.length > 0 &&
            React.Children.toArray(
              stateDirectors.map((cell, index: number) => (
                <ListItem key={index} disabled={cell.is_active ? false : true}>
                  <ListItemAvatar>
                    <Avatar>{index + 1}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={cell.name} />
                  <ListItemSecondaryAction>
                    {cell.is_active && (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteDirectorClick(index)}
                      >
                        <RiDeleteBinLine size={20} />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              )),
            )}
        </List>
        <RayButton
          buttonType="tertiary"
          style={{ width: '100%', marginBottom: 16 }}
          onClick={() => setAddDirectorModalState(true)}
        >
          Add Director
        </RayButton>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <RayButton
              buttonType="secondary"
              onClick={handleModifyDirectorsCancel}
            >
              Cancel
            </RayButton>
            <RayButton
              buttonType="primary"
              onClick={handleModifyDirectorsSubmit}
              loading={isLoading}
            >
              Submit
            </RayButton>
          </Grid>
        </Grid>
      </ModalDialog>

      <ModalDialog
        isOpen={openAddDirectorModal}
        showModal={() => setAddDirectorModalState(false)}
        fullWidth
        maxWidth="sm"
        title={'Add Director'}
        key={'add-director'}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RayInput
              fullWidth
              id="name"
              name="name"
              label="Director Name"
              placeholder="Enter the Name"
              type="text"
              value={state.name}
              required
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              key="document"
              options={[
                { label: 'PAN Number', value: 'pan' },
                { label: 'Passport Number', value: 'passport' },
              ]}
              getOptionLabel={(option: Option) => option.label}
              label="Select Document"
              name="select-document"
              value={state.document}
              size="medium"
              onChange={(event: React.ChangeEvent<{}>, value: Option | null) =>
                handleDocumentChange(value)
              }
            />
          </Grid>
          {getDocumentComponent(state.document?.value as string)}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Grid container justifyContent="space-between">
            <RayButton
              buttonType="secondary"
              onClick={() => setAddDirectorModalState(false)}
            >
              Cancel
            </RayButton>
            <RayButton buttonType="primary" onClick={handleAddDirectorSubmit}>
              Submit
            </RayButton>
          </Grid>
        </Grid>
      </ModalDialog>
    </>
  );
}

export default DirectorsTable;
