import React from "react";
import styled from "styled-components";
import { AiFillWarning, AiFillCheckCircle } from "react-icons/ai";
import { VscChromeClose } from "react-icons/vsc";

const AlertWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border-color: ${({ alertType }: WrapperProps) =>
    alertType === "success" ? "#c3e6cb" : "#f5c6cb"};
  color: ${({ alertType }: WrapperProps) =>
    alertType === "success" ? "#155724" : "#721c24"};
  background-color: ${({ alertType }: WrapperProps) =>
    alertType === "success" ? "#d4edda" : "#f8d7da"};
  svg {
    font-size: 2em;
  }
`;

type WrapperProps = {
  alertType: "success" | "error";
};

type Props = {
  children: React.ReactNode;
  alertType: "success" | "error";
  closeBtn: () => void;
};

function Alert({ children, alertType = "success", closeBtn }: Props) {
  return (
    <AlertWrapper alertType={alertType}>
      {alertType === "error" ? <AiFillWarning /> : <AiFillCheckCircle />}
      {children}
      <VscChromeClose onClick={closeBtn} style={{ cursor: "pointer" }} />
    </AlertWrapper>
  );
}

export default Alert;
