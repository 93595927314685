import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import RayInput from 'components/RayInput';
import axiosODE from 'utils/axiosODE';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { TiTick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';
import { emailInputRegex } from 'utils/constants';
import Spinner from '../../assets/loading_spinner.svg';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useOktaAuth } from '@okta/okta-react';

function SubDomain({
  index,
  value,
  handleOnchange,
  collectiveSubdomains,
  deleteEventHandler,
  isDisabled,
}: any) {
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const { setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const [
    isSubDomainAvailable,
    setIsSubDomainAvailable,
  ] = useState<Boolean | null>(null);
  const [subDomain, setSubDomain] = useState('');

  useEffect(() => {
    setSubDomain(value);
  }, [value]);

  useEffect(() => {
    setIsSubDomainAvailable(null);
  }, [subDomain]);

  const handleChangeEventType = (e: any) => {
    const value = e.target.value;
    setSubDomain(value.replace(emailInputRegex, ''));
    collectiveSubdomains[index].subDomainValue = value || '';
    handleOnchange(collectiveSubdomains);
  };

  const subDomainCheck = async () => {
    setCheckingAvailability(true);
    if (!subDomain) {
      setCheckingAvailability(false);
      return setToastType({
        show: true,
        type: 'error',
        message: 'Please enter the sub domain to check the availability',
      });
    }

    let pattern = new RegExp(
      '^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$',
    );
    let match = pattern.test(subDomain);

    if (!match) {
      setIsSubDomainAvailable(false);
      setCheckingAvailability(false);
      return setToastType({
        show: true,
        type: 'error',
        message: 'Enter a valid sub domain name',
      });
    }

    try {
      const response = await axiosODE.get(
        `/api/automation/enterprise/domain?enterpriseDomain=${subDomain}`,
        {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        },
      );
      if (response.status === 200) {
        let status: 'error' | 'success' =
          response.data.message === 'Domain already exists'
            ? 'error'
            : 'success';

        setToastType({
          show: true,
          type: status,
          message: response.data.message,
        });
        setCheckingAvailability(false);
        if (status === 'success') {
          setIsSubDomainAvailable(true);
          collectiveSubdomains[index].isSubDomainAvailable = true;
          handleOnchange([...collectiveSubdomains]);
        } else {
          setIsSubDomainAvailable(false);
        }
      }
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message:
          error?.response?.data?.message ||
          error?.message ||
          'Something went wrong in verifying sub domain',
      });
      setCheckingAvailability(false);
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      xl={8}
      spacing={1}
      direction="row"
      alignItems="center"
      justify="flex-start"
    >
      <Grid item xs={6}>
        <RayInput
          disabled={isDisabled}
          id={`sub_domain_${index}`}
          label={`Subdomain ${index + 1}`}
          name={`sub_domain_${index}`}
          value={subDomain}
          onChange={handleChangeEventType}
          fullWidth
          autoMargin={false}
          placeholder="abc.co.in"
          required={false}
          pattern="(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}"
        />
      </Grid>
      <Grid item xs={6}>
        <div
          className={`domainAvailabilityCheckContainer ${!subDomain &&
            'disableDomainCheck'}`}
        >
          {!collectiveSubdomains[index].isSubDomainAvailable && (
            <div onClick={subDomainCheck} style={{ display: 'flex' }}>
              <div>
                {isSubDomainAvailable !== null ? (
                  isSubDomainAvailable === true ? (
                    <TiTick
                      color="#4BB543"
                      size={30}
                      style={{ marginRight: 0 }}
                    />
                  ) : (
                    <ImCross color="#F00" />
                  )
                ) : (
                  <img src={Spinner} alt="" />
                )}
              </div>
              <p>
                {checkingAvailability && subDomain ? 'Checking ' : 'Check '}
                Availability
              </p>
              <div className="vl" />
            </div>
          )}
          <div
            className="delete-button"
            onClick={() => deleteEventHandler(index)}
          >
            <RiDeleteBinLine size={20} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default SubDomain;
