import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ShopCard from './ShopCard';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Loader from 'components/Loader';
import { InputAdornment, TextField } from '@material-ui/core';
import {
  Building,
  Categories,
  DropdownOption,
  PaginationObject,
  Product,
} from './types';
import ShopPagination from './pagination';
import { useReactQueryNew } from 'utils/hooks/useReactQuery';
import { MdSearch, MdInfoOutline, MdError } from 'react-icons/md';
import useDebounce from 'utils/hooks/useDebounce';
import { ANCILLARY_SERVICES, defaultPagination } from 'utils/constants';
import SelectInput from 'components/MaterialSelect/Autocomplete';

const useStyles = makeStyles({
  cardWrapper: {
    width: '20%', // Adjust width of cards
    display: 'flex',
    marginBottom: '0.5rem',
  },
  centerAlign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '85vh',
  },
  icon: {
    fontSize: '3rem',
    marginBottom: '1rem',
  },
});

type FilterKey = 'persistedCategory' | 'persistedBuilding';
// | 'searchString';

function ShopPage() {
  const classes = useStyles();
  const { userInfo } = useAuthContext()!;
  const [
    selectedCategory,
    setSelectedCategory,
  ] = useState<DropdownOption | null>(null);
  const [
    selectedBuilding,
    setSelectedBuilding,
  ] = useState<DropdownOption | null>(null);
  const [searchString, setSearchString] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchString, 500);
  const [pagination, setPagination] = useState<PaginationObject>(
    defaultPagination,
  );

  const {
    isLoading: categoriesLoader,
    isError: categoriesError,
    data: categoriesData,
  } = useReactQueryNew(['admin', 'products', 'categories'], {
    url: '/api/v1/admin/product-categories',
    params: {
      limit: 10,
      page: 1,
    },
  });

  const specificCategoryUid = React.useMemo(() => {
    if (!categoriesData?.data) return null;

    const specificCategory = categoriesData.data.find(
      category => category.type === ANCILLARY_SERVICES.type,
    );

    return specificCategory?.uid || null;
  }, [categoriesData]);

  const {
    isLoading: productsLoading,
    isError: productsError,
    data: productsData,
  } = useReactQueryNew(
    [
      'admin',
      'products',
      selectedBuilding?.value,
      selectedCategory?.value,
      debouncedSearchTerm,
      pagination.direction,
      pagination.cursor,
      specificCategoryUid,
    ],
    {
      url: `/api/v1/admin/products/search`,
      params: {
        size: 10,
        direction: pagination.direction,
        cursor: pagination.cursor,
        search_query: debouncedSearchTerm,
        must_match: {
          fb_building_id: selectedBuilding?.value,
          category_uid: selectedCategory?.value,
        },
        should_match: userInfo?.accessibleBuildings.map(
          (building: Building) => building.id,
        ),
        must_not_match: {
          //move it to backend later.
          product_type: 'DAY_PASS',
          ...(!userInfo.is_postpaid_user &&
            specificCategoryUid && {
              category_uid: specificCategoryUid,
            }),
        },
      },
      method: 'POST',
    },
  );

  useEffect(() => {
    const savedFilters = localStorage.getItem('shopFilters');
    if (savedFilters) {
      const { persistedCategory, persistedBuilding } = JSON.parse(savedFilters);
      if (persistedBuilding) {
        const matchedBuilding = userInfo?.accessibleBuildings?.find(
          (building: Building) => building.id === persistedBuilding.value,
        );

        if (matchedBuilding) {
          setSelectedBuilding({
            label: matchedBuilding.name,
            value: matchedBuilding.id,
          });
        } else if (persistedBuilding === null) {
          // User explicitly removed the building, do nothing
          setSelectedBuilding(null);
        } else if (userInfo?.accessibleBuildings?.length > 0) {
          // no buildings in local storage then use first building.
          const defaultBuilding = userInfo?.accessibleBuildings[0];
          setSelectedBuilding({
            label: defaultBuilding.name,
            value: defaultBuilding.id,
          });
        }
      }

      if (persistedCategory && categoriesData?.data) {
        const matchedCategory = categoriesData.data.find(
          (category: Categories) => category.uid === persistedCategory.value,
        );

        if (matchedCategory) {
          setSelectedCategory({
            label: matchedCategory.name,
            value: matchedCategory.uid,
          });
        }
      }

      // setSearchString(searchString || '');
    } else if (userInfo?.accessibleBuildings?.length > 0) {
      const defaultBuilding = userInfo?.accessibleBuildings[0];
      setSelectedBuilding({
        label: defaultBuilding.name,
        value: defaultBuilding.id,
      });
    }
  }, [userInfo?.accessibleBuildings, categoriesData]);

  const updateFilterInStorage = (key: FilterKey, value: any) => {
    const currentFilters = JSON.parse(
      localStorage.getItem('shopFilters') || '{}',
    );
    const updatedFilters = {
      ...currentFilters,
      [key]: value,
    };
    localStorage.setItem('shopFilters', JSON.stringify(updatedFilters));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setPagination(defaultPagination);
    setSearchString(newValue);
    // updateFilterInStorage('searchString', newValue); // Save filters on change
  };

  const handleBuildingChange = (selectedOption: DropdownOption) => {
    setPagination(defaultPagination);
    setSelectedBuilding(selectedOption);
    updateFilterInStorage('persistedBuilding', selectedOption); // Save filters on change
  };

  const handleCategoryChange = (selectedOption: DropdownOption) => {
    setPagination(defaultPagination);
    setSelectedCategory(selectedOption);
    updateFilterInStorage('persistedCategory', selectedOption); // Save filters on change
  };

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2} alignItems="center">
        <Grid item xs={3}>
          <TextField
            placeholder="Search by product name"
            variant="outlined"
            fullWidth
            id="search"
            name="search"
            className="searchbar"
            onChange={handleInputChange}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdSearch color="#979797" />
                </InputAdornment>
              ),
            }}
            value={searchString}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            options={
              userInfo?.accessibleBuildings?.length > 0
                ? userInfo?.accessibleBuildings
                    .filter(({ disabled }: Building) => !disabled)
                    .map((building: Building) => ({
                      label: building.name,
                      value: building.id,
                    }))
                : []
            }
            size="medium"
            getOptionLabel={option => option.label}
            label={'Building'}
            onChange={(_, selectedBuilding) => {
              handleBuildingChange(selectedBuilding);
            }}
            value={selectedBuilding}
            disabled={false}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            options={
              categoriesData?.data?.length > 0
                ? categoriesData?.data
                    ?.filter(
                      (category: Categories) =>
                        !(
                          category.uid === ANCILLARY_SERVICES.uid &&
                          !userInfo.is_postpaid_user
                        ),
                    )
                    .map((category: Categories) => ({
                      label: category.name,
                      value: category.uid,
                    }))
                : []
            }
            size="medium"
            getOptionLabel={option => option.label}
            label={'Category'}
            value={selectedCategory}
            onChange={(_, selectedCategory) => {
              handleCategoryChange(selectedCategory);
            }}
            disabled={categoriesLoader || categoriesError}
          />
        </Grid>
      </Grid>
      {productsLoading ? (
        <Loader />
      ) : productsError ? (
        <Grid item xs={12} className={classes.centerAlign}>
          <MdError className={classes.icon} />
          <p>Error occurred while fetching products</p>
        </Grid>
      ) : (
        <>
          {productsData?.data?.records?.length > 0 ? (
            productsData?.data?.records.map((item: Product, index: number) => (
              <Grid key={index.toString()} item className={classes.cardWrapper}>
                <ShopCard product={item} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} className={classes.centerAlign}>
              <MdInfoOutline className={classes.icon} />
              <p>There are no products available for the applied filter</p>
            </Grid>
          )}
          {productsData?.data?.records?.length > 0 && (
            <ShopPagination
              setPagination={setPagination}
              pagination={productsData?.data?.pagination}
            />
          )}
        </>
      )}
    </Grid>
  );
}

export default ShopPage;
