import styled from 'styled-components';
import { TabLayoutProps } from './types';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: 100%;
  color: #000;
  background-color: #fff;
`;

export const TabsLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  color: #000;
  background-color: #fff;
  padding: 1em;
`;

export const TabChild = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 10%;
text-transform : capitalize;
&:hover{
  cursor: pointer;
}
p{
  border-bottom: ${({ bType, booking }: TabLayoutProps) =>
    bType === booking ? '2px solid #00f' : 'none'};
}
span{
  padding: 0 0.5em;
  background-color: #0000FF;
  color: white;
  border-radius: 50%;
  margin-left: 0.5em;
  font-size: 0.8em;
  font-weight: bolder;
}
}
`;
