import React from 'react';
import { Helmet } from 'react-helmet';
import { useAuthContext } from '../../utils/hooks/useAuthContext';
import axios from '../../utils/axios';
import LegalUsersTable from './LegalUsersTable';
import styled from 'styled-components';
import RayButton from '../../components/RayButton';
import Modal from '../../components/DialogModal';
import DeleteModal from '../../components/DeleteDialog';
import CreateLegalUserForm from './CreateLegalUserForm';
import Alert from '../../components/Alert';
import { useOktaAuth } from '@okta/okta-react';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
`;
const AlertWrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

function LegalUsers() {
  const [userModal, showUserModal] = React.useState<boolean>(false);
  const [deleteModal, showDeleteModal] = React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<any>(undefined);
  const [alert, setAlert] = React.useState<any>({
    type: '',
    message: '',
  });
  const [reload, setReload] = React.useState<boolean>(true);
  const { setToastType } = useAuthContext()!;
  const { authState} = useOktaAuth();

  const selectUser = (user: any) => {
    setSelectedUser(user);
    showUserModal(true);
  };
  const selectDeleteUser = (user: any) => {
    setSelectedUser(user);
    showDeleteModal(true);
  };
  const deleteFirebaseUser = async (id: string) => {
    setReload(false);
    try {
      const tokenValue = authState?.accessToken?.accessToken;
      const response = await axios.post(
        'legality-users/delete',
        { user_id: id },
        { headers: { Authorization: tokenValue } },
      );
      setToastType({
        show: true,
        type: 'success',
        message: response.data.message,
      });
      setReload(true);
      showDeleteModal(false);
    } catch (err) {
      const error: any = err;
      console.error(error);
      setToastType({
        show: true,
        type: 'error',
        message: error?.response?.data?.message,
      });
      setReload(true);
    }
  };
  const closeAlert = () => {
    setAlert({ type: '', message: '' });
  };

  return (
    <>
      <Helmet>
        <title>Legal Users</title>
        <meta
          name="description"
          content="WeWork On Demand Dashboard - Legal Users"
        />
      </Helmet>
      <Wrapper>
        <ButtonRow>
          <AlertWrapper>
            {alert.message && (
              <Alert alertType={alert.type} closeBtn={closeAlert}>
                {alert.message}
              </Alert>
            )}
          </AlertWrapper>
          <RayButton
            onClick={() => {
              setSelectedUser(undefined);
              showUserModal(true);
            }}
            compact
          >
            Add User
          </RayButton>
        </ButtonRow>
        {reload && (
          <LegalUsersTable
            selectUser={selectUser}
            showDeleteModal={selectDeleteUser}
          />
        )}
      </Wrapper>
      <Modal isOpen={userModal} showModal={showUserModal} center>
        <CreateLegalUserForm
          showModal={showUserModal}
          userData={selectedUser}
          setAlert={setAlert}
          reload={setReload}
          userId={
            selectedUser && selectedUser.email ? selectedUser.email : false
          }
        />
      </Modal>
      <DeleteModal
        isOpen={deleteModal}
        showModal={showDeleteModal}
        deleteFunction={deleteFirebaseUser}
        deleteItemId={
          selectedUser && selectedUser.email ? selectedUser.email : false
        }
      />
    </>
  );
}

export default LegalUsers;
