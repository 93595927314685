import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import styled from 'styled-components';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';

const SendKYCEmailWrapper = styled.div`
  display: flex;
  .vl {
    border-left: 1px solid #000;
    height: 20px;
  }
  .send-email-text {
    margin-left: 14px;
    color: #0000ff;
    cursor: pointer;
  }
  .disabled {
    color: #9a9af0;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const SendKYCEmail = ({ email }: { email: string }) => {
  const {  setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const [sendingEmail, setIsSendingEmail] = React.useState(false);
  const sendEmail = async (email: string) => {
    setIsSendingEmail(true);
    try {
      const token = authState?.accessToken?.accessToken;
      const body = {
        email,
      };
      const response = await axios.post('/customers/send-kyc-reminder', body, {
        headers: { Authorization: token },
      });
      setToastType({
        show: true,
        type: 'success',
        message: response.data?.message,
      });
    } catch (err) {
      const error: any = err;
      let message =
        error.response.data.message ?? 'Error occurred while sending the email';
      if (error.response.status === 401) {
        message = error.response.data.message;
      }
      setToastType({
        show: true,
        type: 'error',
        message: message,
      });
    } finally {
      setIsSendingEmail(false);
    }
  };
  if (!email) return <></>;
  return (
    <SendKYCEmailWrapper>
      <div className="vl" />
      <p
        className={`send-email-text ${sendingEmail && 'disabled'} `}
        onClick={() => sendEmail(email)}
      >
        Send Email
      </p>
    </SendKYCEmailWrapper>
  );
};

export default SendKYCEmail;
