import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: white;
  font-weight: bold;
  background-color: #89909c;
`;

function NotFoundPage() {
  return (
    <Wrapper>
      <h1>*404*</h1>
      <p>Oops! Something went wrong...</p>
      <p>
        Do you wanna go back <Link to="/">home</Link>?
      </p>
    </Wrapper>
  );
}

export default NotFoundPage;
