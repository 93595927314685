import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import ExistingEnterprise from './ExistingEnterprise';
import NewEnterprise from './NewEnterprise';

const OnboardEnterpriseWrapper = styled.div`
  .marginBottom {
    margin-bottom: 30px;
  }
`;
type TabLayoutProps = {
  bType: string;
  option: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: 100%;
  color: #000;
  background-color: #fff;
`;
const TabsLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  width: 100%;
  color: #000;
  background-color: #fff;
  padding: 0em;
  border-bottom: 1px solid black;
`;

const TabChild = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding-right: 1em;

  &:hover{
    cursor: pointer;
  }
  p{
    border-bottom: ${({ bType, option }: TabLayoutProps) =>
      bType === option ? '3px solid #00f' : 'none'};
    padding: 0.5em 1em;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: -2px;
    color: #0000FF;
  }
  span{
    padding: 0 0.5em;
    background-color: #0000FF;
    color: white;
    border-radius: 50%;
    margin-left: 0.5em;
    font-size: 0.8em;
    font-weight: bolder;
  }
  }
`;

const EnterpriseOnboarding = () => {
  const [selectedOption, setSelectedOption] = useState('NEW_ENTERPRISE');

  return (
    <Grid container style={{ padding: '0px 50px' }}>
      <h3 style={{ marginBottom: '40px' }}>OD Enterprise Onboarding</h3>
      <Grid className="marginBottom" item xs={12}>
        <OnboardEnterpriseWrapper>
          <Grid container />

          <Wrapper>
            <TabsLayout>
              <TabChild
                bType={selectedOption}
                option="NEW_ENTERPRISE"
                onClick={() => {
                  setSelectedOption('NEW_ENTERPRISE');
                }}
              >
                <p>Add an enterprise</p>
                {selectedOption === 'NEW_ENTERPRISE'}
              </TabChild>
              <TabChild
                bType={selectedOption}
                option="EXISTING_ENTERPRISE"
                onClick={() => {
                  setSelectedOption('EXISTING_ENTERPRISE');
                }}
              >
                <p>Update enterprise</p>
                {selectedOption === 'EXISTING_ENTERPRISE'}
              </TabChild>
            </TabsLayout>
          </Wrapper>
          {selectedOption === 'NEW_ENTERPRISE' && <NewEnterprise />}
          {selectedOption === 'EXISTING_ENTERPRISE' && <ExistingEnterprise />}
        </OnboardEnterpriseWrapper>
      </Grid>
    </Grid>
  );
};

export default EnterpriseOnboarding;
