import React from 'react';
import styled from 'styled-components';
import TableCell from './TableCell';
import { TLog } from '..';

const Wrapper = styled.div`
  width: 100%;
  table {
    font-size: 14px;
    width: 100%;
  }
  tr.booking_row:hover {
    cursor: pointer;
  }
  .no-logs {
    padding-top: 10px;
  }
`;

type Props = {
  auditLogs: TLog[];
};

const AuditHistoryTable = React.forwardRef(({ auditLogs }: Props) => {
  if (auditLogs.length === 0) {
    return (
      <Wrapper>
        <h5 className="no-logs">No audit logs available</h5>
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <table className="ray-table">
          <thead>
            <tr>
              <th>Location</th>
              <th>Building Name</th>
              <th>Activity</th>
              <th>Actioned By</th>
              <th>Role</th>
              <th>Status</th>
              <th>Last updated</th>
              <th>File URL</th>
            </tr>
          </thead>
          <tbody>
            {auditLogs.map((data: TLog) => (
              <TableCell data={data} />
            ))}
          </tbody>
        </table>
      </Wrapper>
    </>
  );
});

export default AuditHistoryTable;
