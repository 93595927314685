import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { Product } from './types';

type ShopProduct = {
  product: Product;
};

const useStyles = makeStyles({
  cardWrapper: {
    width: '100%', // Adjust width of cards
    display: 'flex',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  media: {
    height: '15vh',
  },
  content: {
    flexGrow: 1,
  },
  cta: {
    width: '100%',
    padding: 12,
  },
});

const ShopCard = ({ product }: ShopProduct) => {
  const classes = useStyles();

  const { images, product_name, building_name, product_uid } = product;

  const imageUrl = images?.[0]?.url || 'https://via.placeholder.com/150';

  return (
    <div className={classes.cardWrapper}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={imageUrl}
          title={product_name}
        />
        <CardContent className={classes.content}>
          <Typography>
            {product_name + (building_name ? `, ${building_name}` : '')}
          </Typography>
        </CardContent>
        <CardActions>
          <Link
            className={`${classes.cta} ray-button ray-button--primary`}
            to={`/shop/${product_uid}`}
          >
            Select
          </Link>
        </CardActions>
      </Card>
    </div>
  );
};

export default ShopCard;
