import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { SidebarItem } from './SidebarItem';
import { useAuthContext } from '../../utils/hooks/useAuthContext';
import logo from '../../assets/WeLogoFull.png';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  activeLink: {
    color: '#0000FF !important',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
}));

const SidebarWrapper = styled.div`
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
  width: 15%;
  background-color: #f6f6f6;
  color: #000;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  a {
    color: #000;
    width: 100%;
    text-decoration: none;
  }
  a:hover {
    color: #0000ff !important;
  }
  &:last-child {
    justify-self: end;
  }
`;

// const DisabledLink = styled(NavLink)`
//   cursor: default;
//   &:hover {
//     cursor: default;
//     text-decoration: none;
//   }
// `;

const StyledImage = styled.img`
  margin-top: 2em;
  margin-bottom: 5em;
  margin-left: 1em;
  align-self: flex-start;
`;

export function getUserRole(userInfo: any): role {
  if (userInfo.hasLMSAccess) {
    return 'lms';
  }
  if (userInfo.is_wework_super_admin) {
    return 'weworkSuperAdmin';
  }
  if (userInfo.is_super_admin) {
    return 'superAdmin';
  }
  if (userInfo.is_legality_user) {
    return 'legality';
  }
  if (userInfo.isPartnerAdminUser) {
    return 'partnerAdmin';
  }
  if (userInfo.isBusinessManager) {
    return 'businessManager';
  }
  if (userInfo.is_admin) {
    return 'admin';
  }

  return 'admin';
}

function Sidebar() {
  const { userInfo } = useAuthContext()!;
  const classes = useStyles();

  const role = getUserRole(userInfo);

  const tabs = useMemo(() => {
    return getLabelsByRole(role, userInfo.is_postpaid_user);
  }, [role, userInfo.is_postpaid_user]);

  return (
    <SidebarWrapper>
      <StyledImage src={logo} alt="" style={{ height: '1.5em' }} />
      {tabs.map((label, index) => (
        <NavLink
          activeClassName={classes.activeLink}
          key={+index}
          to={sidebarRoute[label]}
        >
          <SidebarItem label={label} />
        </NavLink>
      ))}
    </SidebarWrapper>
  );
}

export default Sidebar;

export const sidebarRoute: Record<string, string> = {
  'Day Pass Bookings': '/day-pass-bookings',
  'Online Conference Room Bookings': '/online-conference-room-bookings',
  'VO Bookings': '/vo-bookings',
  'Shop Purchases': '/shop-purchases',
  Shop: '/shop',
  Buildings: '/buildings',
  Users: '/users',
  'Admin Users': '/admin-users',
  'Legal Users': '/legal-users',
  'My Profile': '/my-profile',
  'PO Inventory': '/po-inventory',
  LMS: '/lms',
  'OD Enterprise Onboarding': '/enterprise-onboarding',
  'Conference Rooms': '/conference-rooms',
  Inventory: '/inventory',
  'Shopify Orders': '/shopify-orders',
  'Day Pass Bundles': '/day-pass-bundles',
  'Audit history': '/audit-history',
};

type role =
  | 'admin'
  | 'superAdmin'
  | 'weworkSuperAdmin'
  | 'lms'
  | 'legality'
  | 'partnerAdmin'
  | 'businessManager'; // add role here while adding new one

const rolesByLabel: Record<string, role[]> = {
  'Day Pass Bookings': ['admin', 'superAdmin', 'weworkSuperAdmin', 'lms'],
  'Online Conference Room Bookings': [
    'admin',
    'superAdmin',
    'weworkSuperAdmin',
    'lms',
  ],
  'VO Bookings': [
    'admin',
    'superAdmin',
    'weworkSuperAdmin',
    'partnerAdmin',
    'lms',
  ],
  'Shop Purchases': ['admin', 'superAdmin', 'weworkSuperAdmin', 'lms'],
  Shop: ['admin', 'superAdmin', 'weworkSuperAdmin', 'lms'],
  Users: ['admin', 'superAdmin', 'legality', 'weworkSuperAdmin', 'lms'],
  'My Profile': [
    'admin',
    'superAdmin',
    'legality',
    'weworkSuperAdmin',
    'partnerAdmin',
    'lms',
    'businessManager',
  ],
  Buildings: [
    'superAdmin',
    'weworkSuperAdmin',
    'partnerAdmin',
    'lms',
    'businessManager',
  ],
  'Admin Users': ['superAdmin', 'weworkSuperAdmin', 'lms'],
  'Legal Users': ['weworkSuperAdmin'],
  'PO Inventory': ['superAdmin', 'weworkSuperAdmin', 'lms'],
  LMS: ['lms'],
  'OD Enterprise Onboarding': ['superAdmin', 'weworkSuperAdmin', 'lms'],
  'Conference Rooms': ['superAdmin', 'weworkSuperAdmin', 'lms'],
  Inventory: ['superAdmin', 'businessManager', 'weworkSuperAdmin'],
  'Shopify Orders': [],
  'Day Pass Bundles': ['superAdmin', 'weworkSuperAdmin'],
  'Audit history': ['superAdmin', 'weworkSuperAdmin'],
};

export function getLabelsByRole(
  roleToFind: role,
  isPostpaid_User: boolean = false,
) {
  const labels: string[] = [];
  for (const label in rolesByLabel) {
    if (rolesByLabel[label].includes(roleToFind)) {
      labels.push(label);
    }
  }
  // This tab is only accessible to users with postpaid tag
  if (isPostpaid_User) {
    labels.push('Shopify Orders');
  }
  return labels;
}

// const labelsByRole = {
//   admin: [
//     'Day Pass Bookings',
//     'Online Conference Room Bookings',
//     'VO Bookings',
//     'Shop Purchases',
//     'Shop',
//     'Users',
//     'My Profile',
//   ],
//   superAdmin: [
//     'Day Pass Bookings',
//     'Online Conference Room Bookings',
//     'VO Bookings',
//     'Shop Purchases',
//     'Shop',
//     'Buildings',
//     'Users',
//     'Admin Users',
//     'My Profile',
//     'On Demand CMS',
//     'OD Enterprise Onboarding',
//     'Conference Rooms',
//     'Shopify Orders'
//   ],
//   legality: ['Users', 'My Profile'],
//   weworkSuperAdmin: ['Day Pass Bookings',
//     'Online Conference Room Bookings',
//     'VO Bookings',
//     'Shop Purchases',
//     'Shop',
//     'Buildings',
//     'Users',
//     'Admin Users',
//     'Legal Users',
//     'My Profile',
//     'On Demand CMS',
//     'OD Enterprise Onboarding',
//     'Conference Rooms'],
//   partnerAdmin: ['VO Bookings', 'Buildings'],
//   lms: ['Day Pass Bookings',
//     'Online Conference Room Bookings',
//     'VO Bookings',
//     'Shop Purchases',
//     'Shop',
//     'Buildings',
//     'Users',
//     'Admin Users',
//     'My Profile',
//     'On Demand CMS',
//     'LMS',
//     'OD Enterprise Onboarding',
//     'Conference Rooms',
//     'Shopify Orders'],
//   postpaid: ['Day Pass Bookings',
//     'Online Conference Room Bookings',
//     'VO Bookings',
//     'Shop Purchases',
//     'Shop',
//     'Buildings',
//     'Users',
//     'My Profile', 'Shopify Orders'],
// };
