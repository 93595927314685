import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import Loader from 'components/Loader';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { DropdownTypes, VODocumentLineData } from './types';
import UserNameComponent from './util';
import { BsClockHistory } from 'react-icons/bs';
import { useVO } from 'utils/VOContext';
import { useAuthContext } from 'utils/hooks/useAuthContext';

const Wrapper = styled.div`
  width: 100%;
  table {
    font-size: 14px;
    width: 100%;
  }
  tr.documents_row:hover {
    cursor: pointer;
  }
`;

const PaginationWrapper = styled.div`
  .pagination {
    margin-top: 1.5em;
    display: flex;
    justify-content: flex-end;
  }
  .icon {
    margin-top: -1.2em;
    display: flex;
    justify-content: flex-start;
    margin-left: 1em;
  }
  .description {
    margin-left: 0.5em;
  }
`;

type Props = {
  className?: string;
  queryType: string;
  hits: number;
  searchString: string;
  reference: any;
  selectedBuilding: number;
  expiryMonthSelected: DropdownTypes | null;
  expiryYearSelected: DropdownTypes | null;
  filterType: any;
};

// const generateVOOrderStatus = (
//   status: string,
//   orderKycStatus: boolean,
// ): string => {
//   if (status === 'COMPLETED' && orderKycStatus) return 'COMPLETED';
//   if (status === 'COMPLETED' && !orderKycStatus) return 'PENDING KYC';
//   return 'PENDING';
// };

function TableContainer({
  queryType,
  hits,
  searchString,
  reference,
  selectedBuilding,
  expiryMonthSelected,
  expiryYearSelected,
  filterType,
}: Props) {
  const { useAlgolia } = useAuthContext()!;
  const { push } = useHistory();
  const classes = useStyles();
  const { currentPage, setCurrentPage } = useVO()!;
  const { isLoading, error, data, isFetching, refetch } = useReactQuery(
    ['voDocuments', queryType, hits, currentPage],
    {
      url: useAlgolia
        ? '/vo/admin/fetch-all-docs/'
        : '/api/v1/admin/virtual-offices',
      params: {
        status: queryType,
        pageNo: useAlgolia ? currentPage : currentPage - 1,
        hitsPerPage: hits,
        searchString,
        buildingId: selectedBuilding,
        expiryMonth: expiryMonthSelected && expiryMonthSelected.value,
        expiryYear: expiryYearSelected?.value
          ? expiryYearSelected?.value
          : expiryMonthSelected?.value
          ? new Date().getUTCFullYear()
          : undefined,
        ...(filterType?.value &&
          filterType?.value !== 'All' && { searchType: filterType?.value }),
      },
    },
    {
      enabled: !!queryType,
    },
  );
  const prevSearchString = useRef<string>(searchString);
  // useEffect(() => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  //   refetch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hits, filterType?.value]);

  const gotoDocument = (id: string) => {
    push(`/vo-bookings/${id}`);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (searchString !== prevSearchString.current)
      timeoutId = setTimeout(() => {
        setCurrentPage(1);
      }, 1000);
    prevSearchString.current = searchString;
    // Cleanup function to cancel the previous update if there's a new one
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  useImperativeHandle(reference, () => ({
    refreshLoad(page: number = 1) {
      setCurrentPage(page);
      setTimeout(() => {
        refetch();
      }, 1000);
    },
  }));

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageNo: number,
  ) => {
    setCurrentPage(pageNo);
    refetch();
  };

  if (isLoading || isFetching) return <Loader maxHeight />;
  if (error) return <p className={classes.error}>Error fetching records</p>;

  return (
    <>
      <Wrapper>
        {data.data.hits.length > 0 ? (
          <table className="ray-table">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Email ID</th>
                <th>Contact Number</th>
                <th>Building Name</th>
                <th>VO Package Type</th>
                <th>Order ID</th>
                <th>Total Price</th>
                {/* <th>Status</th> */}
              </tr>
            </thead>
            <tbody>
              {data.data &&
                data.data.hits &&
                React.Children.toArray(
                  data.data.hits.map(
                    (cell: VODocumentLineData, index: number) => (
                      <tr
                        className="documents_row"
                        onClick={() => {
                          // addSessionStorage();
                          gotoDocument(cell.objectID);
                        }}
                      >
                        <td>{cell.companyName}</td>
                        <td>
                          <u>
                            <UserNameComponent
                              name={cell.primaryUser}
                              voExpiryDate={cell.expiresOn}
                            />
                          </u>
                        </td>
                        <td>{cell.phone}</td>
                        <td>{cell.buildingName}</td>

                        <td>{cell.packageName}</td>
                        <td>{cell.latestOrderNumber}</td>
                        <td>{cell.packagePrice}</td>
                        {/* <td>
                        {generateVOOrderStatus(
                          cell.status,
                          cell.totalKycComplete,
                        )}
                      </td> */}
                      </tr>
                    ),
                  ),
                )}
            </tbody>
          </table>
        ) : (
          <div className={classes.error}>No records found</div>
        )}
        {data &&
          (useAlgolia
            ? data?.data?.nbPages
            : data?.data?.pagination?.totalPages) !== 0 && (
            <PaginationWrapper>
              <Pagination
                count={
                  useAlgolia
                    ? data?.data?.nbPages
                    : data?.data?.pagination?.totalPages
                }
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                classes={{ ul: classes.ul }}
                className="pagination"
              />
              <span className="icon">
                <BsClockHistory color="red" />
                <text className="description">Expires in 15 days</text>
              </span>
            </PaginationWrapper>
          )}
      </Wrapper>
    </>
  );
}

export default TableContainer;

const useStyles = makeStyles(() => ({
  ul: {
    '& .Mui-selected': {
      backgroundColor: '#0000ff',
      color: '#fff',
    },
  },
  error: {
    textAlign: 'center',
    color: 'red',
    fontSize: '2 rem',
  },
}));
