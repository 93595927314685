import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Row from '../../components/RayGrid/Row';
import Columm from '../../components/RayGrid/Column';
import RayInput from '../../components/RayInput';
import axios from '../../utils/axios';
import { useFetchRequest } from '../../utils/hooks/useFetchRequest';
import Select from 'react-select';
import styled from 'styled-components';
import RayButton from '../../components/RayButton';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { emailValidateRegex, nameValidateRegex } from 'utils/constants';
import { useOktaAuth } from '@okta/okta-react';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#B3B3B3',
    '&$checked': {
      color: '#0000CC',
    },
    '&$checked + $track': {
      backgroundColor: '#9999FF',
    },
  },
  checked: {},
  track: {},
})(Switch);

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .no-margin {
    margin: 0px;
    height: 100%;
    .select__control {
      border: 1px solid black;
      overflow: auto;
      max-height: 58px;
    }
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const customStyles = {
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    alignItems: 'none',
    padding: '10px',
  }),
};

type Props = {
  showModal: (x: boolean) => void;
  setAlert: (x: any) => void;
  userData?: any;
  userId?: string;
  reload: (x: boolean) => void;
};

type BuildingOption = {
  id: string;
  name: string;
  disabled?: boolean;
};

interface UserForm {
  email?: string;
  display_name: string;
  accessible_buildings: any;
  user_id?: string;
  is_super_admin?: boolean;
  is_postpaid_user?: boolean;
  is_partner_admin_user?: boolean;
  isBusinessManager?: boolean;
  dashboard_url?: string;
}

interface Role {
  value:
    | 'is_admin'
    | 'isPartnerAdminUser'
    | 'isBusinessManager'
    | 'is_super_admin';
  label: 'Admin' | 'Super admin' | 'Myhq (partner admin)' | 'Business manager';
}

function CreateUserForm({
  showModal,
  userData,
  userId,
  setAlert,
  reload,
}: Props) {
  const [userName, setUserName] = React.useState<string>('');
  const [userEmail, setUserEmail] = React.useState<string>('');
  const [isPostpaidUser, setIsPostpaidUser] = React.useState<boolean>(false);
  const [defaultBuildings, setDefaultBuildings] = React.useState<any[]>([]);
  const [accessibleBuildings, setAccessibleBuildings] = React.useState({});
  const [buildingsData, buildingsLoader, buildingsError] = useFetchRequest(
    '/buildings/get',
  );
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [role, setRole] = React.useState<Role>({ value: 'is_admin', label: 'Admin' });

  const { setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();
  const { setUserInfo, userInfo } = useAuthContext()!;

  const classes = useStyles();

  useEffect(() => {
    if (buildingsData?.length > 0) {
      buildingsData.unshift({ name: 'All Buildings', id: '1' });
    }
  }, [buildingsData]);

  const backendRolesData = [
    'isPartnerAdminUser',
    'isBusinessManager',
    'is_super_admin',
  ];

  const roleTypeMapping: { [key: string]: string } = {
    is_super_admin: 'Super admin',
    isPartnerAdminUser: 'Myhq (partner admin)',
    isBusinessManager: 'Business manager',
  };

  // Function to set the role based on the backend data
  const setRoleFromBackend = (userData: any) => {
    for (const type in userData) {
      if (backendRolesData.includes(type) && userData[type]) {
        const roleObject: Role = {
          value: type as
            | 'isPartnerAdminUser'
            | 'isBusinessManager'
            | 'is_super_admin',
          label: roleTypeMapping[type] as
            | 'Super admin'
            | 'Myhq (partner admin)'
            | 'Business manager',
        };
        setRole(roleObject);
        break;
      }
    }
  };

  useEffect(() => {
    if (userData) {
      setUserName(userData.name);
      setRoleFromBackend(userData);
      setIsPostpaidUser(userData.is_postpaid_user);
      if (userData.accessibleBuildings) {
        const arr = Object.entries(userData.accessibleBuildings).map(item => ({
          id: item[0],
          name: item[1],
        }));
        setDefaultBuildings([...arr]);
        setAccessibleBuildings(userData.accessibleBuildings);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (!userId) {
      setUserName('');
      setDefaultBuildings([]);
      setAccessibleBuildings({});
    }
  }, [userId]);

  const validateForm = () => {
    if (
      !userId &&
      (!userEmail || userEmail === '' || !userEmail.match(emailValidateRegex))
    ) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid email address',
      });
      return false;
    }
    if (!userName || !userName.match(nameValidateRegex)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please use only characters and avoid extra spaces',
      });
      return false;
    }
    return true;
  };

  const submitUserInfo = async () => {
    if (!validateForm()) return;
    reload(false);
    setDisabled(true);
    try {
      let body: UserForm = {
        email: userEmail,
        display_name: userName,
        accessible_buildings: accessibleBuildings,
        is_super_admin: role.value === 'is_super_admin',
        is_postpaid_user: isPostpaidUser,
        is_partner_admin_user: role.value === 'isPartnerAdminUser',
        isBusinessManager: role.value === 'isBusinessManager',
        dashboard_url: process.env.REACT_APP_URL,
      };
      if (userId)
        body = {
          user_id: userId,
          display_name: userName,
          accessible_buildings: accessibleBuildings,
          is_super_admin: role.value === 'is_super_admin',
          is_postpaid_user: isPostpaidUser,
          is_partner_admin_user: role.value === 'isPartnerAdminUser',
          isBusinessManager: role.value === 'isBusinessManager',
        };
      const tokenValue = authState?.accessToken?.accessToken;
      // console.log(body);
      const response = await axios.post(`/admin-users/create`, body, {
        headers: {
          Authorization: tokenValue,
        },
      });
      if (userInfo.id === userId) {
        setUserInfo({
          ...userInfo,
          is_postpaid_user: isPostpaidUser,
        });
      }
      reload(true);
      setToastType({
        show: true,
        type: 'success',
        message: response.data.message,
      });
      setDisabled(false);
      showModal(false);
    } catch (err) {
      const error: any = err;
      console.error(error);
      reload(true);
      setDisabled(false);
      setToastType({
        show: true,
        type: 'error',
        message: error.response.data.message,
      });
    }
  };

  const onBuildingChange = (e: any) => {
    if (e === null) {
      setDefaultBuildings([]);
      setAccessibleBuildings({});
    } else {
      let buildings = e.map((building: BuildingOption) => building.name);
      if (buildings.includes('All Buildings')) {
        let arr = buildingsData
          .filter(({ disabled }: BuildingOption) => !disabled)
          .reduce(
            (obj: any, item: any) =>
              Object.assign(obj, { [item.id]: item.name }),
            {},
          );
        delete arr[1];
        setDefaultBuildings([{ name: 'All Buildings', id: 1 }]);
        setAccessibleBuildings(arr);
      } else {
        const arr = e.reduce(
          (obj: any, item: any) => Object.assign(obj, { [item.id]: item.name }),
          {},
        );
        setDefaultBuildings(e);
        setAccessibleBuildings(arr);
      }
    }
  };

  const options: Role[] = [
    { label: 'Admin', value: 'is_admin' },
    { label: 'Super admin', value: 'is_super_admin' },
    { label: 'Myhq (partner admin)', value: 'isPartnerAdminUser' },
    { label: 'Business manager', value: 'isBusinessManager' },
  ];

  function ChangeRole(e: Role) {
    setRole(e);
    if (e?.value === 'isPartnerAdminUser' || e?.value === 'isBusinessManager') {
      let arr = buildingsData
        .filter(({ disabled }: BuildingOption) => !disabled)
        .reduce(
          (obj: any, item: any) => Object.assign(obj, { [item.id]: item.name }),
          {},
        );
      delete arr[1];
      setDefaultBuildings([{ name: 'All Buildings', id: 1 }]);
      setAccessibleBuildings(arr);
    } else {
      setAccessibleBuildings({});
      setDefaultBuildings([]);
    }

    if (
      isPostpaidUser &&
      e?.value !== 'is_admin' &&
      e?.value !== 'is_super_admin'
    ) {
      setIsPostpaidUser(false);
    }
  }

  return (
    <FormWrapper>
      <Row>
        <Columm size="12">
          <h2>{userId ? 'Update User' : 'Create User'}</h2>
        </Columm>
        {!userId && (
          <Columm size="6">
            <RayInput
              id="useremail"
              name="user-email"
              label="Email"
              placeholder="Enter Email"
              className={classes.textField}
              disabled={userId ? true : false}
              fullWidth
              value={userEmail}
              onChange={e => setUserEmail(e.target.value)}
            />
          </Columm>
        )}
        <Columm size={userId ? '12' : '6'}>
          <RayInput
            id="user-name"
            name="display-name"
            label="Name"
            placeholder="Enter Name"
            className={classes.textField}
            value={userName}
            type="text"
            fullWidth
            onChange={e =>
              setUserName(
                e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
              )
            }
          />
        </Columm>
        <Columm size="6" style={{ marginTop: '10px' }}>
          <form>
            <label htmlFor="building-access-input">
              Select Building Access
            </label>
            <Select
              isMulti
              styles={customStyles}
              id="building-access-input"
              name="buildings-selection"
              options={
                buildingsData && buildingsData.length > 0
                  ? buildingsData.filter(
                      ({ disabled }: BuildingOption) => !disabled,
                    )
                  : []
              }
              classNamePrefix="select"
              value={defaultBuildings}
              placeholder="Select Building Access"
              className="building__select_input no-margin"
              getOptionValue={(option: BuildingOption) => option.id}
              getOptionLabel={(option: BuildingOption) => option.name}
              isLoading={buildingsLoader}
              isDisabled={
                buildingsError
                  ? true
                  : false ||
                    role.value === 'isPartnerAdminUser' ||
                    role.value === 'isBusinessManager'
              }
              onChange={e => onBuildingChange(e)}
            />
          </form>
        </Columm>

        <Columm size="6" style={{ marginTop: '10px' }}>
          <form>
            <label htmlFor="role-selection-input">Select User Role</label>
            <Select
              styles={customStyles}
              name="role-selection"
              inputId="role-selection-input"
              options={options}
              classNamePrefix="select"
              value={role}
              placeholder="Select user role"
              className="building__select_input no-margin"
              onChange={e => {
                ChangeRole(e as Role);
              }}
            />
          </form>
        </Columm>

        {/* disable postpaid if role changed from admin / super admin */}

        {(role.value === 'is_admin' || role.value === 'is_super_admin') && (
          <Columm size={'6'}>
            <FormControlLabel
              control={
                <PurpleSwitch
                  checked={isPostpaidUser}
                  onChange={e => setIsPostpaidUser(e.target.checked)}
                  name={'Is Postpaid User'}
                />
              }
              label={'Is Postpaid User'}
            />
          </Columm>
        )}
        <Columm style={{ marginTop: '1em', textAlign: 'left' }} size="12">
          <small>
            Please note that if a customer exists with this email address, their
            customer account will be deleted and permanently converted to an
            admin account.
          </small>
        </Columm>
        <Columm size="12">
          <ButtonRow>
            <RayButton
              style={{ width: '25%' }}
              disabled={disabled}
              onClick={submitUserInfo}
              compact
            >
              {userId ? 'Update' : 'Add'}
            </RayButton>
          </ButtonRow>
        </Columm>
      </Row>
    </FormWrapper>
  );
}

export default CreateUserForm;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: '0.5em',
    },
  }),
);
