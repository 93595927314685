import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import RayInput from 'components/RayInput';
import RayButton from 'components/RayButton';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { useOktaAuth } from '@okta/okta-react';

export interface CustomerForm {
  name: string;
  email: string;
  phone: string;
  leftHotstamp: string;
  rightHotstamp: string;
}

type Props = {
  toggleModal: React.Dispatch<boolean>;
  selectedValue: CustomerForm | null;
  updateCustomersData: any;
};

function EditCustomerForm({
  selectedValue,
  toggleModal,
  updateCustomersData,
}: Props) {
  //   const { register, handleSubmit, reset } = useForm<CustomerForm>({
  //     defaultValues: selectedValue ? selectedValue : undefined,
  //   });
  const [leftHotstamp, setLefthotstamp] = useState<string | undefined>(
    selectedValue && selectedValue.leftHotstamp
      ? selectedValue.leftHotstamp
      : '',
  );
  const [rightHotstamp, setRighthotstamp] = useState<string | undefined>(
    selectedValue && selectedValue.rightHotstamp
      ? selectedValue.rightHotstamp
      : '',
  );

  const { setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();

  const errorCheck = () => {
    
      if (!leftHotstamp || !leftHotstamp.match(/^[0-9]{10}$/)) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter a 10 digit number for left hotstamp',
        });
        return false;
      }
      if (!rightHotstamp || !rightHotstamp.match(/^[0-9]{7}$/)) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter a 7 digit number for right hotstamp',
        });
        return false;
      }
    
    return true;
  };

  const onSumbit = async () => {
    if (!errorCheck()) return;
    try {
      const body = {
        userId: selectedValue ? selectedValue.email : '',
        updateData: { leftHotstamp, rightHotstamp },
      };
      const token = authState?.accessToken?.accessToken;
      await axios.put(`customers/admin-update`, body, {
        headers: {
          Authorization: token,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: 'Successfully Updated the customer',
      });
      updateCustomersData();
      toggleModal(false);
    } catch (error) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Error Occurred while updating customer',
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RayInput
          label="Name"
          fullWidth
          disabled
          name="name"
          value={selectedValue ? selectedValue.name : ''}
        />
      </Grid>
      <Grid item xs={6}>
        <RayInput
          label="Email"
          fullWidth
          disabled
          name="email"
          value={selectedValue ? selectedValue.email : ''}
        />
      </Grid>
      <Grid item xs={6}>
        <RayInput
          label="Phone"
          fullWidth
          disabled
          name="phone"
          value={selectedValue ? selectedValue.phone : ''}
        />
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={6}>
          <RayInput
            label="Left Hot Stamp"
            fullWidth
            name="leftHotstamp"
            value={leftHotstamp}
            onChange={e =>
              setLefthotstamp(
                e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <RayInput
            label="Right Hot Stamp"
            fullWidth
            name="rightHotstamp"
            value={rightHotstamp}
            onChange={e =>
              setRighthotstamp(
                e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <RayButton type="submit" onClick={onSumbit}>
          Update
        </RayButton>
      </Grid>
    </Grid>
  );
}

export default EditCustomerForm;
