import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  Grid,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import axios from 'utils/axios';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FiDownload } from 'react-icons/fi';
import RayButton from '../../components/RayButton';
import RayInput from 'components/RayInput';
import Spinner from '../../assets/loading_spinner.svg';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';
import LogoGuidelinesImage from '../../assets/logo_guidelines.png';
import { emailInputRegex, numberInputRegex } from 'utils/constants';
import axiosODE from 'utils/axiosODE';
import SubDomain from './SubDomain';
import Counter from './Counter';
import InputDatePicker from './InputDatePicker';
import { useOktaAuth } from '@okta/okta-react';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip);

const EnterpriseWrapper = styled.div`
  .container {
    width: 100%;
    margin-top: 20px;
  }

  .priceExclusiveText {
    color: #666;
    margin-top: 5px;
    font-weight: 400;
    span {
      color: #ff1d1d;
    }
  }

  .domainAvailabilityCheckContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    div:first-child {
      margin-right: 5px;
      position: relative;
      top: 2px;
    }

    .vl {
      border-left: 1px solid #000;
      height: 20px;
      margin: 0.12rem 0 0 0.8rem;
    }

    .delete-button {
      cursor: pointer;
      margin: 0.5rem 0 0 0.5rem;
    }

    img {
      transform: rotate(0deg);
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-name: ${(props: DomainLoadingIconProps) =>
    props.checkAvailability ? Rotate : ''};
      transition: all 0.5s ease-in-out;
    }
  }
  .disableDomainCheck {
    color: #666;
  }

  .gstinAddressContainer {
    display: flex;
    flex-direction: column;
    padding: 0.1em;
    padding-bottom: 1em;
    border: 1px solid #666;
    border-radius: 0.35em;
  }

  .gstinAddressContainerLabel {
    padding-left: 0.35em;
    padding-right: 0.5em;
    position: relative;
    top: -1em;
    left: 0.4em;
    width: 4.5em;
    background-color: white;
    color: #666;
    font-size: 0.9em;
  }

  .addressContainer {
    margin-left: 1em;
    border: none;
  }

  .addressValue {
    font-weight: 400;
    color: #666;
    margin-bottom: 0.1em;
  }

  .fileInputField {
    width: 60%;
    margin: 1em 0;
  }

  .guidelines {
    display: flex;
    align-items: center;

    p {
      color: #0000ff;
    }
  }

  .sampleDownload {
    display: flex;
    align-items: center;
    color: blue;
    cursor: pointer;
    font-weight: bold;
  }

  h5 {
    margin-bottom: 0;
  }

  .submitBtn {
    background: transparent;
    border: none;
    padding: 1.1875em 1.4375em;
    color: white;
    cursor: pointer;
    width: 100%;
  }

  .duration-container{
    padding-top: 1em;
  }
`;

const Rotate = keyframes`
   from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface DomainLoadingIconProps {
  checkAvailability: boolean;
}
interface GstinLoadingIconProps {
  checkGstin: boolean;
}

const GSTINCheckWrapper = styled.div`
  .gstinCheckContainer {
    cursor: pointer;
    display: flex;
    align-items: center;

    div:first-child {
      margin-right: 5px;
      position: relative;
      top: 2px;
    }

    img {
      transform: rotate(0deg);
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-name: ${(props: GstinLoadingIconProps) =>
    props.checkGstin ? Rotate : ''};
      transition: all 0.5s ease-in-out;
    }
  }

  .disableGstinCheck {
    color: #666;
  }
`;

const ResetWrapper = styled.div`
  .resetSelection {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  div:first-child {
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      maxWidth: 250,
    },
  },
};

const NewEnterprise = () => {
  const { setToastType } = useAuthContext()!;
  const { authState} = useOktaAuth();
  const [uploadedCSVFiles, setUploadedCSVFiles] = useState({});
  const [uploadedLogoFiles, setUploadedLogoFiles] = useState({});
  const [parking, setParking] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [conferenceRoom, setConferenceRoom] = useState(false);
  const [canBookWithoutApproval, setCanBookWithoutApproval] = useState(false);
  const [canBookForOthers, setCanBookForOthers] = useState(false);
  const [
    canCancelRescheduleEnterpriseWideBookings,
    setCanCancelRescheduleEnterpriseWideBookings,
  ] = useState(false);
  const [
    canCancelRescheduleBookings,
    setCanCancelRescheduleBookings,
  ] = useState(false);
  const [hasDayPassBookingLimit, setHasDayPassBookingLimit] = useState(false);
  const [enterpriseName, setEnterpriseName] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [conferenceRoomPrice, setConferenceRoomPrice] = useState('');
  const [dayPassPrice, setDayPassPrice] = useState('');
  const [twoWheelerPrice, setTwoWheelerPrice] = useState('');
  const [fourWheelerPrice, setFourWheelerPrice] = useState('');
  const [colorPrice, setColorPrice] = useState('');
  const [blackWhitePrice, setBlackWhitePrice] = useState('');
  const [enterpriseDomain, setEnterpriseDomain] = useState('');
  const [legalName, setLegalName] = useState('');
  const [shortCode, setShortCode] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [pincode, setPincode] = useState('');
  const [gstin, setGstin] = useState('');
  const [primaryMember, setPrimaryMember] = useState('');
  const [primaryMemberEmail, setPrimaryMemberEmail] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [noOfBookingsAllowedPerDay, setNoOfBookingsAllowedPerDay] = useState(
    '',
  );
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const [checkingGstin, setCheckingGstin] = useState(false);
  const [isValidGstin, setIsValidGstin] = useState<Boolean | null>(null);
  const [isDomainAvailable, setIsDomainAvailable] = useState<Boolean | null>(
    null,
  );
  const [building, setBuilding] = useState<any[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<any[]>([]);
  const [selectAllBuildings, setSelectAllBuildings] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const logoRef = useRef<HTMLInputElement>(null);
  const csvRef = useRef<HTMLInputElement>(null);

  const [subdomainIndex, setSubdomainIndex] = useState<number>(-1);
  const [collectiveSubdomains, setCollectiveSubdomains] = useState<any[]>([]);
  const classes = useStyles();

  const [isAddSubDomainEnabled, setIsAddSubDomainEnabled] = useState(false);
  const [startDateOpen, setStartDateOpen] = useState<boolean>(false);
  const [endDateOpen, setEndDateOpen] = useState<boolean>(false);

  const [credits, setCredits] = useState('');

  const [startDate, handleStartDateChange] = useState<any>(null);
  const [endDate, handleEndDateChange] = useState<any>(null);

  const handleStartDateInputClick = () => {
    setStartDateOpen((prev: boolean) => !prev);
  };

  const handleEndDateInputClick = () => {
    setEndDateOpen((prev: boolean) => !prev);
  };

  useEffect(() => {
    if (isDomainAvailable) {
      if (
        collectiveSubdomains.length &&
        !collectiveSubdomains[collectiveSubdomains.length - 1][
        'isSubDomainAvailable'
        ]
      ) {
        setIsAddSubDomainEnabled(false);
      } else {
        setIsAddSubDomainEnabled(true);
      }
    }
  }, [isDomainAvailable, collectiveSubdomains]);

  const addEventHandler = () => {
    const id = subdomainIndex + 1;
    setSubdomainIndex(id);
    setCollectiveSubdomains(prev => [
      ...prev,
      {
        subDomainValue: '',
        isSubDomainAvailable: false,
      },
    ]);
  };
  

  useEffect(() => {
    setIsDomainAvailable(null);
  }, [enterpriseDomain]);

  useEffect(() => {
    setIsValidGstin(null);
  }, [gstin]);

  const reset = () => {
    setUploadedCSVFiles({});
    setUploadedLogoFiles({});
    setParking(false);
    setPrinting(false);
    setConferenceRoom(false);
    setCanBookWithoutApproval(false);
    setCanBookForOthers(false);
    setCanCancelRescheduleEnterpriseWideBookings(false);
    setCanCancelRescheduleBookings(false);
    setHasDayPassBookingLimit(false);
    setEnterpriseName('');
    setCreatedBy('');
    setConferenceRoomPrice('');
    setDayPassPrice('');
    setTwoWheelerPrice('');
    setFourWheelerPrice('');
    setColorPrice('');
    setBlackWhitePrice('');
    setEnterpriseDomain('');
    setLegalName('');
    setShortCode('');
    setAddress('');
    setCity('');
    setState('');
    setStateCode('');
    setPincode('');
    setGstin('');
    setPrimaryMember('');
    setPrimaryMemberEmail('');
    setLocationCode('');
    setNoOfBookingsAllowedPerDay('');
    setCheckingAvailability(false);
    setCheckingGstin(false);
    setIsValidGstin(null);
    setIsDomainAvailable(null);
    setSelectedBuildings([]);
    setSelectAllBuildings(false);
    setCollectiveSubdomains([]);
    setIsAddSubDomainEnabled(false);
    handleStartDateChange(null);
    handleEndDateChange(null);
    setCredits('');
    if (logoRef.current) {
      logoRef.current.value = '';
    }
    if (csvRef.current) {
      csvRef.current.value = '';
    }
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    let domainPattern = new RegExp(
      '^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$',
    );
    let domainMatch = domainPattern.test(enterpriseDomain);
    // let shortCodePattern = new RegExp('^(?=\\D*\\d)[a-zA-Z0-9]{6}$');
    // let shortCodeMatch = shortCodePattern.test(shortCode);

    if (!domainMatch) {
      setIsSubmitting(false);
      return setToastType({
        show: true,
        type: 'error',
        message: 'Enter a valid domain name',
      });
    }
    // if (!shortCodeMatch) {
    //   return setToastType({
    //     show: true,
    //     type: 'error',
    //     message: 'Enter a valid location code',
    //   });
    // }

    const isAnySubdomainEmpty = collectiveSubdomains.find(subDomain => !subDomain.subDomainValue);
    if (!!isAnySubdomainEmpty) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Please enter the subdomain to check the availability',
      });
      setIsSubmitting(false);
      return;
    }

    const isPendingToVerifySubDomains = collectiveSubdomains.find(subDomain => !subDomain.isSubDomainAvailable);
    if (!!isPendingToVerifySubDomains) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Please check availability of entered subdomain',
      });
      setIsSubmitting(false);
      return;
    }

    if (isValidGstin === null) {
      setIsSubmitting(false);
      return setToastType({
        show: true,
        type: 'error',
        message: 'Validate the GSTIN to generate address which is required',
      });
    }

    if (!isValidGstin) {
      setIsSubmitting(false);
      return setToastType({
        show: true,
        type: 'error',
        message: 'Invalid GSTIN. Contact Support or Enter a valid GSTIN.',
      });
    }

    if(!startDate || !credits || !endDate){
      setIsSubmitting(false);
      return setToastType({
        show: true,
        type: 'error',
        message: 'Please fill all free credits fields',
      });
    }

    const formData = new FormData();
    const csv: any = Object.values(uploadedCSVFiles);
    const logo: any = Object.values(uploadedLogoFiles);
    for (let i = 0; i < csv.length; i++) {
      formData.append(`files`, csv[i]);
    }
    for (let i = 0; i < logo.length; i++) {
      formData.append(`files`, logo[i]);
    }
    if (csv.length === 0 && logo.length === 0) {
      alert(
        'Please upload a CSV file and company logo image to add enterprise',
      );
    }

    const subDomains = collectiveSubdomains
      ?.filter(subDomain => subDomain.isSubDomainAvailable)
      ?.map(subDomain => subDomain.subDomainValue)
      ?.join(',');

    var blob = JSON.stringify({
      enterprise_name: enterpriseName,
      created_by: createdBy,
      day_pass_price: +dayPassPrice,
      conference_room_price: +conferenceRoomPrice,
      two_wheeler_price: +twoWheelerPrice,
      four_wheeler_price: +fourWheelerPrice,
      color_price: +colorPrice,
      black_white_price: +blackWhitePrice,
      enterprise_domain: subDomains
        ? `${enterpriseDomain},${subDomains}`
        : enterpriseDomain,
      can_book_without_approval: canBookWithoutApproval,
      parking_required: parking,
      conference_room_required: conferenceRoom,
      printing_required: printing,
      can_book_for_others: canBookForOthers,
      has_day_pass_booking_limit: hasDayPassBookingLimit,
      can_cancel_reschedule_enterprise_wide_bookings: canCancelRescheduleEnterpriseWideBookings,
      can_cancel_reschedule_bookings: canCancelRescheduleBookings,
      enterprise_legal_name: legalName,
      short_code: shortCode,
      gstin: gstin,
      primary_member: primaryMember,
      primary_member_email: primaryMemberEmail,
      location_code: locationCode,
      address: address,
      city: city,
      state: state,
      pin_code: pincode,
      state_code: pincode,
      allBuildings: selectAllBuildings,
      buildings: selectedBuildings.map(building => ({
        id: building.id,
      })),
      no_of_bookings_allowed_per_day: hasDayPassBookingLimit
        ? noOfBookingsAllowedPerDay.toString()
        : '',
    });
    formData.append('data', blob);
   
    try {
      const response = await axiosODE.post('/api/automation/enterprise/', formData, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:authState?.accessToken?.accessToken,
        },
      });

      const creditBody ={
        enterpriseName: response?.data?.data?.domain,
        totalCredit: credits,
        type: "conference_room",
        operation: "free_credit_add",
        startDate: startDate,
        endDate: endDate
    }
      await axiosODE.post('/api/credit-wallet/admin-transaction', creditBody, {
        method: 'POST',
        headers: {
          Authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: 'Enterprise Data added!',
      });
      setIsSubmitting(false);

      reset();
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error.response ? error.response.data.message : error.message,
      });
      setIsSubmitting(false);
    }
  };

  const domainCheck = async () => {
    setCheckingAvailability(true);
    if (!enterpriseDomain) {
      setCheckingAvailability(false);
      return setToastType({
        show: true,
        type: 'error',
        message: 'Please enter the domain to check the availability',
      });
    }

    let pattern = new RegExp(
      '^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$',
    );
    let match = pattern.test(enterpriseDomain);

    if (!match) {
      setIsDomainAvailable(false);
      setCheckingAvailability(false);
      return setToastType({
        show: true,
        type: 'error',
        message: 'Enter a valid domain name',
      });
    }

    try {
      const response = await axiosODE.get(
        `/api/automation/enterprise/domain?enterpriseDomain=${enterpriseDomain}`,
        {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        },
      );
      if (response.status === 200) {
        let status =
          response.data.message === 'Domain already exists'
            ? 'error'
            : 'success';

        setToastType({
          show: true,
          type: status === 'success' ? 'success' : 'error',
          message: response.data.message,
        });
        setCheckingAvailability(false);
        if (status === 'success') {
          setIsDomainAvailable(true);
        } else {
          setIsDomainAvailable(false);
        }
      }
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error.response ? error.response.data.message : error.message,
      });
      setCheckingAvailability(false);
    }
  };

  const gstinValidation = async () => {
    setCheckingGstin(true);
    try {
      const response = await axiosODE.get(
        `/api/automation/enterprise/address?gstin=${gstin}`,
        {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        },
      );
      if (response.status === 200) {
        setCheckingGstin(false);
        setIsValidGstin(true);
        if (response.data.data.address) {
          let address = response.data.data.address;
          setAddress(address.line1 + ' ' + address.line2);
          setCity(address.city);
          setState(address.state);
          setStateCode(address.state_code);
          setPincode(address.pin_code);
        }
      }
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error.response ? error.response.data.message : error.message,
      });
      setCheckingGstin(false);
      setIsValidGstin(false);
    }
  };
  const fetchAllBuildings = async () => {
    const getAllBuildings = await axios.get('/buildings/get', {
      headers: {
        Authorization: authState?.accessToken?.accessToken,
      },
    });
    const buildingsArr = getAllBuildings.data.map((building: any) => building);
    setBuilding(buildingsArr);
  };

  useEffect(() => {
    fetchAllBuildings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBuilding = (event: React.ChangeEvent<any>, value: any) => {
    if (
      selectedBuildings.findIndex(
        (building: any) => building.id === value.props.value.id,
      ) === -1
    ) {
      setSelectedBuildings(oldValue => [...oldValue, value.props.value]);
    } else {
      setSelectedBuildings(oldValue => [
        ...oldValue.filter(building => building.id !== value.props.value.id),
      ]);
    }
  };
  const handleLogoUpload = (event: any) => {
    setUploadedLogoFiles(event.target.files);
  };

  const handleFileUpload = (event: any) => {
    setUploadedCSVFiles(event.target.files);
  };

  useEffect(() => {
    if (!canBookWithoutApproval) {
      setHasDayPassBookingLimit(false);
    }
  }, [canBookWithoutApproval]);

  const handleReset = () => {
    setSelectedBuildings([]);
  };

  const deleteEventHandler = (objIndex: number) => {
    const updatedSubdomains = [...collectiveSubdomains].filter(
      (subdomain: any, index) => index !== objIndex,
    );
    setCollectiveSubdomains(updatedSubdomains);
  };

  const handleStartDate = (e: any) => {
    if (e) {
      handleStartDateChange(new Date(e));
    }
  };

  const handleEndDate = (e: any) => {
    if (e) {
      handleEndDateChange(new Date(e));
    }
  };

  return (
    <EnterpriseWrapper checkAvailability={checkingAvailability}>
      <form onSubmit={e => onSubmit(e)}>
        <Grid container direction="column" className="container" spacing={2}>
          <Grid item>
            <h4>Enterprise Details</h4>
          </Grid>
          <Grid item xs={10} md={6} xl={4}>
            <RayInput
              id="enterprise_name"
              label="Enterprise Name"
              name="enterprise_name"
              value={enterpriseName}
              onChange={e =>
                setEnterpriseName(
                  e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                )
              }
              fullWidth
              autoMargin={false}
              placeholder="ABC enterprises Ltd."
              required
            />
          </Grid>
          <Grid item xs={10} md={6} xl={4}>
            <RayInput
              id="legal_name"
              label="Legal Name"
              name="legal_name"
              value={legalName}
              onChange={e =>
                setLegalName(
                  e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                )
              }
              fullWidth
              autoMargin={false}
              placeholder="ABC enterprises Ltd."
              required
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            xl={8}
            spacing={1}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item xs={6}>
              <RayInput
                id="enterprise_domain"
                label="Enterprise Domain"
                name="enterprise_domain"
                value={enterpriseDomain}
                onChange={e =>
                  setEnterpriseDomain(
                    e.target.value.replace(emailInputRegex, ''),
                  )
                }
                fullWidth
                autoMargin={false}
                placeholder="abc.co.in"
                required
                pattern="(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}"
              />
            </Grid>
            <Grid item xs={6}>
              <div
                className={`domainAvailabilityCheckContainer ${!enterpriseDomain &&
                  'disableDomainCheck'}`}
                onClick={domainCheck}
              >
                {!isDomainAvailable && (
                  <>
                    <div>
                      {isDomainAvailable !== null ? (
                        isDomainAvailable === true ? (
                          <TiTick
                            color="#4BB543"
                            size={30}
                            style={{ marginRight: 0 }}
                          />
                        ) : (
                          <ImCross color="#F00" />
                        )
                      ) : (
                        <img src={Spinner} alt="" />
                      )}
                    </div>
                    <p>
                      {checkingAvailability && enterpriseDomain
                        ? 'Checking '
                        : 'Check '}
                      Availability
                    </p>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
          {collectiveSubdomains.map((each: any, index: number) => (
            <SubDomain
              index={index}
              value={each.subDomainValue}
              isDisabled={each.isSubDomainAvailable}
              handleOnchange={setCollectiveSubdomains}
              collectiveSubdomains={collectiveSubdomains}
              deleteEventHandler={deleteEventHandler}
            />
          ))}
          <Grid item xs={11}>
            <p
              onClick={addEventHandler}
              className={`${classes.addNoteBtn} ${!isAddSubDomainEnabled &&
                classes.disabled}`}
            >
              + Add subdomain
            </p>
          </Grid>
          <Grid item xs={10} md={6} xl={4}>
            <RayInput
              id="created_by"
              label="Created By Email"
              name="created_by"
              fullWidth
              autoMargin={false}
              placeholder="tony@xyz.com"
              value={createdBy}
              onChange={e =>
                setCreatedBy(
                  e.target.value.toLowerCase().replace(emailInputRegex, ''),
                )
              }
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
          </Grid>

          <Grid item xs={10} md={6} xl={4}>
            <RayInput
              id="short_code"
              label="Short Code"
              name="short_code"
              value={shortCode}
              onChange={e =>
                setShortCode(
                  e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                )
              }
              fullWidth
              autoMargin={false}
              placeholder="1APDXZ"
              required
            // pattern="(?=\D*\d)[a-zA-Z0-9]{6}"
            />
          </Grid>
          <Grid item xs={10} md={6} xl={4}>
            <RayInput
              id="primary_member"
              label="Primary Member Name"
              name="primary_member"
              value={primaryMember}
              onChange={e =>
                setPrimaryMember(
                  e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                )
              }
              fullWidth
              autoMargin={false}
              placeholder="Tony"
              required
            />
          </Grid>
          <Grid item xs={10} md={6} xl={4}>
            <RayInput
              id="primary_member_email"
              label="Primary Member Email"
              name="primary_member_email"
              value={primaryMemberEmail}
              onChange={e =>
                setPrimaryMemberEmail(
                  e.target.value
                    .toLowerCase()
                    .replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                )
              }
              fullWidth
              autoMargin={false}
              placeholder="tony@xyz.com"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
            />
          </Grid>
          <Grid item xs={10} md={6} xl={4}>
            <RayInput
              id="location_code"
              label="Location Code"
              name="location_code"
              value={locationCode}
              onChange={e =>
                setLocationCode(
                  e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                )
              }
              fullWidth
              autoMargin={false}
              placeholder="BLR01"
              required
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            xl={8}
            spacing={1}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item xs={6}>
              <RayInput
                id="gstin"
                label="GSTIN"
                name="gstin"
                value={gstin}
                onChange={e =>
                  setGstin(
                    e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                  )
                }
                fullWidth
                autoMargin={false}
                placeholder="29AAGCK8114J1Z8"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <GSTINCheckWrapper checkGstin={checkingGstin}>
                <div
                  className={`gstinCheckContainer ${!gstin &&
                    'disableGstinCheck'}`}
                  onClick={gstinValidation}
                >
                  <div>
                    {isValidGstin !== null ? (
                      isValidGstin === true ? (
                        <TiTick
                          color="#4BB543"
                          size={30}
                          style={{ marginRight: 0 }}
                        />
                      ) : (
                        <ImCross color="#F00" />
                      )
                    ) : (
                      <img src={Spinner} alt="" />
                    )}
                  </div>
                  <p>
                    {checkingGstin && gstin ? 'Validating ' : 'Validate '}
                    GSTIN
                  </p>
                </div>
              </GSTINCheckWrapper>
            </Grid>
          </Grid>

          {isValidGstin === true && (
            <Grid item xs={10} md={6} xl={4}>
              <div className="gstinAddressContainer">
                <label className="gstinAddressContainerLabel">Address</label>
                <div className="addressContainer">
                  <h6 className="addressValue">{address}</h6>
                </div>
                <div className="addressContainer">
                  <h6 className="addressValue">{city}</h6>
                </div>
                <div className="addressContainer">
                  <h6 className="addressValue">{state}</h6>
                </div>
                <div className="addressContainer">
                  <h6 className="addressValue">{stateCode}</h6>
                </div>
                <div className="addressContainer">
                  <h6 className="addressValue">{pincode}</h6>
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={10} md={6} xl={4}>
            <Grid item>
              <RayInput
                id="no_of_bookings_allowed_per_day"
                label="Number of bookings allowed per day"
                name="no_of_bookings_allowed_per_day"
                value={noOfBookingsAllowedPerDay}
                disabled={!hasDayPassBookingLimit}
                onChange={e =>
                  setNoOfBookingsAllowedPerDay(
                    e.target.value.replace(numberInputRegex, ''),
                  )
                }
                fullWidth
                autoMargin={false}
                placeholder="10"
                required={hasDayPassBookingLimit}
              />
            </Grid>
            <Grid item style={{ float: 'right' }}>
              <LightTooltip
                title={
                  <div style={{ marginLeft: '1em' }}>
                    <li style={{ textIndent: '-1em' }}>
                      If the enterprise has day pass booking limit then the
                      number of bookings allowed per day needs to be specified
                    </li>
                  </div>
                }
                placement="bottom-start"
              >
                <h6 style={{ color: '#0000FF' }}>Read more</h6>
              </LightTooltip>
            </Grid>
          </Grid>
          <br />
          <Grid item container xs={10} md={6} xl={4} spacing={2}>
            <Grid item>
              <h4>Pricing Details</h4>
            </Grid>
            <Grid item>
              <h6 className="priceExclusiveText">
                (<span>*</span>exclusive of taxes in INR)
              </h6>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={10}
            md={6}
            xl={4}
            spacing={1}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item xs={12}>
              <RayInput
                id="conference_room_price"
                label="Conference Room Price"
                name="conference_room_price"
                value={conferenceRoomPrice}
                onChange={e =>
                  setConferenceRoomPrice(
                    e.target.value.replace(numberInputRegex, ''),
                  )
                }
                fullWidth
                autoMargin={false}
                placeholder="250"
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={6}
            xl={4}
            spacing={1}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item xs={12}>
              <RayInput
                id="day_pass_price"
                label="Day Pass Price"
                name="day_pass_price"
                value={dayPassPrice}
                onChange={e =>
                  setDayPassPrice(e.target.value.replace(numberInputRegex, ''))
                }
                fullWidth
                autoMargin={false}
                placeholder="500"
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={6}
            xl={4}
            spacing={1}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item xs={12}>
              <RayInput
                id="two_wheeler_price"
                label="Two Wheeler Parking Price"
                name="two_wheeler_price"
                value={twoWheelerPrice}
                onChange={e =>
                  setTwoWheelerPrice(
                    e.target.value.replace(numberInputRegex, ''),
                  )
                }
                fullWidth
                autoMargin={false}
                placeholder="35"
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={6}
            xl={4}
            spacing={1}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item xs={12}>
              <RayInput
                id="four_wheeler_price"
                label="Four Wheeler Parking Price"
                name="four_wheeler_price"
                value={fourWheelerPrice}
                onChange={e =>
                  setFourWheelerPrice(
                    e.target.value.replace(numberInputRegex, ''),
                  )
                }
                fullWidth
                autoMargin={false}
                placeholder="80"
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={6}
            xl={4}
            spacing={1}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item xs={12}>
              <RayInput
                id="color_price"
                label="Color Printout Price"
                name="color_price"
                value={colorPrice}
                onChange={e =>
                  setColorPrice(e.target.value.replace(numberInputRegex, ''))
                }
                fullWidth
                autoMargin={false}
                placeholder="30"
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={6}
            xl={4}
            spacing={1}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Grid item xs={12}>
              <RayInput
                id="black_white_price"
                label="Black and white Printout Price"
                name="black_white_price"
                value={blackWhitePrice}
                onChange={e =>
                  setBlackWhitePrice(
                    e.target.value.replace(numberInputRegex, ''),
                  )
                }
                fullWidth
                autoMargin={false}
                placeholder="5"
                required
              />
            </Grid>
          </Grid>
          <br/>
          <Grid item xs={10}
            md={6}
            xl={4}>
            <h4>Conference room free credits</h4>
            <Counter value={credits} onClick={setCredits} label={'Add free credits per month'} />
            <Grid container alignItems='center' spacing={2} className='duration-container'>
              <Grid item xs={2}>
                Duration:
              </Grid>
              <Grid item xs={5}>
                <InputDatePicker label={'Start Date'} open={startDateOpen} setOpen={setStartDateOpen} value={startDate} handleInputClick={handleStartDateInputClick} handleValue={handleStartDate}  minDate={new Date()} />
              </Grid>
              <Grid item xs={5}>
                <InputDatePicker label={'End Date'} open={endDateOpen} setOpen={setEndDateOpen} value={endDate} handleInputClick={handleEndDateInputClick} handleValue={handleEndDate}  minDate={startDate} isDisabled={!startDate}/>
              </Grid>
            </Grid>
          </Grid>
          <br/>
          <Grid item xs={12}>
            <Grid item>
              <h4>Add Buildings For Enterprise:</h4>
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllBuildings}
                  onChange={e => {
                    setSelectAllBuildings(e.target.checked);
                    setDisabled(e.target.checked);
                  }}
                  value="Give access to all buildings"
                  color="primary"
                />
              }
              label="Give access to all buildings"
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            xl={8}
            spacing={1}
            direction="row"
            alignItems="flex-end"
            justify="flex-start"
          >
            <Grid
              item
              xs={6}
              style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            >
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Building
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedBuildings}
                  onChange={handleBuilding}
                  renderValue={(selected: any) =>
                    selected
                      .map((selectedName: any) => selectedName.name)
                      .join(', ')
                  }
                  disabled={selectAllBuildings}
                  MenuProps={MenuProps}
                >
                  {building &&
                    building.map(buildingName => (
                      <MenuItem key={buildingName.id} value={buildingName}>
                        <Checkbox
                          checked={
                            selectedBuildings.findIndex(
                              building => building.id === buildingName.id,
                            ) >= 0
                          }
                          color="primary"
                        />
                        <ListItemText primary={buildingName.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            >
              <ResetWrapper>
                <div className="resetSelection" onClick={handleReset}>
                  <p>Clear Building Selection</p>
                </div>
              </ResetWrapper>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              justify="space-between"
              xs={10}
              md={6}
              xl={4}
            >
              <Grid item>
                <h4>Enterprise Permissions:</h4>
              </Grid>
              <Grid item>
                <LightTooltip
                  title={
                    <div style={{ marginLeft: '1em' }}>
                      <li style={{ textIndent: '-1em' }}>
                        If approval is required, The order will not be marked
                        complete until this step has been completed by an
                        admin/manager{' '}
                      </li>
                      <li style={{ textIndent: '-1em' }}>
                        If approval is not required, selecting the ‘Without
                        Approval’ box will allow the order to go through without
                        admin/manager review
                      </li>
                      <li style={{ textIndent: '-1em' }}>
                        An admin or manager will always be able to submit an
                        order without approval.
                      </li>
                      <li style={{ textIndent: '-1em' }}>
                        Day Pass Booking Limit can only be applicable when there
                        is no approval required to make the bookings
                      </li>
                      <li style={{ textIndent: '-1em' }}>
                        If an admin can cancel/reschedule the enterprise wide
                        bookings then the emloyees of that enterprise cannot
                        cancel/reschedule their own bookings
                      </li>
                      <li style={{ textIndent: '-1em' }}>
                        If employees can cancel/reschedule their own bookings
                        then admins of that enterprise cannot cancel/reschedule
                        enterprise wide bookings
                      </li>
                    </div>
                  }
                  placement="bottom-start"
                >
                  <h6 style={{ color: '#0000FF' }}>Read more</h6>
                </LightTooltip>
              </Grid>
            </Grid>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={canBookWithoutApproval}
                      onChange={e =>
                        setCanBookWithoutApproval(e.target.checked)
                      }
                      value="Can Book Without Approval"
                      color="primary"
                    />
                  }
                  label="No Approval required"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printing}
                      onChange={e => setPrinting(e.target.checked)}
                      value="Can Book Printing"
                      color="primary"
                    />
                  }
                  label="Printing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={parking}
                      onChange={e => setParking(e.target.checked)}
                      value="Can Book Parking"
                      color="primary"
                    />
                  }
                  label="Parking"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conferenceRoom}
                      onChange={e => setConferenceRoom(e.target.checked)}
                      value="Can Book Conference Room"
                      color="primary"
                    />
                  }
                  label="Conference Room"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={canBookForOthers}
                      onChange={e => setCanBookForOthers(e.target.checked)}
                      value="Admin can book for others"
                      color="primary"
                    />
                  }
                  label="Admin Can Book for Others"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasDayPassBookingLimit}
                      onChange={e =>
                        setHasDayPassBookingLimit(e.target.checked)
                      }
                      value="Enterprise has day pass booking limit"
                      color="primary"
                      disabled={!canBookWithoutApproval}
                    />
                  }
                  label="Day Pass Booking Limit"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={canCancelRescheduleEnterpriseWideBookings}
                      onChange={e =>
                        setCanCancelRescheduleEnterpriseWideBookings(
                          e.target.checked,
                        )
                      }
                      value="Admin can cancel and reschedule enterprise wide bookings"
                      color="primary"
                      disabled={canCancelRescheduleBookings}
                    />
                  }
                  label="Admin can cancel and reschedule enterprise wide bookings"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={canCancelRescheduleBookings}
                      onChange={e =>
                        setCanCancelRescheduleBookings(e.target.checked)
                      }
                      value="Employees can cancel and reschedule their own bookings"
                      color="primary"
                      disabled={canCancelRescheduleEnterpriseWideBookings}
                    />
                  }
                  label="Employees can cancel and reschedule their own bookings"
                />
              </FormGroup>
            </FormControl>
            <Grid
              item
              xs={12}
              md={9}
              lg={7}
              xl={6}
              style={{ margin: '20px 0' }}
            >
              <Grid item container alignItems="center" justify="center">
                <Grid item xs={3}>
                  <h5>Upload Logo</h5>
                </Grid>
                <Grid
                  item
                  container
                  xs={9}
                  alignItems="center"
                  justify="space-between"
                >
                  <input
                    ref={logoRef}
                    type="file"
                    name="files"
                    accept="image/*"
                    onChange={e => handleLogoUpload(e)}
                    className="fileInputField"
                    required
                  />
                  <LightTooltip
                    title={
                      <div style={{ marginLeft: '1em' }}>
                        <li style={{ textIndent: '-1em' }}>
                          Please upload in jpeg/jpg / png formats only. File
                          size limit: upto 1MB each.
                        </li>
                        <img
                          src={LogoGuidelinesImage}
                          style={{ width: '100%', height: '100%' }}
                          alt=""
                        />
                        <br />

                        <div>
                          <div style={{ listStyleType: 'decimal' }}>
                            <li style={{ textIndent: '-1em' }}>
                              Logos should be minimum of 190px(width) and
                              50px(height) in dimensions.
                            </li>
                            <div
                              style={{
                                listStyleType: 'disc',
                                marginLeft: '1em',
                              }}
                            >
                              <li style={{ textIndent: '-1em' }}>
                                Logos larger than this size will snap right to
                                the size
                              </li>
                              <li style={{ textIndent: '-1em' }}>
                                Logos shorter than this size will appear
                                pixelated in this size
                              </li>
                            </div>
                            <br />

                            <li style={{ textIndent: '-1em' }}>
                              Prefer to use dark-coloured logo as the background
                              to this navigation bar is white.
                            </li>
                            <div
                              style={{
                                listStyleType: 'disc',
                                marginLeft: '1em',
                              }}
                            >
                              <li style={{ textIndent: '-1em' }}>
                                If the logo itself is white, prefer to ask a
                                logo with a dark background.
                              </li>
                            </div>
                            <br />

                            <li style={{ textIndent: '-1em' }}>
                              Any shape can fit into this design as long as it
                              has a minimum of 50px(height)
                            </li>
                          </div>
                        </div>
                      </div>
                    }
                    placement="right"
                  >
                    <div className="guidelines">
                      <AiOutlineInfoCircle
                        color="#0000FF"
                        style={{ marginRight: '7px' }}
                      />
                      <p>Guidelines</p>
                    </div>
                  </LightTooltip>
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={3}>
                  <h5 style={{ marginBottom: 0 }}>Upload CSV</h5>
                </Grid>
                <Grid
                  item
                  container
                  xs={9}
                  alignItems="center"
                  justify="space-between"
                >
                  <input
                    ref={csvRef}
                    type="file"
                    name="files"
                    accept=".csv"
                    onChange={e => handleFileUpload(e)}
                    className="fileInputField"
                    required
                  />
                  <LightTooltip
                    title={
                      <div style={{ marginLeft: '1em' }}>
                        <li style={{ textIndent: '-1em' }}>
                          The CSV can only have Name,Email,Role,Phone,Approver
                          columns.
                        </li>
                        <li style={{ textIndent: '-1em' }}>
                          All fields mentioned in the sample CSV are mandatory.
                        </li>
                        <li style={{ textIndent: '-1em' }}>
                          Approver’s email is mandatory.
                        </li>
                        <li style={{ textIndent: '-1em' }}>
                          There should be only a single phone number in each
                          phone number field.
                        </li>
                      </div>
                    }
                    placement="right"
                  >
                    <div className="guidelines">
                      <AiOutlineInfoCircle
                        color="#0000FF"
                        style={{ marginRight: '7px' }}
                      />
                      <p>Guidelines</p>
                    </div>
                  </LightTooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          style={{ margin: '20px 0' }}
        >
          <Grid item xs={6}>
            <div
              className="sampleDownload"
              onClick={() =>
                window.open(
                  'https://firebasestorage.googleapis.com/v0/b/wework-staging.appspot.com/o/enterprise-accounts%2FCSVs%2FSample-csv-for-employee-data.csv?alt=media&token=76e7fe7b-f1b2-4838-a766-d6282d6279cc',
                  '_blank',
                )
              }
            >
              <FiDownload size={20} style={{ marginRight: '1em' }} />
              Download Sample Employee CSV Data
            </div>
          </Grid>
          <Grid item xs={4} md={2}>
            <RayButton style={{ padding: 0 }} fullWidth>
              {isSubmitting ? (
                <div style={{ padding: '10px 0' }}>
                  <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
                </div>
              ) : (
                <input className="submitBtn" type="submit" value="Submit" />
              )}
            </RayButton>
          </Grid>
        </Grid>
      </form>
    </EnterpriseWrapper>
  );
};

export default NewEnterprise;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundYellow: {
      backgroundColor: '#FFD500',
      borderRadius: '2px',
      padding: '0.5em',
      marginBottom: '1em',
    },

    variantWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    addNoteBtn: {
      fontSize: '1.1em',
      color: '#0000FF',
      marginBottom: '1em',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    loaderGrid: {
      textAlign: 'center',
      '& svg': {
        color: '#0000FF',
      },
    },
    mt: {
      marginTop: '0.6em',
    },
    fieldError: {
      color: '#CC0000',
    },
    disabled: {
      color: '#6060c9',
      pointerEvents: 'none',
    },
  }),
);
