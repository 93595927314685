import { Button, CircularProgress, Grid } from '@material-ui/core';
import app from 'utils/firebase';
import React, { useState } from 'react';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import styled from 'styled-components';
import { getBrowser } from 'utils/functions/getBrowser';

const downloadFile = (file: string) => {
  const anchor = document.createElement('a');
  anchor.setAttribute('href', file);
  anchor.target = '_blank';
  anchor.click();
};


const LMSPage = () => {
  const [loader, setLoader] = useState(false);
  const isOtherBrowser = !["Chrome", "Firefox"].includes(getBrowser()?.name||"") 
  const {
    setToastType,
    userInfo: { hasLMSAccess },
  } = useAuthContext()!;

  const getReportURL = async () => {
    if (!hasLMSAccess)
      return void setToastType({
        show: true,
        type: 'error',
        message: 'Unauthorized Access',
      });
    setLoader(true);
    try {
      const fileUrl = await app.getFileFromStorage(
        'lms/historical-lms-data.csv',
      );
      setTimeout(() => {
        downloadFile(fileUrl);
        setLoader(false);
        setToastType({
          show: true,
          type: 'success',
          message: 'Successfully fetched URL. Download will start soon',
        });
      }, 2000);
    } catch (error) {
      console.error(error);
      setLoader(false);
      setToastType({
        show: true,
        type: 'error',
        message: 'Something went wrong. Please try again',
      });
    }
  };
  return (
    <LMSWrapper>
      <Grid className="label-container">
        <h3 className="heading">LMS Historical Data</h3>
        <p>The report contains all the historical leads before 21st November</p>
        {isOtherBrowser && (<p className='warning'>Please use Chrome or Firefox to download the file</p>) }
      </Grid>
      <Button
        variant="contained"
        color="primary"
        disabled={loader || isOtherBrowser}
        onClick={getReportURL}
        className="btn"
      >
        {loader ? (
          <CircularProgress size={24} style={{ color: '#000000' }} />
        ) : (
          'Download Report'
        )}
      </Button>
    </LMSWrapper>
  );
};

export default LMSPage;

const LMSWrapper = styled(Grid)`
  width: 100%;
  margin: 20px 0;
  .warning {
    color: red;
    font-size: 14px;
  }
  .btn {
    margin-top: 10px;
    min-width: 200px;
    &:disabled {
      cursor: not-allowed;
    }
  }
`;
