import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

type Props = {
  onClick?:
    | ((event: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void)
    | undefined;
  style?: React.CSSProperties;
  className?: string;
  mb0?: boolean;
  children: React.ReactNode;
};

const RAY_HEADING_CLASS = "ray-text--h";
const RAY_DISPLAY_CLASS = "ray-text--display-";

export function Display1({
  onClick,
  style,
  children,
  className = "",
  mb0 = false,
}: Props) {
  const classes = useStyles();
  return (
    <h1
      onClick={onClick}
      style={style}
      className={`${RAY_DISPLAY_CLASS}1 ${
        mb0 ? classes.zeroMarginBottom : ""
      } ${className}`}
    >
      {children}
    </h1>
  );
}

export function Display2({
  onClick,
  style,
  children,
  className = "",
  mb0 = false,
}: Props) {
  const classes = useStyles();

  return (
    <h2
      onClick={onClick}
      style={style}
      className={`${RAY_DISPLAY_CLASS}2 ${
        mb0 ? classes.zeroMarginBottom : ""
      } ${className}`}
    >
      {children}
    </h2>
  );
}

export function H1({
  onClick,
  style,
  children,
  className = "",
  mb0 = false,
}: Props) {
  const classes = useStyles();

  return (
    <h1
      onClick={onClick}
      style={style}
      className={`${RAY_HEADING_CLASS}1 ${
        mb0 ? classes.zeroMarginBottom : ""
      } ${className}`}
    >
      {children}
    </h1>
  );
}

export function H2({
  onClick,
  style,
  children,
  className = "",
  mb0 = false,
}: Props) {
  const classes = useStyles();

  return (
    <h2
      onClick={onClick}
      style={style}
      className={`${RAY_HEADING_CLASS}2 ${
        mb0 ? classes.zeroMarginBottom : ""
      } ${className}`}
    >
      {children}
    </h2>
  );
}

export function H3({
  onClick,
  style,
  children,
  className = "",
  mb0 = false,
}: Props) {
  const classes = useStyles();

  return (
    <h3
      onClick={onClick}
      style={style}
      className={`${RAY_HEADING_CLASS}3 ${
        mb0 ? classes.zeroMarginBottom : ""
      } ${className}`}
    >
      {children}
    </h3>
  );
}

export function H4({
  onClick,
  style,
  children,
  className = "",
  mb0 = false,
}: Props) {
  const classes = useStyles();

  return (
    <h4
      onClick={onClick}
      style={style}
      className={`${RAY_HEADING_CLASS}4 ${
        mb0 ? classes.zeroMarginBottom : ""
      } ${className}`}
    >
      {children}
    </h4>
  );
}

export function H5({
  onClick,
  style,
  children,
  className = "",
  mb0 = false,
}: Props) {
  const classes = useStyles();

  return (
    <h5
      onClick={onClick}
      style={style}
      className={`${RAY_HEADING_CLASS}5 ${
        mb0 ? classes.zeroMarginBottom : ""
      } ${className}`}
    >
      {children}
    </h5>
  );
}
export function H6({
  onClick,
  style,
  children,
  className = "",
  mb0 = false,
}: Props) {
  const classes = useStyles();

  return (
    <h6
      onClick={onClick}
      style={style}
      className={`${RAY_HEADING_CLASS}6 ${
        mb0 ? classes.zeroMarginBottom : ""
      } ${className}`}
    >
      {children}
    </h6>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    zeroMarginBottom: {
      marginBottom: 0,
    },
  })
);
