import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ReservationDocument } from './types';
import { format } from 'date-fns';
import RayButton from 'components/RayButton';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { InvoiceData } from 'containers/Bookings/TableContainer';
import styled from 'styled-components';
import pdfIcon from '../../assets/pdf_icon.png';
import { LightTooltip } from 'containers/CustomersPage/CustomersTable';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import SendKYCEmail from 'containers/CustomersPage/SendKYCEmail';
import { getKYCTooltipContent, getUserKYCDetails } from 'utils/getKYCDetails';
import { useOktaAuth } from '@okta/okta-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'start',
    },
    tableParent: {
      width: '100%',
    },
    rayTable: {
      fontSize: '14px',
      width: '100%',
    },
    addOnBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    userKyc: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
    infoIcon:{
      marginRight: '7px',
       marginTop: 6
    }
  }),
);

const InvoiceWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;

type Props = {
  reservationInfo: ReservationDocument | undefined;
  isUpComing: boolean;
  fetchReservations: (buildingId: number, conferenceRoomId?: string) => void;
  invoiceResponse: InvoiceData;
};

function ViewReservationDetails({
  reservationInfo,
  isUpComing,
  fetchReservations,
  invoiceResponse,
}: Props) {
  const classes = useStyles();

  const [canShowCancelButton, setCanShowCancelButton] = useState(false);
  const { setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const [disabled, setDisabled] = useState<boolean>(false);
  // const [vaccinationStatus, setVaccinationStatus] = useState<boolean>(false);
  const [memebersKYCDetails, setMembersKYCDetails] = useState<any>({});
  const [isLoadingKYCDetails, setIsLoadingKYCDetails] = useState<boolean>(false);

  useEffect(() => {
    if (isUpComing && reservationInfo && reservationInfo.status === 'BOOKED') {
      setCanShowCancelButton(true);
    }
    // getTeamMembersVaccineDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationInfo, isUpComing]);

  useEffect(() => {
    if(!reservationInfo?.isB2BOrder) getKYCDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationInfo]);

  // const getTeamMembersVaccineDetails = async () => {
  //   const vaccination = await axios.get('/shop/vaccination-status', {
  //     params: {
  //       userDetails: [reservationInfo?.customerEmail],
  //     },
  //     headers: {
  //       Authorization: authState?.accessToken?.accessToken,
  //     },
  //   });
  //   if (
  //     reservationInfo?.customerEmail === vaccination?.data.userData[0].userId
  //   ) {
  //     setVaccinationStatus(vaccination?.data.userData[0].vaccineStatus);
  //   }
  // };

  // const vaccineStatusUpdate = async () => {
  //   if (!vaccinationStatus || vaccinationStatus === null) {
  //     setToastType({
  //       type: 'error',
  //       message: 'Please select the vaccination status before updating.',
  //       show: true,
  //     });
  //     return;
  //   }
  //   try {
  //     const result = [
  //       {
  //         userId: reservationInfo?.customerEmail,
  //         vaccinationStatus: vaccinationStatus,
  //       },
  //     ];
  //     await axios.patch(
  //       '/shop/vaccination-status',
  //       {
  //         userDetails: result,
  //       },
  //       {
  //         headers: {
  //           Authorization: authState?.accessToken?.accessToken,
  //         },
  //       },
  //     );
  //     setToastType({
  //       type: 'success',
  //       message: 'Vaccination Status Updated',
  //       show: true,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const cancelReservation = async () => {
    setDisabled(true);
    try {
      if (!reservationInfo) {
        setDisabled(false);
        setToastType({
          show: true,
          type: 'error',
          message: 'No reservation information available',
        });
        return;
      }
      const { data } = await axios.post(`/cancel/reservations`, null, {
        params: {
          orderId: reservationInfo.orderId,
        },
        headers: {
          Authorization: authState?.accessToken?.accessToken,
        },
      });
      await fetchReservations(-1);
      setToastType({
        show: true,
        type: 'success',
        message: data.message,
      });
      setDisabled(false);
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error.response.data.message || 'Error occured',
      });
      setDisabled(false);
    }
  };


  const getKYCDetails = async () =>{
    setIsLoadingKYCDetails(true);

    const token = authState?.accessToken?.accessToken;
    try{
        const data = await getUserKYCDetails(token as string, reservationInfo?.customerEmail as string);
        setMembersKYCDetails(data);
    }catch(err){
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error?.response?.data?.message,
      });
    }finally{
      setIsLoadingKYCDetails(false);
    }
  }


  if (!reservationInfo) return <p>Nothing available</p>;

  return (
    <Grid container className={classes.root} alignItems="flex-end" spacing={3}>
      <Grid item xs={4}>
        <h5> Order #:</h5>
        <p>
          {reservationInfo.orderNumber ? reservationInfo.orderNumber : '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Email:</h5>
        <p>{`${reservationInfo.customerEmail}`}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Date:</h5>
        <p>
          {reservationInfo.bookingDate
            ? format(new Date(reservationInfo.bookingDate), 'do MMM y')
            : '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Start Time:</h5>
        <p>
          {reservationInfo.bookingDate
            ? format(new Date(reservationInfo.bookingDate), 'hh:mm aaaa')
            : '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Duration (hrs):</h5>
        <p>{reservationInfo.slotLength ? reservationInfo.slotLength : '--'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Member Count:</h5>
        <p>
          {reservationInfo.memberCount ? reservationInfo.memberCount : '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Created On:</h5>
        <p>
          {reservationInfo.createdAt
            ? format(
                new Date(reservationInfo.createdAt),
                'do MMM y, hh:mm aaaa',
              )
            : '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Building:</h5>
        <p>{reservationInfo.buildingName}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Conf. Room:</h5>
        <p>{reservationInfo.name ? reservationInfo.name : '--'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Is B2B Order:</h5>
        <p>{reservationInfo.isB2BOrder ? 'Yes' : 'No'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Invoice:</h5>
        {invoiceResponse.isDiscountedOrder &&
          invoiceResponse.pdfURL === null &&
          'Discount Order'}
        {!invoiceResponse.isDiscountedOrder && invoiceResponse.pdfURL && (
          <Grid item xs={12}>
            <InvoiceWrapper>
              <a
                href={invoiceResponse.pdfURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={pdfIcon} alt="" />
                {/* <span>Type : {invoiceResponse.typeOfInvoice}</span> */}
                <span>Download Now</span>
              </a>
              {invoiceResponse.GSTINValidity !== null && (
                <span>{invoiceResponse.GSTINValidity}</span>
              )}
            </InvoiceWrapper>
          </Grid>
        )}
        {(invoiceResponse.isDiscountedOrder === null ||
          !invoiceResponse.isDiscountedOrder) &&
          invoiceResponse.pdfURL === null &&
          '--'}
      </Grid>
      {invoiceResponse.checkoutUserEmail && (
        <Grid item xs={4}>
          <h5>Checkout Email: </h5>
          <p>{invoiceResponse.checkoutUserEmail}</p>
        </Grid>
      )}
      {!reservationInfo?.isB2BOrder &&  <Grid item xs={4}>
        <h5>KYC</h5>
        {isLoadingKYCDetails ? <Grid>Loading...</Grid>: 
        <Grid
           className={classes.userKyc} >
            <Grid>{memebersKYCDetails && memebersKYCDetails[reservationInfo?.customerEmail] ? memebersKYCDetails[reservationInfo.customerEmail]?.hasCompletedKYC ? 'YES' : 'NO' : 'NA'}</Grid>
            {memebersKYCDetails && memebersKYCDetails[reservationInfo?.customerEmail] && <Grid>
              <LightTooltip
                  title={getKYCTooltipContent(memebersKYCDetails?.[reservationInfo.customerEmail])}
                  placement="top"
                >
               <div>
                  <AiOutlineInfoCircle
                      color="#0000FF"
                      className={classes.infoIcon}
                    />
               </div>
              </LightTooltip>
            </Grid>}
            
            {memebersKYCDetails?.[reservationInfo?.customerEmail]?.isKYCMandate &&  <SendKYCEmail email={reservationInfo.customerEmail} />}
        </Grid>}
                         
      </Grid>}

      <Grid item xs={12} className={classes.addOnBtn}>
        {false && canShowCancelButton && (
          <RayButton
            buttonType="secondary"
            danger
            disabled={disabled}
            onClick={cancelReservation}
          >
            Cancel
          </RayButton>
        )}
        {/* <RayButton buttonType="primary" onClick={vaccineStatusUpdate}>
          Update
        </RayButton> */}
      </Grid>
    </Grid>
  );
}

export default ViewReservationDetails;
