import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';
import axiosCatalog from 'utils/axiosCatalog';

type FetchApiOptions = {
  url: string;
  params?: any;
  method?: 'GET' | 'POST';
};

interface ApiResponse<TData> {
  data: TData;
}

const fetchApiData = async (url: string, token: string, params: any) => {
  const { data } = await axios.get(url, {
    params,
    headers: {
      Authorization: token,
    },
  });
  return data;
};

export const useReactQuery = (
  identifier: (string | number | undefined)[],
  { url, params = {} }: FetchApiOptions,
  options?:
    | UseQueryOptions<any, unknown, any, (string | number | undefined)[]>
    | undefined,
) => {
  const { authState } = useOktaAuth();

  return useQuery(
    identifier,
    () => fetchApiData(url, authState?.accessToken?.accessToken || '', params),
    options,
  );
};

const fetchApiDataNew = async (
  url: string,
  token: string,
  params: any = {},
  method: 'GET' | 'POST' = 'GET', // default to GET
) => {
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    ...(method === 'POST' && { data: params }), // Include params in request body for POST
  };

  let response;
  if (method === 'GET') {
    response = await axiosCatalog.get(url, { params, ...config });
  } else if (method === 'POST') {
    response = await axiosCatalog.post(
      url,
      JSON.stringify(config.data),
      config,
    );
  }

  return response.data;
};

export const useReactQueryNew = <TData = any,>(
  identifier: (string | number | undefined)[],
  { url, params = {}, method }: FetchApiOptions,
  options?: UseQueryOptions<
    ApiResponse<TData>,
    unknown,
    ApiResponse<TData>,
    (string | number | undefined)[]
  >,
): UseQueryResult<ApiResponse<TData>> => {
  const { authState } = useOktaAuth();

  return useQuery<
    ApiResponse<TData>,
    unknown,
    ApiResponse<TData>,
    (string | number | undefined)[]
  >(
    identifier,
    () =>
      fetchApiDataNew(
        url,
        `Bearer ${authState?.accessToken?.accessToken}` || '',
        params,
        method,
      ),
    options,
  );
};
