import React  from 'react';
import styled from 'styled-components';
import RayButton from '../../components/RayButton';
import { useHistory } from 'react-router-dom';

const UnauthorizedPage = () => {
  const { push } = useHistory();
  return (
    <UnauthorizedPageWrapper>
      <div>
      Please reach out to the community team to initiate the onboarding process
      </div>
      <div>
        <RayButton
          onClick={() => {
            push('/');
            window.location.reload();
          }}
        >
          Try again
        </RayButton>
      </div>
    </UnauthorizedPageWrapper>
  );
};

export default UnauthorizedPage;

const UnauthorizedPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;

  div {
    margin-bottom: 20px; /* Add some space between the text and the button */
  }
`;
