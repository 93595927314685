import { CircularProgress, Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import styled from 'styled-components';
import InvoiceSection from './Invoice/InvoiceSection';

const LoaderWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  orderId: String;
  order_id: String;
  buildingName: String;
  isCancelledOrder: boolean;
};

function ViewPurchasesDetails({
  orderId,
  order_id,
  buildingName,
  isCancelledOrder,
}: Props) {
  const { isLoading, error, data } = useReactQuery([`${orderId}`], {
    url: '/shop/fetch-purchase-by-id',
    params: {
      docId: orderId,
      isPartner: order_id && order_id !== '--' ? false : true,
    },
  });

  const purchaseData = useMemo(() => {
    if (data) return data.purchaseData;
    return {};
  }, [data]);

  const productName = useMemo(() => {
    let productName = '--';
    if (Array.isArray(purchaseData?.productInformation)) {
      productName = purchaseData.productInformation
        .map((product: any) =>
          product.title !== 'Others'
            ? product.title
            : 'Others ( ' + product.eventTypeOthersDetails + ' )' ||
              product.type,
        )
        .join(', ');
      if (purchaseData.metadata?.eventDraftOrderId)
        productName += ' (Event Space)';
    } else if (purchaseData.productInformation?.title) {
      productName = purchaseData.productInformation?.title;
    } else if (purchaseData.metadata?.handle) {
      productName = purchaseData.metadata?.handle;
      if (purchaseData?.shopify_data?.variant_title) {
        productName += ' (' + purchaseData.shopify_data?.variant_title + ')';
      }
    } else if (purchaseData?.shopify_data?.title) {
      productName = purchaseData.shopify_data?.title;
    }
    return productName;
  }, [purchaseData]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress size={30} color="inherit" />
      </LoaderWrapper>
    );
  }
  if (error)
    return (
      <LoaderWrapper>
        <p>Error fetching records</p>
      </LoaderWrapper>
    );
  if (Object.keys(purchaseData).length === 0)
    return (
      <LoaderWrapper>
        <p>Invalid data recieved</p>
      </LoaderWrapper>
    );

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <h5>Order Number:</h5>
        <p>
          {purchaseData?.shopify_data?.order_number ||
            purchaseData?.orderNumber ||
            '--'}
        </p>
      </Grid>
      {purchaseData?.draft_order_id && (
        <Grid item xs={4}>
          <h5>Shopify draft Order Id:</h5>
          <p>{purchaseData?.draft_order_id || '--'}</p>
        </Grid>
      )}
      {purchaseData?.dashBoardOrderType === 'postpaid-event' && (
        <Grid item xs={4}>
          <h5>Invoice Number:</h5>
          <p>{purchaseData?.postpaid_invoice_number || '--'}</p>
        </Grid>
      )}
      <Grid item xs={4}>
        <h5>Building Name:</h5>
        <p>{buildingName}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Customer Email:</h5>
        <p>{purchaseData.customer_email || '--'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Product Name:</h5>
        <p>{productName}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Quantity:</h5>
        <p>
          {/* The paymentMode is mainly for event space offline orders. */}
          {Array.isArray(purchaseData?.productInformation) &&
          purchaseData.metadata?.paymentMode !== 'offline'
            ? purchaseData.productInformation.reduce(
                (sum: number, product: any) => (sum += product.quantity || 1),
                0,
              )
            : purchaseData?.productInformation?.quantity ||
              purchaseData?.shopify_data?.quantity ||
              purchaseData?.quantity ||
              '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Order Price(₹):</h5>
        {/* The paymentMode is mainly for event space offline orders. */}
        <p>
          {purchaseData?.totalPrice ||
            purchaseData?.shopify_data?.parent_order_total_price +
              (purchaseData.metadata?.paymentMode ?? purchaseData.paymentMode
                ? ' (' +
                  (
                    purchaseData.metadata?.paymentMode ??
                    purchaseData.paymentMode
                  )
                    .charAt(0)
                    .toUpperCase() +
                  (
                    purchaseData.metadata?.paymentMode ??
                    purchaseData.paymentMode
                  ).slice(1) +
                  ')'
                : '') ||
            '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Order Status:</h5>
        <p>{purchaseData.status || '--'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Created At:</h5>
        <p>
          {purchaseData?.createdOn
            ? new Date(purchaseData?.createdOn).toLocaleString(undefined, {
                timeZone: 'Asia/Kolkata',
              })
            : '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>GST:</h5>
        <p>{purchaseData.gstin || purchaseData.customer_gstin || '--'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Notes:</h5>
        <p>
          {typeof purchaseData.notes === 'string'
            ? purchaseData.notes || '--'
            : '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Order Creator</h5>
        <p>{purchaseData.order_creator || '--'}</p>
      </Grid>
      <InvoiceSection
        purchaseData={purchaseData}
        isCancelledOrder={isCancelledOrder}
      />
    </Grid>
  );
}

export default ViewPurchasesDetails;
