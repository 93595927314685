import styled from 'styled-components';

type Props = {
  text?: string;
};

const FormHelperText = ({ text }: Props) => {
  return <TextWrapper className={'helper-text'}>{text}</TextWrapper>;
};

export default FormHelperText;

const TextWrapper = styled.div`
  margin-top: 2px;
  font-size: 0.75rem;
`;
