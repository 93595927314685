export interface PurchaseDetails {
  building_name: string;
  checkout_url: string;
  created_at: string;
  customer_email: string;
  draft_order_id: number;
  id: string;
  product_name: string;
  quantity: number;
  status: string;
  total_order_price: number;
  gstin: string;
  notes: string;
  order_number: number;
}

export interface DropdownTypes {
  label: 'PENDING' | 'COMPLETED' | 'CANCELLED' | 'ALL';
  value: 'PENDING' | 'COMPLETED' | 'CANCELLED' | 'ALL';
}

export interface RefundDetails {
  amount: number | null;
  reason: { label: string; value: string } | null;
  note?: string;
}

export interface TableProps {
  cell: any;
  index: number;
  reloadPurchases: () => void;
}

export const refundReasonsMap = {
  'Customer Requested': 'CUSTOMER_REQUESTED',
  'Inventory Unavailable': 'INVENTORY_UNAVAILABLE',
  'Late Payment': 'LATE_PAYMENT',
  'Tax Return Request': 'TAX_RETURN_REQUEST',
  'Booking Error': 'BOOKING_ERROR',
  'Duplicate Booking': 'DUPLICATE_BOOKING',
  'Pricing Error': 'PRICING_ERROR',
  'Promotion Applied Incorrectly': 'PROMOTION_APPLIED_INCORRECTLY',
  'Event Canceled': 'EVENT_CANCELED',
  'Credits Expiration Issue': 'CREDITS_EXPIRATION_ISSUE',
  'Booking Not Fulfilled': 'BOOKING_NOT_FULFILLED',
  Other: 'OTHER',
};

export enum REFUND_ELIGIBLE_ORDER_STATUSES {
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
}
