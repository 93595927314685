import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import axios from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';
import pdfIcon from '../../../assets/pdf_icon.png';
import styled from 'styled-components';
import { InvoiceDataOMS } from 'containers/Bookings/TableContainer';

enum InvoiceType {
  RECEIPT = 'Receipt',
  SECURITY_DEPOSIT = 'Security Deposit Invoice',
  TAX_INVOICE = 'Invoice',
  CREDIT_NOTE = 'Credit Note',
}

const InvoiceOMS = ({ order_id }) => {
  const { authState } = useOktaAuth();
  const [invoiceResponse, setInvoiceResponse] = useState<
    InvoiceDataOMS[] | null
  >(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('/api/v1/admin/invoices', {
          params: { order_uid: order_id },
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
          baseURL: process.env.REACT_APP_CHECKOUT_BASE_URL,
        });
        setInvoiceResponse(data?.data as InvoiceDataOMS[]);
      } catch (error) {
        console.error('Error fetching OMS Invoice:', error);
        setInvoiceResponse(null);
      }
    })();
  }, [order_id, authState]);

  return (
    <>
      {invoiceResponse &&
        invoiceResponse.map(invoice =>
          invoice.presigned_url ? (
            <Grid item xs={4}>
              <h5>{InvoiceType[invoice.invoice_type] || 'Invoice'}:</h5>
              <InvoiceWrapper>
                <a
                  href={invoice.presigned_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={pdfIcon} alt="" />
                  <span>Download Now</span>
                </a>
              </InvoiceWrapper>
            </Grid>
          ) : (
            'No PDF available'
          ),
        )}
    </>
  );
};

export default InvoiceOMS;

const InvoiceWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    text-decoration: underline;
  }
`;
