import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormDatePickerField, OnChangeFunction } from './types';
import FormError from './FormError';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { shouldDisableDate } from 'utils/functions/shouldDisableDate';
import { Grid } from '@material-ui/core';
import FormHelperText from './FormHelperText';
import moment from 'moment';

const FormDatePicker = ({
  label,
  name,
  disabled = false,
  required = false,
  ...rest
}: FormDatePickerField) => {
  const { control, watch, clearErrors } = useFormContext();
  const {
    fieldValidations,
    helperText,
    shouldDisableWeekends,
    targetFieldName,
  } = rest;
  const [open, setOpen] = useState(false);

  // handle change in validation based on dependent fields
  const value = targetFieldName ? watch(targetFieldName) : undefined;

  useEffect(() => {
    if (targetFieldName && value !== undefined) {
      clearErrors(name);
    }
  }, [targetFieldName, value, clearErrors, name]);

  const handleChange = (onChange: OnChangeFunction) => (
    date: MaterialUiPickersDate,
  ) => {
    if (!date) return onChange(null);
    if (date && moment(date).toDate()) onChange(moment(date).toDate());
    if (open) setOpen(false);
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: !disabled && targetFieldName ? !value : required,
          message: 'This field is required',
        },
        validate: (value: any) => {
          if (targetFieldName && !value) return true;
          if (!required) return true;
          return (
            new Date().setHours(0, 0, 0, 0) <=
              new Date(value).setHours(0, 0, 0, 0) ||
            'Past date cannot be selected'
          );
        },
        ...fieldValidations,
      }}
      defaultValue={null}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              className="rhf-datepicker__input"
              label={label}
              error={Boolean(error)}
              inputVariant="outlined"
              style={{ width: '100%' }}
              disableToolbar
              variant="inline"
              format="Do MMMM YYYY"
              onChange={handleChange(onChange)}
              shouldDisableDate={date =>
                shouldDisableDate(date, shouldDisableWeekends)
              }
              disablePast
              readOnly={disabled}
              disabled={disabled}
              {...rest}
            />
          </MuiPickersUtilsProvider>
          <FormHelperText text={helperText} />
          <FormError error={error} />
        </Grid>
      )}
    />
  );
};

export default FormDatePicker;
