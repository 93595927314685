import Grid from '@material-ui/core/Grid';
import ModalDialog from 'components/ModalDialog';
import RayButton from 'components/RayButton';
import { BuildingOption } from 'containers/Bookings/types';
import { VirtualOfficeDocument } from 'containers/VirtualOfficePage/types';
import React from 'react';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import { UpdateVODetailsMutation } from './ApiCalls';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { useOktaAuth } from '@okta/okta-react';

type ChangeBuildingProps = {
  isOpen: boolean;
  setChangeBuildingState: React.Dispatch<boolean>;
  documentData: VirtualOfficeDocument;
};

export const ChangeBuildingModal = ({
  documentData,
  isOpen,
  setChangeBuildingState,
}: ChangeBuildingProps) => {
  const [buildingsData, buildingsLoader, buildingsError] = useFetchRequest(
    '/api/v1/admin/buildings/byVoPlans',
    {
      planType: documentData?.selectedPlan?.name,
      buildingId: Number(documentData?.building?.id),
    },
  );
  const [selectedBuilding, updateSelectedBuilding] = React.useState<BuildingOption | null>(
    documentData.building || null,
  );
  const { setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();

  const { isLoading, mutateAsync } = UpdateVODetailsMutation({});

  const handleChangeBuildingSubmit = () => {
    mutateAsync({
      authToken: authState?.accessToken?.accessToken || '',
      documentId: documentData.id,
      payload: {
        fieldToBeUpdated: 'building',
        newBuildingId: selectedBuilding?.id?.toString() || '',
      },
    }).then(data => {
      setToastType({
        show: true,
        type: 'success',
        message: 'Successfully Updated the building',
      });
      setChangeBuildingState(false);
    });
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      showModal={() => setChangeBuildingState(false)}
      fullWidth
      maxWidth="sm"
      title={'Change Building'}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectInput
            key="building"
            options={
              buildingsData?.length > 0
                ? buildingsData?.filter(
                    ({ disabled }: BuildingOption) => !disabled,
                  )
                : []
            }
            getOptionLabel={option => option.name}
            disabled={buildingsLoader || buildingsError || !documentData?.selectedPlan?.name}
            label="Select Building"
            name="select-building"
            value={selectedBuilding}
            size="medium"
            onChange={(
              event: React.ChangeEvent<{}>,
              value: BuildingOption | null,
            ) => updateSelectedBuilding(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <RayButton
              buttonType="secondary"
              onClick={() => setChangeBuildingState(false)}
            >
              Cancel
            </RayButton>
            <RayButton
              buttonType="primary"
              onClick={handleChangeBuildingSubmit}
              loading={isLoading}
            >
              Submit
            </RayButton>
          </Grid>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};
