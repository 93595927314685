import React from "react";

type GridProps = {
  container?: boolean;
  children?: React.ReactNode;
};

const RayRow = ({ container = false, children }: GridProps) =>
  container ? (
    <main className="ray-page-container">
      <div className="ray-grid">{children}</div>
    </main>
  ) : (
    <div className="ray-grid">{children}</div>
  );

export default RayRow;
