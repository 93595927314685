import React from 'react';
import { Helmet } from 'react-helmet';

import './App.css';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { UserProvider } from './utils/context';

import Bookings from './containers/Bookings';
import NotFoundPage from './containers/NotFoundPage';
import UserManagement from './containers/UserManagement';
// import ForgotPasswordPage from './containers/ForgotPasswordPage';
import EditProfilePage from './containers/EditProfilePage';
import ShopPage from 'containers/ShopPage';
import PurchasesPage from 'containers/PurchasesPage';
import CustomersPage from 'containers/CustomersPage';
import ReservationsPage from 'containers/ReservationsPage';
import VirtualOfficePage from 'containers/VirtualOfficePage';
import VirtualOfficeDetailPage from 'containers/VirtualOfficeDetailPage';
import ShopifyOrdersPage from 'containers/ShopifyOrdersPage';

import ProtectedRoute from './components/ProtectedRoute';
import ToastAlert from './components/ToastAlert';
import BuildingsPage from 'containers/BuildingsPage';
import BuildingDetailsPage from 'containers/BuildingDetailsPage';
import POInventory from 'containers/POInventory';
import EnterpriseOnboarding from 'containers/EnterpriseOnboarding';
import ConferenceRoomsPage from 'containers/ConferenceRoomsPage';
import LegalUsers from 'containers/LegalUsers';
import { VOProvider } from 'utils/VOContext';
import LMSPage from 'containers/LMS';
import DayPassBundlesPage from 'containers/DayPassBundlesPage';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';
import LoginPage from 'containers/LoginPage';
import UnauthorizedPage from 'containers/UnauthorizedPage';
import AuditHistory from 'containers/AuditHistory';
import ShopProductDetails from 'containers/ProductPage';
import InventoryPage from 'containers/InventoryPage';

const oktaAuth = new OktaAuth({
  issuer: 'https://weworkindia.okta.com/oauth2/default',
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  const onAuthResume = async () => {
    history.push('/login');
  };

  return (
    <>
      <Helmet
        titleTemplate="%s | WeWork On Demand Dashboard"
        defaultTitle="WeWork On Demand"
      >
        {process.env.REACT_APP_ENABLE_NEWRELIC === 'true' && (
          <script
            src="https://storage.googleapis.com/platform_pdfs/newrelic.js"
            type="text/javascript"
          />
        )}
        <meta name="description" content="WeWork On Demand Dashboard" />
      </Helmet>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <UserProvider>
          <VOProvider>
            <Switch>
              <Route
                path="/login/callback"
                render={props => (
                  <LoginCallback {...props} onAuthResume={onAuthResume} />
                )}
              />
              <Route path="/login" component={LoginPage} />
              {/* <Route path="/forgot-password" component={ForgotPasswordPage} /> */}
              <Route path="/unauthorized" component={UnauthorizedPage} />
              <ProtectedRoute
                exact
                path="/day-pass-bookings"
                component={Bookings}
              />
              <ProtectedRoute
                exact
                path="/my-profile"
                component={EditProfilePage}
              />
              <ProtectedRoute exact path="/shop" component={ShopPage} />
              <ProtectedRoute
                exact
                path="/shop-purchases"
                component={PurchasesPage}
              />
              <ProtectedRoute exact path="/users" component={CustomersPage} />
              <ProtectedRoute
                exact
                path="/vo-bookings"
                component={VirtualOfficePage}
              />
              <ProtectedRoute
                exact
                path="/vo-bookings/:docId"
                component={VirtualOfficeDetailPage}
              />
              <ProtectedRoute
                exact
                path="/online-conference-room-bookings"
                component={ReservationsPage}
              />
              <ProtectedRoute
                exact
                path="/shopify-orders"
                component={ShopifyOrdersPage}
              />
              <ProtectedRoute
                exact
                path="/shop/:productId"
                component={ShopProductDetails}
              />
              <ProtectedRoute
                exact
                path="/admin-users"
                component={UserManagement}
              />
              <ProtectedRoute
                exact
                path="/buildings"
                component={BuildingsPage}
              />
              <ProtectedRoute
                exact
                path="/buildings/:id"
                component={BuildingDetailsPage}
              />
              <ProtectedRoute
                exact
                path="/po-inventory"
                component={POInventory}
              />
              <ProtectedRoute exact path="/lms" component={LMSPage} />
              <ProtectedRoute
                exact
                path="/enterprise-onboarding"
                component={EnterpriseOnboarding}
              />
              <ProtectedRoute
                exact
                path="/conference-rooms"
                component={ConferenceRoomsPage}
              />
              <ProtectedRoute
                exact
                path="/inventory"
                component={InventoryPage}
              />
              <ProtectedRoute
                exact
                path="/day-pass-bundles"
                component={DayPassBundlesPage}
              />
              <ProtectedRoute
                exact
                path="/legal-users"
                component={LegalUsers}
              />
              <ProtectedRoute
                exact
                path="/audit-history"
                component={AuditHistory}
              />
              <Redirect exact from="/" to="day-pass-bookings" />
              <Route component={NotFoundPage} />
            </Switch>
            <ToastAlert />
          </VOProvider>
        </UserProvider>
      </Security>
    </>
  );
}

export default App;
