import React, { createContext, useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { fetchUserProfileInfo } from 'utils/functions/fetchUserProfile';
import { useHistory } from 'react-router-dom';
import { fetchFeatureFlagConfig } from 'utils/functions/fetchFeatureFlagConfig';
import { fetchUserBuildings } from 'utils/functions/fetchUserBuildings';

type Props = {
  children: React.ReactNode;
};

type ContextStructure = {
  userInfo: any;
  tokenLoad: boolean;
  setUserInfo: (value: any) => void;
  // retrieveToken?: () => Promise<string | null>;
  toastType: any;
  setToastType: ({ show, type, message }: ToastStructure) => void;
  useAlgolia: boolean;
};

type ToastStructure = {
  show: boolean;
  type: 'error' | 'success' | 'warning' | 'info' | undefined;
  message: string;
};

const UserContext = createContext<ContextStructure | undefined>(undefined);

export const UserProvider = ({ children }: Props) => {
  const { authState } = useOktaAuth();
  const { push } = useHistory();
  const [userInformation, setUserInformation] = useState<any>('');
  const [tokenLoader, setTokenLoader] = useState(true);
  const [toastType, setToastType] = useState<ToastStructure>({
    show: false,
    type: undefined,
    message: '',
  });
  const [useAlgolia, setUseAlgolia] = useState(false);

  const fetchData = async () => {
    setTokenLoader(true);
    if (authState?.isAuthenticated && authState?.accessToken?.accessToken) {
      try {
        const userProfileInfo = await fetchUserProfileInfo(
          authState?.accessToken?.accessToken,
        );
        const featureFlagConfig = await fetchFeatureFlagConfig(
          authState?.accessToken?.accessToken,
        );
        const userBuildings = await fetchUserBuildings(
          authState?.accessToken?.accessToken,
        );
        if (userProfileInfo.status === 401) {
          setUserInformation(null);
          push('/unauthorized');
        }
        setUseAlgolia(featureFlagConfig?.data[0]?.configValue?.useAlgolia);
        setUserInformation({ ...userProfileInfo, id: userProfileInfo.email, accessibleBuildings: userBuildings });
      } catch (error) {
        setUserInformation(null);
        push('/unauthorized');
      }
    } else {
      setUserInformation(null);
    }
    setTokenLoader(false);
  };

  useEffect(() => {
    if (authState) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        userInfo: userInformation,
        tokenLoad: tokenLoader,
        setUserInfo: setUserInformation,
        toastType: toastType,
        setToastType: setToastType,
        useAlgolia: useAlgolia,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;

export default UserContext;
