import { VirtualOfficeDocument } from 'containers/VirtualOfficePage/types';
import axios, { isCustomAxiosError } from 'utils/axios';

export const attachments: AttachmentItemType[] = [
  {
    type: 'PDF',
    label: 'Membership Agreement',
    value: 'MSA',
    parentFolder: 'virtual-offices',
    plans: [
      'business-address-plan',
      'gst-registration-plan',
      'business-registration-plan',
    ],
    disabledFor: [],
  },
  {
    type: 'PDF',
    label: 'WeWork NOC',
    value: 'NOC',
    parentFolder: 'virtual-offices',
    plans: [
      'existing',
      'gst-registration-plan',
      'business-registration-plan',
      'business-address-plan',
    ],
    disabledFor: [],
  },
];

export const slugify = (string: string) =>
  string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export type AttachmentItemType = {
  type: string;
  label: string;
  value: string;
  parentFolder: string;
  plans: string[];
  disabledFor: string[];
};

function decrementSubscription(str: string) {
  // Use a regular expression to find the number at the end of the string
  const match = str.match(/(\d+)$/);
  if (match) {
    // Extract the number from the match
    let number = parseInt(match[1]);
    if (number > 1) {
      number -= 1;
    }
    // Replace the original number in the string with the decremented number
    return str.replace(/(\d+)$/, number.toString());
  }
  // If no number is found, return the original string
  return str;
}

export const getFileName = (
  value: string,
  parentFolder: string,
  type: string,
  virtualOfficeDocument: VirtualOfficeDocument | Record<string, any>,
) => {
  const appendExtension = (fileName: string, type: string) => {
    return `${fileName}.${type.toLowerCase()}`;
  };
  const prependDocId = (fileName: string, id: string) => {
    return `${id}/${fileName}`;
  };
  // Function to get fileName
  const getName = (): string => {
    const {
      latestSubscriptionId,
      building,
      unIncorporatedRegistration,
      leegalityMembershipResponse,
      leegalityNOCResponse,
      status,
    } = virtualOfficeDocument;
    let subscriptionId = latestSubscriptionId;
    // Old user now also has this property coming in so added the logic
    const isOldUser = !('leegalityNOCResponse' in virtualOfficeDocument);

    if (parentFolder !== 'virtual-offices') return `${building.id}/${value}`;

    // If the user is currently in the 'SIGN_MEMBERSHIP_DOC' or 'GENERATED_PAYMENT_LINK_FOR_PLAN' states,
    // it indicates an ongoing upgrade or renewal process. (unless the user is buying a new VO where he will not have the docs)
    // In these states, we need to access the previous subscription PDF, which is determined by decrementing the latest subscription ID.
    if (
      status === 'SIGN_MEMBERSHIP_DOC' ||
      status === 'GENERATED_PAYMENT_LINK_FOR_PLAN'
    )
      subscriptionId = decrementSubscription(latestSubscriptionId);

    if (value === 'NOC') {
      // Old flow
      if (isOldUser) {
        if (unIncorporatedRegistration)
          return prependDocId('NOC_unincorporated', virtualOfficeDocument.id);
        return prependDocId(
          subscriptionId === 'subscription1' ? 'NOC' : `NOC_${subscriptionId}`,
          virtualOfficeDocument.id,
        );
      }
      // New Flow Leegality
      return (
        leegalityNOCResponse?.fileUrl ||
        prependDocId(`NOC_${subscriptionId}`, virtualOfficeDocument.id)
      );
      // return `NOC_${latestSubscriptionId}`;
    } else {
      // New Flow Leegality membership agreement
      return (
        leegalityMembershipResponse?.inviteeInfo?.fileURL ||
        prependDocId(
          `Membership_Agreement_${subscriptionId}`,
          virtualOfficeDocument.id,
        )
      );
    }
  };
  const file = getName();
  if (file.startsWith('http')) return file;
  return appendExtension(getName(), type);
};

async function fetchFileURL(folder: string, fileRef: string, token: string) {
  try {
    const response = await axios.get(`/storage/admin/get`, {
      params: {
        folder: `virtual-office-documents/${folder}`,
        fileRef,
      },
      headers: { Authorization: `${token}` },
    });
    return {
      valid: true,
      fileUrl: response.data.url,
    };
  } catch (error) {
    if (isCustomAxiosError(error)) {
      console.error(error);
      return {
        valid: false,
        message:
          error?.response?.data?.message ||
          'Error occurred while fetching the document',
      };
    }
  }
}

export default fetchFileURL;
