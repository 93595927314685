import React from 'react';
import styled from 'styled-components';
import Dialog from '../DialogModal';
import RayButton from '../RayButton';

const DialogWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;
const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  isOpen: boolean;
  showModal: (x: boolean) => void;
  deleteFunction: (x: any) => void;
  deleteItemId: any;
};

function DeleteDialog({
  isOpen,
  showModal,
  deleteFunction,
  deleteItemId,
}: Props) {
  return (
    <Dialog center isOpen={isOpen} showModal={showModal}>
      <DialogWrapper>
        <h2>Delete User</h2>
        <p>Are you sure you want to delete this user?</p>
        <ButtonRow>
          <RayButton
            buttonType="secondary"
            compact
            onClick={() => showModal(false)}
          >
            Cancel
          </RayButton>
          <RayButton
            danger
            compact
            onClick={
              deleteItemId ? () => deleteFunction(deleteItemId) : () => null
            }
          >
            Delete
          </RayButton>
        </ButtonRow>
      </DialogWrapper>
    </Dialog>
  );
}

export default DeleteDialog;
