import { Grid } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import RenderFormItem from './RenderFormItem';
import { FormConfig } from './types';
import OrderSummaryCard from 'containers/ProductPage/OrderSummaryCard';

export interface FormWrapperProps {
  onSubmit: (data: any, setError: any) => void;
  config: FormConfig[];
  defaultValues: {
    [key: string]: any;
  };
}

const FormWrapper = ({ onSubmit, config, defaultValues }: FormWrapperProps) => {
  const form = useForm({
    defaultValues: { ...defaultValues },
  });

  const { setFocus } = form;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(
          data => onSubmit(data, form.setError),
          errors => {
            const firstErrorKey = Object.keys(errors)[0] as string;
            const errorElement = document.querySelector(
              `[name="${firstErrorKey}"]`,
            );
            if (errorElement) {
              errorElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
              setFocus(firstErrorKey);
            }
          },
        )}
        className="w-full space-y-8 p-1"
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            {config?.map(fieldItemConfig => {
              return (
                <div key={fieldItemConfig.name}>
                  <RenderFormItem {...fieldItemConfig} />
                </div>
              );
            })}
          </Grid>
          <Grid item xs={4}>
            <OrderSummaryCard />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default FormWrapper;
