import { Grid } from '@material-ui/core';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import { H1 } from 'components/Typography/Heading';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import InventoryTable from './InventoryTable';
import CreateInventory from './CreateInventory';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { useReactQueryNew } from 'utils/hooks/useReactQuery';
import { handleAxiosError } from 'utils/errorHelper';
import RangePicker, { initialTimePickerStateType } from './RangePicker';

export type Building = {
  id: string;
  name: string;
  disabled?: boolean;
  micromarket?: string;
  city?: string;
  region?: string;
};

export type Product = {
  category_id: string;
  category_name: string;
  product_uid: string;
  product_name: string;
  product_description: string;
  building_id: string;
  variants: any;
  meta_info: any;
};

type ProductsResponseData = {
  records: Product[];
};

type ProductMetaInfo = {
  capacity: number;
};

type ProductPricing = {
  selling_price: string;
};

type ProductResponseData = {
  product: Product;
  product_name: string;
  pricing: ProductPricing[];
  meta_info: ProductMetaInfo;
};

const InventoryPage = () => {
  const { userInfo } = useAuthContext()!;
  const [selectedBuilding, setSelectedBuilding] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<
    initialTimePickerStateType
  >([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#0000ff',
      showDateDisplay: true,
      autoFocus: true,
    },
  ]);
  const { setToastType } = useAuthContext()!;

  useEffect(() => {
    if (userInfo?.accessibleBuildings?.length > 0) {
      const building = userInfo?.accessibleBuildings[0];
      setSelectedBuilding({
        label: building?.name,
        value: building?.id,
      });
    }
  }, [userInfo?.accessibleBuildings]);

  const {
    data: productsData,
    isError: productsError,
    isLoading: productsLoading,
  } = useReactQueryNew<ProductsResponseData>(
    ['admin', 'products', selectedBuilding?.value],
    {
      url: `/api/v1/admin/products/search`,
      params: {
        size: 10,
        direction: 'next',
        cursor: null,
        //the search_query is hardcoded to get only day pass product here since inventory is only for day pass for now.
        search_query: 'day pass',
        must_match: {
          fb_building_id: selectedBuilding?.value,
        },
      },
      method: 'POST',
    },
    {
      enabled: !!selectedBuilding?.value,
      onError: (error: any) => {
        const errorMessage = handleAxiosError(
          error,
          'Something went wrong when fetching products',
        );
        setToastType({
          show: true,
          message: errorMessage,
          type: 'error',
        });
        return;
      },
    },
  );

  const selectedProduct = productsData?.data?.records[0];

  // api to get complete product details since we are not getting complete details from the previous api.
  const { data: productData, isLoading: productLoading } = useReactQueryNew<
    ProductResponseData
  >(
    ['admin', 'product', selectedBuilding?.value],
    {
      url: `api/v1/admin/products/${selectedProduct?.product_uid}`,
    },
    {
      enabled: !!selectedProduct?.product_uid,
      onError: (error: any) => {
        const errorMessage = handleAxiosError(
          error,
          'Something went wrong when fetching product details',
        );
        setToastType({
          show: true,
          message: errorMessage,
          type: 'error',
        });
        return;
      },
    },
  );

  const handleBuildingChange = (selectedOption: any) => {
    setSelectedBuilding(selectedOption);
  };

  return (
    <Wrapper xs={12} container>
      <Grid item xs={12}>
        <H1>On-Demand Inventory</H1>
      </Grid>
      <Grid container justifyContent="space-between" item xs={12}>
        {/* product details section */}
        <Grid item xs={6}>
          {productData?.data && selectedProduct && (
            <Grid item>
              <Grid container>
                <Grid item container>
                  <p className="bold">{productData?.data?.product_name}</p>
                </Grid>
                <Grid item container justifyContent="space-between">
                  <Grid item container className="gap-1">
                    <p>Base Price:</p>
                    <p>₹{productData?.data?.pricing[0]?.selling_price}</p>
                  </Grid>
                  <Grid item container className="gap-1 ">
                    <p>Base Capacity:</p>
                    <p>{productData?.data?.meta_info?.capacity}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={3}>
          {/* building selection section */}
          <SelectInput
            options={
              userInfo?.accessibleBuildings?.length > 0
                ? userInfo?.accessibleBuildings
                    .filter(({ disabled }: Building) => !disabled)
                    .map((building: Building) => ({
                      label: building.name,
                      value: building.id,
                    }))
                : []
            }
            value={selectedBuilding}
            disableClearable
            size="medium"
            getOptionLabel={option => option.label}
            label={'Building'}
            onChange={(_, selectedOption) => {
              handleBuildingChange(selectedOption);
            }}
          />
        </Grid>
      </Grid>
      {/* Display "Inventory unavailable" if no products or an error occurs */}
      {(!productsData?.data || !selectedProduct || productsError) &&
        !productsLoading &&
        !productLoading && (
          <Grid container justifyContent="center" alignItems="center" xs={12}>
            <p className="error">
              Inventory unavailable for the selected building
            </p>
          </Grid>
        )}

      {selectedProduct && selectedBuilding && (
        <Grid
          container
          justifyContent="space-between"
          xs={12}
          className="filters-section"
        >
          {/* date range picker and create inventory section */}
          <Grid container item xs={4}>
            <RangePicker
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
            />
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <CreateInventory id={selectedProduct.product_uid} />
          </Grid>
        </Grid>
      )}

      {selectedBuilding && selectedProduct && (
        <InventoryTable
          selectedBuilding={selectedBuilding}
          selectedProduct={selectedProduct}
          selectedDateRange={selectedDateRange}
        />
      )}
    </Wrapper>
  );
};

export default InventoryPage;

const Wrapper = styled(Grid)`
  .gap-2 {
    gap: 1rem;
  }
  .bold {
    font-weight: 600;
  }
  .center {
    text-align: center;
  }
  .error {
    color: red;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
  }
  td,
  th {
    vertical-align: middle; /* Center vertically */
  }
  .expiry-td {
    text-align: center;
  }
  .centered-input {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .justify-end {
    justify-self: flex-end;
  }
  .filters-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
