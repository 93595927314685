/* eslint-disable eqeqeq */
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import RayInput from 'components/RayInput';
import RayButton from 'components/RayButton';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import axios from 'utils/axios';
import RaySwitch from 'components/RaySwitch';
import { numberInputRegex } from 'utils/constants';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import { useOktaAuth } from '@okta/okta-react';

export interface RoomForm {
  name: string;
  building_name: string;
  capacity: number;
  covid_capacity: number;
  room_disabled: boolean;
  creditsPerHour: number;
  id: string;
}

type Props = {
  toggleModal: React.Dispatch<boolean>;
  selectedValue: RoomForm | null;
  updateRoomsData: any;
};

function EditConferenceRoomForm({ selectedValue, toggleModal, updateRoomsData }: Props) {
  const { setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();

  const [credits] = useFetchRequest('/shop/get-conference-room-credits')

  const [creditsInput, setCreditsInput] = useState<number>(selectedValue?.creditsPerHour || 0)
  
  const[loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    setLoading(true)
    try {
        const body = {
            id: selectedValue?.id,
            updateBody: {
                creditsPerHour: creditsInput
            }
        }
        const token = authState?.accessToken?.accessToken;
        await axios.patch(`/shop/update-conference-room-details`, body, {
            headers: {
                Authorization: token
            }
        });
        setToastType({
            show: true,
            type: 'success',
            message: 'Successfully updated the conference room'
        })
        updateRoomsData()
        setLoading(false)
        toggleModal(false);
    }catch(error) {
        console.log(error)
        setToastType({
            show: true,
            type: 'error',
            message: 'Error occured while updating the conference room'
        })
        setLoading(false)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RayInput
          label="Name"
          fullWidth
          disabled
          name="name"
          value={selectedValue ? selectedValue.name : ''}
        />
      </Grid>
      <Grid item xs={6}>
        <RayInput
          label="Building Name"
          fullWidth
          disabled
          name="building_name"
          value={selectedValue ? selectedValue.building_name : ''}
        />
      </Grid>
      <Grid item xs={6}>
        <RayInput
          label="Capacity"
          fullWidth
          disabled
          name="capacity"
          value={selectedValue ? selectedValue.capacity : ''}
        />
      </Grid>
      <Grid item xs={6}>
        <RayInput
          label="Covid Capacity"
          fullWidth
          disabled
          name="covid_capacity"
          value={selectedValue ? selectedValue.covid_capacity : ''}
        />
      </Grid>
      <Grid item xs={6}>
        <RayInput
          label="Credits Per Hour"
          fullWidth
          name="creditsPerHour"
          value={creditsInput}
          onChange={(e:any) => setCreditsInput(parseInt(e.target.value.replace(numberInputRegex, ""))||0)}
        />
      </Grid>
      <Grid item xs={6}>
        <RayInput
          label="Price"
          fullWidth
          name="price"
          disabled
          value={ (creditsInput||0) * (credits?.credits || 50) }
        />
      </Grid>
      <Grid item xs={12}>
        1 Credit per hour denotes INR {(credits?.credits || 50)}
      </Grid>
      <Grid item xs={6}>
        <RaySwitch
          id="room_disabled"
          label="Is Conference Room Disabled ?"
          checked={selectedValue?.room_disabled ? true : false}
          value={selectedValue?.room_disabled ? true : false}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <RayButton type="submit" onClick={onSubmit} disabled={creditsInput==selectedValue?.creditsPerHour || !creditsInput} loading={loading}>
          Update
        </RayButton>
      </Grid>
      
    </Grid>
  );
}

export default EditConferenceRoomForm;
