import FormInput from './FormInput';
import FormDatePicker from './FormDatePicker';
import FormCounter from './FormCounter';
import FormMultipleProductInput from './FormMultipleProductInput';
import FormGstInput from './FormGstInput';
import FormSwitch from './FormSwitch';
import FormSelect from './FormSelect';
import FormTextArea from './FormTextArea';
import { FORM_FIELD_TYPES, FormConfig } from './types';

const RenderFormItem = (config: FormConfig) => {
  const { type, name, label, ...rest } = config;
  const {
    TEXT,
    EMAIL,
    NUMBER,
    TEXT_AREA,
    SELECT,
    DATE_PICKER,
    COUNTER,
    MULTI_PRODUCT_INPUT,
    GST_INPUT,
    SWITCH,
  } = FORM_FIELD_TYPES;

  const getFormItem = () => {
    switch (type) {
      case TEXT:
      case EMAIL:
      case NUMBER:
        return (
          <FormInput
            label={label}
            type={type}
            name={name}
            inputTextType={config?.inputTextType}
            {...rest}
          />
        );
      case TEXT_AREA:
        return <FormTextArea label={label} type={type} name={name} {...rest} />;
      case SELECT:
        return (
          <FormSelect
            label={label}
            type={type}
            name={name}
            options={config.options}
            fetchOptions={config.fetchOptions}
            api_meta={config.api_meta}
            {...rest}
          />
        );
      case COUNTER:
        return <FormCounter label={label} type={type} name={name} {...rest} />;
      case DATE_PICKER:
        return (
          <FormDatePicker label={label} type={type} name={name} {...rest} />
        );
      case MULTI_PRODUCT_INPUT:
        return (
          <FormMultipleProductInput
            label={label}
            type={type}
            fieldArrayFields={config.fieldArrayFields}
            name={name}
            {...rest}
          />
        );
      case SWITCH:
        return <FormSwitch label={label} type={type} name={name} {...rest} />;
      case GST_INPUT:
        return (
          <FormGstInput
            label={label}
            type={type}
            name={name}
            fields={config.fields}
            {...rest}
          />
        );
      default:
        console.log('form field of type ' + type + ' not implemented');
        return <></>;
    }
  };

  return <>{getFormItem()}</>;
};

export default RenderFormItem;
