import React, { useState, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import RayInput from 'components/RayInput';
import RayBodyText from 'components/Typography/Body';
import RayButton from 'components/RayButton';
import NativeSelect from 'components/MaterialSelect/NativeSelect';
import {
  OPTIONS,
  PAYMENT_OPTIONS,
  changeNoteText,
  ChangeEventSelectInput,
  Props,
} from './utils';

function RequestSection({
  selectedDirector,
  selectedRequestType,
  selectedPlanDetails,
  directors,
  setSelectedPlanDetails,
  setSelectedRequestType,
  setModalSection,
  setSelectedDirector,
  isDocumentExpired,
}: Props) {
  const classes = useStyles();

  const [paymentRef, setPaymentRef] = useState<string>('');
  const [paymentType, setPaymentType] = useState<string>('');
  const [directorName, setDirectorName] = useState<string>('');
  const [directorEmail, setDirectorEmail] = useState<string>('');
  const [noteText, setNoteText] = useState<string | null>('');

  const options = useMemo(()=>{
    if(isDocumentExpired){
      return OPTIONS.filter((option) => option.value !== 'COMPLETE_RESET')
    }
    return OPTIONS
  },[isDocumentExpired])

  useEffect(() => {
    setNoteText(changeNoteText(selectedRequestType));
    if (selectedRequestType === 'MANUAL_AGREEMENT') {
      if (selectedDirector) {
        setDirectorName(selectedDirector.name);
        setDirectorEmail(selectedDirector.email);
      } else {
        setDirectorName('');
        setDirectorEmail('');
      }
    }
    if (selectedRequestType === 'OVERRIDE_PAYMENT') {
      if (selectedPlanDetails) {
        setPaymentRef(selectedPlanDetails.paymentRef);
        setPaymentType(selectedPlanDetails.paymentMode);
      } else {
        setPaymentRef('');
        setPaymentType('');
      }
    }
  }, [selectedRequestType, selectedPlanDetails, selectedDirector]);

  const handleChangeRequestType = (e: ChangeEventSelectInput) => {
    setSelectedRequestType(e.target.value);
  };
  const handleChangePaymentType = (e: ChangeEventSelectInput) => {
    setPaymentType(e.target.value);
  };
  const handleChangeDirector = (e: ChangeEventSelectInput) => {
    setDirectorName(e.target.value);
  };
  const applyChanges = () => {
    if (selectedRequestType === 'OVERRIDE_PAYMENT') {
      const planDetailsBody = {
        paymentMode: paymentType,
        paymentRef,
      };
      setSelectedPlanDetails(planDetailsBody);
    }
    if (selectedRequestType === 'MANUAL_AGREEMENT') {
      const directorsBody = {
        name: directorName,
        email: directorEmail,
      };
      setSelectedDirector(directorsBody);
    }
    setModalSection('WARNING_SECTION');
  };

  const applyBtnDisabled = () => {
    if (selectedRequestType === 'OVERRIDE_PAYMENT') {
      return paymentRef.length < 3 || paymentType.length === 0;
    }
    if (selectedRequestType === 'MANUAL_AGREEMENT') {
      return directorEmail.length < 5 || directorName.length < 3;
    }
    return !selectedRequestType || selectedRequestType.length === 0;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <NativeSelect
          label="Select Request Type"
          onChange={handleChangeRequestType}
          value={selectedRequestType}
          options={options}
          className={`dropdown-class ${classes.fullWidthSelect}`}
          fullWidth
        />
      </Grid>
      {selectedRequestType === 'OVERRIDE_PAYMENT' && (
        <>
          <Grid item xs={7}>
            <NativeSelect
              label="Select Payment Type"
              onChange={handleChangePaymentType}
              value={paymentType}
              options={PAYMENT_OPTIONS.map(i => ({ name: i, value: i }))}
              className={`dropdown-class ${classes.fullWidthSelect}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={7}>
            <RayInput
              placeholder="Enter Payment Reference No."
              id="payment_ref"
              fullWidth
              autoMargin={false}
              value={paymentRef}
              onChange={e => setPaymentRef(e.target.value)}
              label="Payment Reference Number"
            />
          </Grid>
        </>
      )}
      {selectedRequestType === 'MANUAL_AGREEMENT' && (
        <>
          <Grid item xs={7}>
            <NativeSelect
              label="Select Director"
              onChange={handleChangeDirector}
              value={directorName}
              options={directors.map(({ name }) => ({
                name,
                value: name,
              }))}
              className={`dropdown-class ${classes.fullWidthSelect}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={7}>
            <RayInput
              placeholder="Enter Director's Email"
              id="director_email"
              fullWidth
              autoMargin={false}
              value={directorEmail}
              onChange={e => setDirectorEmail(e.target.value)}
              label="Director Email"
            />
          </Grid>
        </>
      )}
      {noteText && noteText.length > 0 && (
        <Grid item xs={12}>
          <RayBodyText>
            <strong>Note: </strong>
            {` ${noteText}`}
          </RayBodyText>
        </Grid>
      )}
      <Grid item xs={12} container justify="flex-end">
        <Grid item xs={3}>
          <RayButton
            fullWidth
            onClick={applyChanges}
            buttonType="primary"
            disabled={(() => applyBtnDisabled())()}
          >
            Apply
          </RayButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RequestSection;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidthSelect: {
      width: '100%',
    },
  }),
);
