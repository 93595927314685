import { useEffect, useState } from 'react';
import { FormGstInputField } from './types';
import { useFormContext } from 'react-hook-form';
import axios, { isCustomAxiosError } from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';
import { Grid } from '@material-ui/core';
import RenderFormItem from './RenderFormItem';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import RayButton from '../RayButton';
import { mandatoryGSTFields } from './utils';

const FormGstInput = ({
  name,
  label,
  required = false,
  disabled = false,
  ...rest
}: FormGstInputField) => {
  const { setValue, watch, trigger, clearErrors } = useFormContext();
  const { authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { fields } = rest;

  const [isGstin, gstNumber, isPostpaid, isOffline, isValidated] = watch([
    'gstinToggle',
    'gstin',
    'postpaid',
    'Payment Mode',
    'validate',
  ]);
  const mandatoryBillingAddressFields = watch(mandatoryGSTFields);
  const { setToastType } = useAuthContext()!;

  const validateGstin = async () => {
    //check if all the fieldValidations are valid for gstin
    const isValid = await trigger('gstin');
    setIsLoading(true);
    //if FE validations are valid then call backend
    if (isValid) {
      try {
        const token = authState?.accessToken?.accessToken;
        const response = await axios.post(
          `/shop/validate-gstin`,
          { gstin: gstNumber },
          {
            headers: { Authorization: token },
          },
        );
        if (response.data.success) {
          setToastType({
            show: true,
            type: 'success',
            message: 'GSTIN validated',
          });
          setValue('validate', true);
        } else {
          setToastType({
            show: true,
            type: 'error',
            message: 'GSTIN is invalid',
          });
          setValue('validate', false);
        }
      } catch (error) {
        console.error('Validation error:', error);
        if (isCustomAxiosError(error)) {
          console.error(error);
          setToastType({
            show: true,
            type: 'error',
            message:
              error?.response?.data?.message ||
              'Something went wrong when validating GSTIN',
          });
          setValue('validate', false);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isValidated) {
      setValue('validate', false);
    }
    if (gstNumber === '') {
      clearErrors('gstin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gstNumber]);

  useEffect(() => {
    if (isGstin) clearErrors(mandatoryGSTFields);
    else clearErrors('gstin');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGstin]);

  useEffect(() => {
    if (
      !mandatoryBillingAddressFields.some(
        value => value !== '' && value !== null,
      )
    ) {
      // why timeout?
      // when we clearErrors the validation again get's triggered and leads to the current field getting validated before it get's changed to non required field.
      // Delaying the clearErrors is a work around - makes sure all field validations are done before clearing errors.
      setTimeout(() => {
        clearErrors(mandatoryGSTFields);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandatoryBillingAddressFields]);

  return (
    <Grid container spacing={isGstin ? 2 : 0}>
      {fields?.map(fieldItemConfig => {
        return fieldItemConfig?.toggle !== isGstin ? null : (
          <Grid item xs={isGstin ? 9 : 12} key={fieldItemConfig.name}>
            <RenderFormItem
              {...fieldItemConfig}
              label={
                isGstin && (isPostpaid || isOffline)
                  ? 'GSTIN'
                  : fieldItemConfig.label
              }
              required={
                isPostpaid || isOffline
                  ? isGstin
                    ? true
                    : fieldItemConfig.required
                  : mandatoryBillingAddressFields.some(
                      value => value !== '' && value !== null,
                    )
                  ? fieldItemConfig.required
                  : false
              }
            />
          </Grid>
        );
      })}
      {isGstin && (
        <Grid item xs={3}>
          <RayButton
            buttonType="secondary"
            onClick={validateGstin}
            disabled={isValidated || isLoading}
          >
            {isLoading ? 'Validating...' : 'Validate'}
          </RayButton>
        </Grid>
      )}
    </Grid>
  );
};

export default FormGstInput;
