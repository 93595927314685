import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import UpdateEnterprise from './UpdateEnterprise';
import RayButton from '../../components/RayButton';
import { FiDownload } from 'react-icons/fi';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import LogoGuidelinesImage from '../../assets/logo_guidelines.png';
import styled from 'styled-components';
import RayInput from 'components/RayInput';
import { numberInputRegex } from 'utils/constants';
import axiosODE from 'utils/axiosODE';
import Counter from './Counter';
import InputDatePicker from './InputDatePicker';
import { isBefore, isEqual } from 'date-fns';
import { useOktaAuth } from '@okta/okta-react';

const ExistingEnterpriseWrapper = styled.div`
  .enterpriseSelectionContainer {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .duration-container{
    padding-top: 2em;
  }

  .pricingContainer {
    margin-bottom: 10px;
  }

  .fileInputField {
    width: 60%;
    margin: 1em 0;
  }

  .guidelines {
    display: flex;
    align-items: center;

    p {
      color: #0000ff;
    }
  }

  .sampleDownload {
    display: flex;
    align-items: center;
    color: blue;
    cursor: pointer;
    margin: 30px 0;
    font-weight: bold;
  }

  h5 {
    margin-bottom: 0;
  }
  .priceExclusiveText {
    color: #666;
    margin-top: 5px;
    font-weight: 400;
    span {
      color: #ff1d1d;
    }
  }
`;

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip);

function ExistingEnterprise() {
  const { setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const [selectedEnterprise, setSelectedEnterprise] = useState<any>({});
  const [uploadedCSVFiles, setUploadedCSVFiles] = useState({});
  const [uploadedLogoFiles, setUploadedLogoFiles] = useState({});
  const [conferenceRoomPrice, setConferenceRoomPrice] = useState('');
  const [dayPassPrice, setDayPassPrice] = useState('');
  const [twoWheelerPrice, setTwoWheelerPrice] = useState('');
  const [fourWheelerPrice, setFourWheelerPrice] = useState('');
  const [colorPrice, setColorPrice] = useState('');
  const [blackWhitePrice, setBlackWhitePrice] = useState('');
  const [updateEnterprisePricing, setUpdateEnterprisePricing] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [startDateOpen, setStartDateOpen] = useState<boolean>(false);
  const [endDateOpen, setEndDateOpen] = useState<boolean>(false);

  const [credits, setCredits] = useState('');

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [startDateDisable, setStartDateDisable] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});

  const handleStartDateInputClick = () => {
    setStartDateOpen((prev: boolean) => !prev);
  };

  const handleEndDateInputClick = () => {
    setEndDateOpen((prev: boolean) => !prev);
  };

  const handleStartDate = (e: any) => {
    if (e) {
      setStartDate(new Date(e));
    }
  };

  const handleEndDate = (e: any) => {
    if (e) {
      setEndDate(new Date(e));
    }
  };

  const fetchEnterprisePricing = async () => {
    setDisableButton(true);
    try {
      const response = await axiosODE.get(
        `/api/automation/enterprise/pricing?enterprise_name=${selectedEnterprise.id
        }`,
        {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        },
      );
      if (response.status === 200) {
        setConferenceRoomPrice(response.data.data.pricing.conference_room);
        setDayPassPrice(response.data.data.pricing.day_pass);
        setTwoWheelerPrice(response.data.data.pricing.parking.two_wheeler);
        setFourWheelerPrice(response.data.data.pricing.parking.four_wheeler);
        setColorPrice(response.data.data.pricing.printing.color);
        setBlackWhitePrice(response.data.data.pricing.printing.black_white);
        setDisableButton(false);
        if (!response.data.data?.credit?.total_credit && !response.data.data?.credit?.start_date && !response.data.data?.credit?.end_date) {
          setStartDateDisable(false);
          setCredits('');
          setStartDate(null);
          setEndDate(null);
        }
        else {
          setInitialValues({ startDate: response.data.data?.credit?.start_date, credits: response.data.data?.credit?.total_credit, endDate: response.data.data?.credit?.end_date })
          setCredits(response.data.data?.credit?.total_credit || '');
          setStartDate(response.data.data?.credit?.start_date || null);
          setEndDate(response.data.data?.credit?.end_date || null);
          setStartDateDisable(true);
        }
      }
    } catch (error) {
      setDisableButton(true);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEnterprisePricing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnterprise]);

  const handleUpdate = async () => {
    if (!selectedEnterprise.id) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Please select an enterprise to proceed.',
      });
    }

    if (updateEnterprisePricing) {
      if (
        conferenceRoomPrice.length === 0 ||
        dayPassPrice.length === 0 ||
        twoWheelerPrice.length === 0 ||
        fourWheelerPrice.length === 0 ||
        colorPrice.length === 0 ||
        blackWhitePrice.length === 0
      ) {
        setToastType({
          show: true,
          type: 'error',
          message: 'Please enter price to update',
        });
        return;
      }
    }

    const triggerWalletApi = !isEqual(new Date(initialValues.startDate), new Date(startDate)) ||
      !isEqual(new Date(initialValues.endDate), new Date(endDate)) ||
      initialValues.credits !== credits;

    if (triggerWalletApi && endDate && isBefore(new Date(endDate), new Date())) {
      setToastType({
        show: true,
        type: 'error',
        message: 'End date must be greater than today\'s date',
      });
      return;
    }

    if (
      Object.keys(selectedEnterprise).length &&
      selectedEnterprise.id &&
      selectedEnterprise.domains.toString()
    ) {
      const formData = new FormData();
      const csv: any = Object.values(uploadedCSVFiles);
      const logo: any = Object.values(uploadedLogoFiles);
      for (let i = 0; i < csv.length; i++) {
        formData.append(`files`, csv[i]);
      }
      for (let i = 0; i < logo.length; i++) {
        formData.append(`files`, logo[i]);
      }
      var enterprisePricing = JSON.stringify({
        conferenceRoomPrice: conferenceRoomPrice,
        dayPassPrice: dayPassPrice,
        black_white_price: blackWhitePrice,
        color_price: colorPrice,
        two_wheeler_price: twoWheelerPrice,
        four_wheeler_price: fourWheelerPrice,
        updateEnterprisePricing: updateEnterprisePricing,
      });
      formData.append('data', enterprisePricing);
      try {
        const response = await axiosODE.patch(
          `/api/automation/employee/?enterpriseName=${selectedEnterprise.id
          }&enterprise_domain=${selectedEnterprise.domains.toString()}`,
          formData,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: authState?.accessToken?.accessToken,
            },
          },
        );
        if (startDate && credits && endDate && triggerWalletApi) {
          const creditBody = {
            enterpriseName: selectedEnterprise.id,
            totalCredit: credits,
            type: "conference_room",
            operation: startDateDisable ? "free_credit_update" : "free_credit_add",
            startDate: startDate,
            endDate: endDate
          }
          await axiosODE.post('/api/credit-wallet/admin-transaction', creditBody, {
            method: 'POST',
            headers: {
              Authorization: authState?.accessToken?.accessToken,
            },
          });
        }
        if (response.status === 200) {
          setToastType({
            show: true,
            type: 'success',
            message: 'Enterprise updated',
          });
          window.location.reload();
        }
        if (response.status === 400) {
          setToastType({
            show: true,
            type: 'success',
            message: 'No new employees found in the data provided.',
          });
        }
      } catch (err) {
        const error: any = err;
        console.error(error);
        setToastType({
          show: true,
          type: 'error',
          message: error.response ? error.response.data.message : error.message,
        });
      }
    }
  };

  const handleLogoUpload = (event: any) => {
    setUploadedLogoFiles(event.target.files);
  };

  const handleFileUpload = (event: any) => {
    setUploadedCSVFiles(event.target.files);
  };

  return (
    <ExistingEnterpriseWrapper>
      <Grid item xs={12}>
        <Grid
          item
          container
          xs={8}
          spacing={2}
          className="enterpriseSelectionContainer"
        >
          <UpdateEnterprise setSelectedEnterprise={setSelectedEnterprise} />
        </Grid>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={updateEnterprisePricing}
                  onChange={e => setUpdateEnterprisePricing(e.target.checked)}
                  value="Update Enterprise Pricing"
                  color="primary"
                />
              }
              label="Update Enterprise Pricing"
            />
          </FormGroup>
        </FormControl>
        <Grid item container xs={10} md={6} xl={4} spacing={2}>
          <Grid item>
            <h4>Pricing Details</h4>
          </Grid>
          <Grid item>
            <h6 className="priceExclusiveText">
              (<span>*</span>exclusive of taxes in INR)
            </h6>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={10}
          md={6}
          xl={4}
          spacing={1}
          direction="row"
          alignItems="center"
          justify="flex-start"
          className="pricingContainer"
        >
          <Grid item xs={12}>
            <RayInput
              id="conference_room_price"
              label="Conference Room Price"
              name="conference_room_price"
              value={conferenceRoomPrice}
              onChange={e =>
                setConferenceRoomPrice(
                  e.target.value.replace(numberInputRegex, ''),
                )
              }
              fullWidth
              autoMargin={false}
              placeholder="250"
              disabled={!updateEnterprisePricing}
              required={updateEnterprisePricing}
            // type="number"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={10}
          md={6}
          xl={4}
          spacing={1}
          direction="row"
          alignItems="center"
          justify="flex-start"
          className="pricingContainer"
        >
          <Grid item xs={12}>
            <RayInput
              id="day_pass_price"
              label="Day Pass Price"
              name="day_pass_price"
              value={dayPassPrice}
              onChange={e =>
                setDayPassPrice(e.target.value.replace(numberInputRegex, ''))
              }
              fullWidth
              autoMargin={false}
              placeholder="500"
              disabled={!updateEnterprisePricing}
              required={updateEnterprisePricing}
            // type="number"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={10}
          md={6}
          xl={4}
          spacing={1}
          direction="row"
          alignItems="center"
          justify="flex-start"
          className="pricingContainer"
        >
          <Grid item xs={12}>
            <RayInput
              id="two_wheeler_price"
              label="Two Wheeler Parking Price"
              name="two_wheeler_price"
              value={twoWheelerPrice}
              onChange={e =>
                setTwoWheelerPrice(e.target.value.replace(numberInputRegex, ''))
              }
              fullWidth
              autoMargin={false}
              placeholder="35"
              disabled={!updateEnterprisePricing}
              required={updateEnterprisePricing}
            // type="number"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={10}
          md={6}
          xl={4}
          spacing={1}
          direction="row"
          alignItems="center"
          justify="flex-start"
          className="pricingContainer"
        >
          <Grid item xs={12}>
            <RayInput
              id="four_wheeler_price"
              label="Four Wheeler Parking Price"
              name="four_wheeler_price"
              value={fourWheelerPrice}
              onChange={e =>
                setFourWheelerPrice(
                  e.target.value.replace(numberInputRegex, ''),
                )
              }
              fullWidth
              autoMargin={false}
              placeholder="80"
              disabled={!updateEnterprisePricing}
              required={updateEnterprisePricing}
            // type="number"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={10}
          md={6}
          xl={4}
          spacing={1}
          direction="row"
          alignItems="center"
          justify="flex-start"
          className="pricingContainer"
        >
          <Grid item xs={12}>
            <RayInput
              id="color_price"
              label="Color Printout Price"
              name="color_price"
              value={colorPrice}
              onChange={e =>
                setColorPrice(e.target.value.replace(numberInputRegex, ''))
              }
              fullWidth
              autoMargin={false}
              placeholder="30"
              disabled={!updateEnterprisePricing}
              required={updateEnterprisePricing}
            // type="number"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={10}
          md={6}
          xl={4}
          spacing={1}
          direction="row"
          alignItems="center"
          justify="flex-start"
          className="pricingContainer"
        >
          <Grid item xs={12}>
            <RayInput
              id="black_white_price"
              label="Black and white Printout Price"
              name="black_white_price"
              value={blackWhitePrice}
              onChange={e =>
                setBlackWhitePrice(e.target.value.replace(numberInputRegex, ''))
              }
              fullWidth
              autoMargin={false}
              placeholder="5"
              disabled={!updateEnterprisePricing}
              required={updateEnterprisePricing}
            // type="number"
            />
          </Grid>
        </Grid>
        <Grid item xs={10}
          md={6}
          xl={4}>
          <h4>Conference room free credits</h4>
          <Counter value={credits} onClick={setCredits} label={'Free credits per month'} />
          <Grid container spacing={2} className='duration-container'>
            <Grid item xs={2} style={{ paddingTop: "20px" }}>
              Duration:
            </Grid>
            <Grid item xs={5}>
              <InputDatePicker label={'Start Date'} open={startDateOpen} setOpen={setStartDateOpen} value={startDate} handleInputClick={handleStartDateInputClick} handleValue={handleStartDate} isDisabled={startDateDisable} />
            </Grid>
            <Grid item xs={5}>
              <InputDatePicker label={'End Date'} open={endDateOpen} setOpen={setEndDateOpen} value={endDate} handleInputClick={handleEndDateInputClick} handleValue={handleEndDate} minDate={new Date(startDate)} isDisabled={!startDate} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            justify="space-between"
            xs={10}
            md={6}
            xl={4}
          />
          <Grid item xs={12} md={9} lg={7} xl={6} style={{ margin: '20px 0' }}>
            <Grid item container alignItems="center" justify="center">
              <Grid item xs={3}>
                <h5>Upload Logo</h5>
              </Grid>
              <Grid
                item
                container
                xs={9}
                alignItems="center"
                justify="space-between"
              >
                <input
                  type="file"
                  name="files"
                  accept="image/*"
                  onChange={e => handleLogoUpload(e)}
                  className="fileInputField"
                />
                <LightTooltip
                  title={
                    <div style={{ marginLeft: '1em' }}>
                      <li style={{ textIndent: '-1em' }}>
                        Please upload in jpeg/jpg / png formats only. File size
                        limit: upto 1MB each.
                      </li>
                      <img
                        src={LogoGuidelinesImage}
                        style={{ width: '100%', height: '100%' }}
                        alt=""
                      />
                      <br />

                      <div>
                        <div style={{ listStyleType: 'decimal' }}>
                          <li style={{ textIndent: '-1em' }}>
                            Logos should be minimum of 190px(width) and
                            50px(height) in dimensions.
                          </li>
                          <div
                            style={{ listStyleType: 'disc', marginLeft: '1em' }}
                          >
                            <li style={{ textIndent: '-1em' }}>
                              Logos larger than this size will snap right to the
                              size
                            </li>
                            <li style={{ textIndent: '-1em' }}>
                              Logos shorter than this size will appear pixelated
                              in this size
                            </li>
                          </div>
                          <br />

                          <li style={{ textIndent: '-1em' }}>
                            Prefer to use dark-coloured logo as the background
                            to this navigation bar is white.
                          </li>
                          <div
                            style={{ listStyleType: 'disc', marginLeft: '1em' }}
                          >
                            <li style={{ textIndent: '-1em' }}>
                              If the logo itself is white, prefer to ask a logo
                              with a dark background.
                            </li>
                          </div>
                          <br />

                          <li style={{ textIndent: '-1em' }}>
                            Any shape can fit into this design as long as it has
                            a minimum of 50px(height)
                          </li>
                        </div>
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <div className="guidelines">
                    <AiOutlineInfoCircle
                      color="#0000FF"
                      style={{ marginRight: '7px' }}
                    />
                    <p>Guidelines</p>
                  </div>
                </LightTooltip>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Grid item xs={3}>
                <h5>Upload CSV</h5>
              </Grid>
              <Grid
                item
                container
                xs={9}
                alignItems="center"
                justify="space-between"
              >
                <input
                  type="file"
                  name="files"
                  accept=".csv"
                  onChange={e => handleFileUpload(e)}
                  className="fileInputField"
                />
                <LightTooltip
                  title={
                    <div style={{ marginLeft: '1em' }}>
                      <li style={{ textIndent: '-1em' }}>
                        The CSV can only have Name,Email,Role,Phone,Approver
                        columns.
                      </li>
                      <li style={{ textIndent: '-1em' }}>
                        All fields mentioned in the sample CSV are mandatory.
                      </li>
                      <li style={{ textIndent: '-1em' }}>
                        Approver’s email is mandatory.
                      </li>
                      <li style={{ textIndent: '-1em' }}>
                        There should be only a single phone number in each phone
                        number field.
                      </li>
                    </div>
                  }
                  placement="right"
                >
                  <div className="guidelines">
                    <AiOutlineInfoCircle
                      color="#0000FF"
                      style={{ marginRight: '7px' }}
                    />
                    <p>Guidelines</p>
                  </div>
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <div
            className="sampleDownload"
            onClick={() =>
              window.open(
                'https://firebasestorage.googleapis.com/v0/b/wework-staging.appspot.com/o/enterprise-accounts%2FCSVs%2FSample-csv-for-employee-data.csv?alt=media&token=76e7fe7b-f1b2-4838-a766-d6282d6279cc',
                '_blank',
              )
            }
          >
            <FiDownload size={20} style={{ marginRight: '1em' }} />
            Download Sample Employee CSV Data
          </div>

          <Grid item xs={4} md={2}>
            <RayButton
              onClick={handleUpdate}
              fullWidth
              disabled={disableButton}
            >
              Update
            </RayButton>
          </Grid>
        </Grid>
      </Grid>
    </ExistingEnterpriseWrapper>
  );
}

export default ExistingEnterprise;
