import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useAuthContext } from "../../utils/hooks/useAuthContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  color: white;
  width: 100%;
  height: 100%;
  color: #000;
  margin-left: 0.5em;
  background-color: #fff;

  .buildingDisplay {
    max-width: 25em;
    padding-bottom: 10px;
  }
`;

function EditProfilePage() {
  const { userInfo } = useAuthContext()!;
  return (
    <>
      <Helmet>
        <title>Profile</title>
        <meta
          name="description"
          content="WeWork On Demand Dashboard - Profile"
        />
      </Helmet>
      <Wrapper>
        <h5>{userInfo && userInfo.name}</h5>
        <h5>{userInfo && userInfo.email}</h5>
        <div className="buildingDisplay">
          {userInfo &&
            userInfo.accessibleBuildings &&
            Object.keys(userInfo.accessibleBuildings).length > 0 &&
            userInfo.accessibleBuildings.constructor === Object &&
            Object.values(userInfo.accessibleBuildings).map((building: any, index: number, array: any[]) => (
              <span>{`${building}${index !== array.length - 1 ? ', ' : ''} `}</span>
            ))}
        </div>
      </Wrapper>
    </>
  );
}

export default EditProfilePage;
