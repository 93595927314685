import { FormSelectField, Meta } from './types';
import { Controller, useFormContext } from 'react-hook-form';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import FormError from './FormError';
import FormHelperText from './FormHelperText';
import { useEffect, useState } from 'react';
import axios from 'utils/axios';
import { capitalizeFirstLetter, generateQueryString } from './utils';
import { useOktaAuth } from '@okta/okta-react';
import { CircularProgress } from '@material-ui/core';

const FormSelect = ({
  name,
  label,
  disabled = false,
  required = false,
  ...rest
}: FormSelectField) => {
  const { control, watch, setValue, setError } = useFormContext();
  const {
    fieldValidations,
    key,
    options,
    helperText,
    fetchOptions,
    api_meta,
  } = rest;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const watchedFields = watch(api_meta?.fieldsToWatch ?? []);
  const { authState } = useOktaAuth();

  const [formOptions, setFormOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (fieldName: string, meta: Meta) => {
    setLoading(true);
    try {
      const token = authState?.accessToken?.accessToken;
      //?startTime=2024-07-09T03:30:00.000Z&endTime=2024-07-09T11:30:00.000Z&id=fc871962-fdc6-4516-ac39-bb12cfeb2297&noOfHrs=1
      const data = api_meta?.fieldsToWatch?.reduce((acc, key, index) => {
        acc[key] = watchedFields[index];
        return acc;
      }, {} as { [key: string]: string });
      const requestUrl = generateQueryString({ meta, data });
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: token,
        },
        ...(api_meta?.baseURL && { baseURL: api_meta?.baseURL }),
      });
      api_meta?.sanity!(response?.data?.data);
      setFormOptions(
        response?.data?.data.map((item: any) => api_meta?.transform!(item)),
      );
    } catch (error) {
      setError(name, {
        type: 'manual',
        message: 'Error in fetching options',
      });
      console.error(`Error fetching options for ${fieldName}:`, error);
    } finally {
      setLoading(false);
    }
  };
  const allFieldsFilled = watchedFields.every(
    value =>
      value !== null && value !== '' && value !== 0 && value !== undefined,
  );

  useEffect(() => {
    if (fetchOptions && api_meta && allFieldsFilled) {
      setValue(name, '');
      fetchData(name, api_meta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...watchedFields, allFieldsFilled]);

  if (loading)
    return <CircularProgress size={25} style={{ color: '#0000FF' }} />;

  if (!allFieldsFilled) return null;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={''}
      rules={{
        required: {
          value: !disabled && required,
          message: 'This field is required',
        },
        ...fieldValidations,
      }}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <>
          <SelectInput
            key={key}
            options={options ?? formOptions ?? []}
            size="medium"
            getOptionLabel={option => capitalizeFirstLetter(option.label)}
            disabled={disabled}
            label={label}
            error={Boolean(error)}
            onChange={(_, selectedOption) => {
              //if there is selling price with select field then update the selling_price field.
              if (
                selectedOption &&
                typeof selectedOption.selling_price === 'number'
              ) {
                const fieldName = name.endsWith('field1')
                  ? name.replace(/field1$/, 'field2')
                  : 'selling_price';
                setValue(fieldName, selectedOption.selling_price);
              }
              onChange(selectedOption);
            }}
            {...rest}
          />
          {helperText && <FormHelperText text={helperText} />}
          <FormError error={error} />
        </>
      )}
    />
  );
};

export default FormSelect;
