import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputField, OnChangeFunction } from './types';
import { validateEmailOrPhone } from './utils';
import {
  XSS_REGEX,
  XSS_REGEX_WITHOUTSPACE,
  XSS_REGEX_WITHOUT_COLON,
} from './constants';
import FormError from './FormError';
import {
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import styled from 'styled-components';
import FormHelperText from './FormHelperText';

const FormInput = ({
  name,
  label,
  disabled = false,
  required = false,
  ...rest
}: FormInputField) => {
  const { control } = useFormContext();
  const { fieldValidations, inputTextType, key, helperText, value } = rest;

  const handleChange = (onChange: OnChangeFunction) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // If the first value is space
    if (e.target.value === ' ') return;

    // If the input is tel
    if (inputTextType === 'tel')
      return onChange(e.target.value.replace(/[^0-9]/g, ''));
    // If the input is email
    else if (inputTextType === 'email')
      return onChange(e.target.value.replace(XSS_REGEX_WITHOUTSPACE, ''));
    // If the input is url
    else if (inputTextType === 'url')
      return onChange(e.target.value.replace(XSS_REGEX_WITHOUT_COLON, ''));
    // If the input is simple input
    else onChange(e.target.value.replace(XSS_REGEX, ''));
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value || ''}
      rules={{
        required: {
          value: !disabled && required,
          message: 'This field is required',
        },
        ...fieldValidations,
        validate: !disabled ? validateEmailOrPhone() : undefined,
      }}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <InputWrapper item xs={12}>
          <FormControl fullWidth disabled={disabled} error={Boolean(error)}>
            <InputLabel
              disabled={disabled}
              htmlFor={key}
              className="input_label"
            >
              {label}
            </InputLabel>
            <OutlinedInput
              id={key}
              label={label}
              type={inputTextType || 'text'}
              placeholder={label}
              onChange={handleChange(onChange)}
              disabled={disabled}
              {...rest}
            />
          </FormControl>
          {helperText && <FormHelperText text={helperText} />}
          <FormError error={error} />
        </InputWrapper>
      )}
    />
  );
};

export default FormInput;

const InputWrapper = styled(Grid)`
  .MuiOutlinedInput-root {
    padding-right: 0;
  }
  .input_label {
    padding-left: 15px;
    top: -5px !important;
    font-size: 1rem;
  }
  .MuiInputLabel-shrink {
    z-index: 2;
  }
`;
