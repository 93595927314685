import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormCounterField, OnChangeFunction } from './types';
import FormError from './FormError';
import { FormControl } from '@material-ui/core';
import styled from 'styled-components';
import RayButton from 'components/RayButton';

const FormCounter = ({
  name,
  label,
  disabled = false,
  required = false,
  ...rest
}: FormCounterField) => {
  const { control } = useFormContext();
  const { fieldValidations, key } = rest;

  // Change Handlers
  const handleChange = (onChange: OnChangeFunction) => (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    onChange(e.target.value.replace(/[^0-9]/g, ''));
  };

  const handleIncrement = (onChange: OnChangeFunction, value: number) => () => {
    if (disabled) return;
    if (!value) onChange(1);
    else if (value >= 1) onChange(value + 1);
  };

  const handleDecrement = (onChange: OnChangeFunction, value: number) => () => {
    if (disabled) return;
    if (value - 1 >= 0) onChange(value - 1);
    else onChange(0);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: !disabled && required,
          message: 'This field is required',
        },
        ...(disabled
          ? {}
          : {
              min: {
                value: 1,
                message: 'Value should be at least 1',
              },
            }),
        ...fieldValidations,
      }}
      defaultValue={''}
      render={({
        field: { onChange, value, ...rest },
        fieldState: { error },
      }) => {
        return (
          <Wrapper>
            <FormControl disabled={disabled} fullWidth error={Boolean(error)}>
              <div className={'ray-text-field ray-text-border'}>
                <input
                  id={key}
                  disabled={disabled}
                  className="ray-text-field__input"
                  placeholder={label}
                  value={value}
                  onChange={handleChange(onChange)}
                  {...rest}
                />
                <label className="ray-text-field__label" htmlFor={key}>
                  {label}
                </label>
                <RayButton
                  type="button"
                  onClick={handleDecrement(onChange, +value)}
                  buttonType="tertiary"
                  className="button-index"
                >
                  -
                </RayButton>
                <RayButton
                  type="button"
                  onClick={handleIncrement(onChange, +value)}
                  buttonType="tertiary"
                  className="button-index"
                >
                  +
                </RayButton>
              </div>
            </FormControl>
            <FormError error={error} />
          </Wrapper>
        );
      }}
    />
  );
};

export default FormCounter;

const Wrapper = styled.div`
  .ray-text-border {
    border: 1px solid #c4c4c4;
  }
  .button-index {
    z-index: 3;
  }
`;
