import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';

function CustomerPagination({
  fetchBookings,
  value,
  counter,
  setCounter,
  bookings,
}: PaginationProps) {
  return (
    <>
      <Grid
        container
        spacing={2}
        item
        justify="space-between"
        xs={12}
        alignItems="center"
      >
        <Grid item style={{ justifyContent: 'flex-start' }}>
          {counter !== 0 && (
            <Button
              onClick={() => {
                fetchBookings(counter - 1, value);
                setCounter(counter - 1);
              }}
            >
              <FaArrowAltCircleLeft size={40} />
            </Button>
          )}
        </Grid>
        <Grid item>Page No: {counter + 1}</Grid>
        <Grid item style={{ justifyContent: 'flex-end' }}>
          {bookings.length > 0 && bookings.length === 10 ? (
            <Button
              onClick={() => {
                fetchBookings(counter + 1, value);
                setCounter(counter + 1);
              }}
            >
              <FaArrowAltCircleRight size={40} />
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default CustomerPagination;

type PaginationProps = {
  value: string;
  fetchBookings: any;
  counter: number;
  setCounter: any;
  bookings: any;
};
