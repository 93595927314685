import React, { useState, useLayoutEffect } from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import axiosODE from 'utils/axiosODE';
import { useOktaAuth } from '@okta/okta-react';

const UpdateEnterprise = ({ setSelectedEnterprise }: any) => {
  const {authState} = useOktaAuth();
  const classes = useStyles();
  const [enterprise, setEnterprise] = useState([]);

  useLayoutEffect(() => {
    const fetchEnterpriseData = async () => {
      try {
        const response = await axiosODE.get('/api/automation/enterprise/', {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        });
        if (response.status === 200) {
          setEnterprise(response.data.data);
        }
      } catch (err) {
        console.error(err);
        setEnterprise([]);
      }
    };

    fetchEnterpriseData();
  }, [authState?.accessToken?.accessToken]);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    if (value) {
      setSelectedEnterprise(value);
    }
  };

  return (
    <FormControl fullWidth className={classes.marginBottom}>
      <Grid container xs={10} md={8} lg={6}>
        <SelectInput
          options={enterprise}
          getOptionLabel={option => option.enterprise_name}
          label="Select Enterprise"
          name="enterprise"
          onChange={handleChange}
        />
      </Grid>
    </FormControl>
  );
};

export default UpdateEnterprise;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginBottom: {
      marginBottom: '2em',
    },
  }),
);
