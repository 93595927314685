import axios from '../axios';
import { generateHmacSha256 } from './generateHmacSha256';

async function fetchFeatureFlagConfig(token: string) {
  const secret_key = process.env.REACT_APP_HMAC_SECRET;
  try {
    const request = await axios.get(`/api/v1/configs?type=search`, {
      headers: {
        Authorization: token,
        'X-Api-Hmac-Sha256': generateHmacSha256({
          data: JSON.stringify({ type: 'search' }),
          key: secret_key,
        }),
      },
    });
    return request.data;
  } catch (err) {
    const error: any = err;
    console.error(error);
    return error.response;
  }
}

export { fetchFeatureFlagConfig };
