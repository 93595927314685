import { FormSwitchField } from './types';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel, Switch, withStyles } from '@material-ui/core';
import FormError from './FormError';
import FormHelperText from './FormHelperText';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#B3B3B3',
    '&$checked': {
      color: '#0000CC',
    },
    '&$checked + $track': {
      backgroundColor: '#9999FF',
    },
  },
  checked: {},
  track: {},
})(Switch);

const FormSwitch = ({
  name,
  label,
  required = false,
  disabled = false,
  ...rest
}: FormSwitchField) => {
  const { control } = useFormContext();
  const { fieldValidations, helperText, heading } = rest;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{
        required: {
          value: !disabled && required,
          message: 'This field is required',
        },
        ...fieldValidations,
      }}
      render={({
        field: { onChange, value, ...rest },
        fieldState: { error },
      }) => (
        <div style={{ paddingTop: '8px' }}>
          {heading && (
            <p>
              <strong>{heading}</strong>
            </p>
          )}
          <FormControlLabel
            control={
              <PurpleSwitch
                checked={value}
                onChange={onChange}
                disabled={disabled}
                {...rest}
              />
            }
            label={label}
          />
          <FormHelperText text={helperText} />
          <FormError error={error} />
        </div>
      )}
    />
  );
};

export default FormSwitch;
