export interface ResponseReservation {
  message: string;
  reservations: Reservations;
}

export interface Reservations {
  upcoming: ReservationDocument[];
  past: ReservationDocument[];
  today: ReservationDocument[];
}

export interface ReservationDocument {
  createdAt: string;
  customerEmail: string;
  startTime: string;
  bookingDate?: number;
  conferenceRoomId: string;
  id: string;
  updatedAt?: string;
  status?: ReservationStatus;
  noOfHrs?: number;
  slotLength?: number;
  memberCount?: number;
  orderId?: number;
  orderNo?: number;
  orderNumber?: number;
  buildingTitle?: string;
  buildingId?: string;
  buildingName?: string;
  isB2BOrder?: boolean;
  name?: string;
  conferenceRoomDetails?: {
    id: string;
    name: string;
    capacity: number;
  };
}

export enum ReservationStatus {
  Booked = 'BOOKED',
  Pending = 'PENDING',
}

export interface ConferenceRoom {
  id: string;
  name: string;
}

export const RESERVATIONS_INITIAL_STATE = {
  upcoming: [],
  past: [],
  today: [],
};

export type ReservationType = ReservationTypesEnum;
export enum ReservationTypesEnum {
  today = 'today',
  past = 'past',
  upcoming = 'upcoming',
}

export type BuildingOption = {
  id: string;
  name: string;
  disabled?: boolean;
};
