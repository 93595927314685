import { Director } from 'containers/VirtualOfficePage/types';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';

type fieldToBeUpdated = Record<'fieldToBeUpdated', string>;

export interface RequestBody {
  documentId: string;
  authToken: string;
  payload: Record<string, string | number | Partial<Director>[]> &
    fieldToBeUpdated;
}

export const UpdateVODetails = ({
  documentId,
  authToken,
  payload,
}: RequestBody) => {
  return axios
    .put(
      `api/v1/virtual-offices/change-kyc-and-reset-legality/${documentId}`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      },
    )
    .then(res => res.data);
};

interface UpdateVODetailsMutationProps {
  successFn?: (data?: any) => void;
  failureFn?: (data?: any) => void;
}

export const UpdateVODetailsMutation = ({
  successFn,
  failureFn,
}: UpdateVODetailsMutationProps) => {
  const queryClient = useQueryClient();
  const { setToastType } = useAuthContext()!;

  return useMutation(UpdateVODetails, {
    onMutate: variables => ({
      ...variables,
    }),

    onSuccess: response => {
      queryClient.invalidateQueries('vo-document-detail');
      successFn?.(response);
    },
    onError: (error: any) => {
      const errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : 'Error occurred while updating details of VO Document';
      setToastType({ show: true, type: 'error', message: errorMessage });
    },
  });
};
