import React from 'react';
import spinner from '../../assets/spinner.gif';
import styled from 'styled-components';

type SpinnerProps = {
  maxHeight: boolean;
};

type Props = {
  maxHeight?: boolean;
  customHeight?: string;
};

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: ${(props: SpinnerProps) => (props.maxHeight ? '100vh' : '85vh')};
  width: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
`;

function Loader({ maxHeight = true, customHeight }: Props) {
  return (
    <SpinnerWrapper maxHeight={!maxHeight} style={{ minHeight: customHeight }}>
      <img style={{ maxWidth: '10em' }} src={spinner} alt="" />
    </SpinnerWrapper>
  );
}

export default Loader;
