import React from "react";

type ColumnProps = {
  children?: React.ReactNode;
  size?: ColumnSizes;
  push?: ColumnSizes;
  fullWidth?: boolean;
  style?: React.CSSProperties;
};

type ColumnSizes =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

const RayColumn = ({ children, size, push, fullWidth = false, style }: ColumnProps) =>
  !fullWidth ? (
    <div
    style={style}
      className={`${
        size ? "ray-grid__cell--span-" + size : "ray-grid__cell"
      }  ${push ? "ray-grid__cell--push-" + size : ""}`}
    >
      {children}
    </div>
  ) : (
    <div className="ray-grid__cell--span-full">{children}</div>
  );

export default RayColumn;
