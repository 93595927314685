import React from 'react';
import {
  InputAdornment,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FaRegCalendarAlt } from 'react-icons/fa';

const InputDatePicker = ({
  value,
  handleInputClick,
  handleValue,
  setOpen,
  open,
  minDate,
  label,
  isDisabled = false,
}: any) => {
  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          style={{ width: '100%' }}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          label={label}
          value={value}
          open={open}
          onClick={handleInputClick}
          onOpen={() => setOpen(true)} // Open calendar when clicked
          onClose={() => setOpen(false)} // Close calendar when date selected or clicked away
          onChange={handleValue}
          minDate={minDate}
          disabled={isDisabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FaRegCalendarAlt onClick={!isDisabled && handleInputClick} />
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default InputDatePicker;

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#0000FF',
      },
      current: {
        color: '#9999FF',
      },
    },
  },
});
