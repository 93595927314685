import React, { Children } from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';
import TableCell from './TableCell';
import { useReactQuery } from 'utils/hooks/useReactQuery';

const Wrapper = styled.div`
  width: 100%;
  table {
    font-size: 14px;
    width: 100%;
  }
  tr.booking_row:hover {
    cursor: pointer;
  }
`;

function TableContainer() {
  const {isLoading,error,data} = useReactQuery(['allBuildings'],{url:'buildings/get'});

  if (isLoading) return <Loader maxHeight />;
  if (error) return <p>Error fetching records</p>;
  return (
      <Wrapper>
        <table className="ray-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>City</th>
              <th>Micro market</th>
              <th>Parking Active</th>
              <th>Conference Room Active</th>
              <th>Virtual Office Active</th>
            </tr>
          </thead>
          <tbody>
            {Children.toArray(
              data.map((data: any, index: number) => (
                <TableCell data={data} index={index} />
              ))
            )}
          </tbody>
        </table>
      </Wrapper>
  );
}

export default TableContainer;
