import React from "react";
import { Modal } from "react-responsive-modal";

type Props = {
  center?: boolean;
  isOpen: boolean;
  children: React.ReactNode;
  showModal: (x: boolean) => void;
};

function DialogModal({ isOpen, showModal, children, center }: Props) {
  return (
    <Modal open={isOpen} onClose={() => showModal(false)} center={center}>
      {children}
    </Modal>
  );
}

export default DialogModal;
