import React from 'react';
import styled from 'styled-components';

type Props = {
  label: string;
};

const SidebarItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 1em;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`;

function SidebarItem({ label }: Props) {
  return <SidebarItemWrapper>{label}</SidebarItemWrapper>;
}

export { SidebarItem };
