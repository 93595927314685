import React from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import RayButton from 'components/RayButton';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import { RefundDetails, refundReasonsMap } from './types';

type RefundContentProps = {
  refundDetails: RefundDetails;
  setRefundDetails: (RefundDetails) => void;
  isActionInProgress: boolean;
  handleAction: (action: string) => void;
};

const RefundContent: React.FC<RefundContentProps> = ({
  refundDetails,
  setRefundDetails,
  isActionInProgress,
  handleAction,
}) => {
  return (
    <>
      <TextField
        style={{
          padding: '0rem 1rem 1rem 0rem',
        }}
        placeholder="Refund Amount"
        value={refundDetails.amount}
        variant="outlined"
        id="refundAmount"
        name="refundAmount"
        onChange={e =>
          setRefundDetails({
            ...refundDetails,
            amount: Number(e.target.value),
          })
        }
        fullWidth
        type="number"
      />
      <SelectInput
        options={Object.keys(refundReasonsMap).map(key => ({
          label: key,
          value: refundReasonsMap[key],
        }))}
        style={{
          padding: '0rem 1rem 1rem 0rem',
        }}
        size="medium"
        getOptionLabel={option => option.label}
        label="Reason"
        onChange={(_, selectedReason) => {
          setRefundDetails({ ...refundDetails, reason: selectedReason });
        }}
        value={refundDetails.reason}
        disabled={false}
      />
      <TextField
        placeholder="Note"
        style={{
          padding: '0rem 1rem 1rem 0rem',
        }}
        value={refundDetails.note}
        variant="outlined"
        id="refundReason"
        fullWidth
        name="refundReason"
        onChange={e =>
          setRefundDetails({ ...refundDetails, note: e.target.value })
        }
        type="text"
      />
      <DialogActions>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <RayButton
            disabled={
              refundDetails.amount! <= 0 ||
              !refundDetails.reason ||
              (refundDetails.reason.label === 'Other' && !refundDetails.note) ||
              isActionInProgress
            }
            compact
            buttonType="primary"
            onClick={() => handleAction('refund')}
          >
            Confirm
          </RayButton>
        </div>
      </DialogActions>
    </>
  );
};

export default RefundContent;
