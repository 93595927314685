import React from 'react';
import { FormTextAreaField, OnChangeFunction } from './types';
import { Controller, useFormContext } from 'react-hook-form';
import { XSS_REGEX } from './constants';
import { FormControl } from '@material-ui/core';
import FormError from './FormError';
import FormHelperText from './FormHelperText';

const FormTextArea = ({
  name,
  label,
  disabled = false,
  required = false,
  ...rest
}: FormTextAreaField) => {
  const { control } = useFormContext();
  const { fieldValidations, helperText, rows } = rest;

  const handleChange = (onChange: OnChangeFunction) => (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    onChange(e.target.value.replace(XSS_REGEX, ''));
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{
        required: {
          value: !disabled && required,
          message: 'This field is required',
        },
        ...fieldValidations,
      }}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <>
          <FormControl fullWidth disabled={disabled} error={Boolean(error)}>
            <div className="ray-text-area">
              <textarea
                rows={rows ?? 4}
                className="ray-text-area__input"
                id={name}
                onChange={handleChange(onChange)}
                {...rest}
              />
              <label className="ray-text-area__label" htmlFor={name}>
                {label}
              </label>
            </div>
          </FormControl>
          <FormHelperText text={helperText} />
          <FormError error={error} />
        </>
      )}
    />
  );
};

export default FormTextArea;
