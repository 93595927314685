import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { H5 } from 'components/Typography';
import RayButton from 'components/RayButton';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import axios from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';

const DownloadUploadButtons = ({ text, fileName, building_id }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [isWrongFormat, setIsWrongFormat] = useState(false);
  const { setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();
  const path = `virtual-office-documents/buildings/${building_id}`;
  const fileNameWithExtension = `${fileName}.pdf`;

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get<{ url: string }>(
          '/storage/admin/get/',
          {
            params: {
              folder: path,
              fileRef: fileNameWithExtension,
            },
            headers: {
              Authorization: authState?.accessToken?.accessToken,
            },
          },
        );
        if (!data.url) {
          throw Error('Url not found');
        }
        setUrl(data.url);
      } catch (error) {
        console.log(error);
        setUrl(undefined);
      }
    })();
  }, [path, fileNameWithExtension, authState]);

  //!TODO: use react-dropzone for file upload;
  const handleFileChange = async (e: any) => {
    setDisabled(true);
    const file = e.target.files[0];
    const fileReader = new FileReader();
    let header = '';
    let type = '';
    fileReader.onloadend = async function(e) {
      if (e?.target?.result) {
        const arr = new Uint8Array(e?.target?.result as ArrayBuffer).subarray(
          0,
          4,
        );
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        switch (header) {
          //to check whether header is of required type based on header codes.
          case '25504446':
            type = 'application/pdf';
            break;
          default:
            // Or you can use the blob.type as fallback
            type = 'unknown';
            break;
        }
      }

      if (type === 'unknown') {
        setIsWrongFormat(true);
        setDisabled(false);
        return;
      }
      try {
        const formData = new FormData();
        formData.append('file', file as Blob);
        formData.append('folder', path);
        formData.append('fileName', fileNameWithExtension);
        const { data } = await axios.post('/storage/admin/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: authState?.accessToken?.accessToken,
          },
        });
        console.log(building_id);
        setIsWrongFormat(false);
        setToastType({
          show: true,
          type: 'success',
          message: `File upload successfully`,
        });
        setTimeout(() => {
          if (data.url) {
            setUrl(data.url);
          } else {
            window.location.reload();
          }
        }, 500);
        setDisabled(false);
      } catch (error) {
        console.log(error);
        setToastType({
          show: true,
          type: 'error',
          message: 'Error occurred while uploading',
        });
        setDisabled(false);
      }
    };
    fileReader.readAsArrayBuffer(file);
  };
  return (
    <Grid item xs={6} md={4} lg={3} xl={2}>
      <StyledHeading>{text}</StyledHeading>
      <RayButton
        onClick={() => fileInputRef.current?.click()}
        buttonType="secondary"
        disabled={disabled}
      >
        {url ? 'Replace' : 'Upload'}
        <HiddenInput
          type="file"
          onChange={handleFileChange}
          ref={fileInputRef as React.RefObject<HTMLInputElement>}
        />
      </RayButton>
      {isWrongFormat && (
        <WarningMessage color="red">
          Only PDF files are supported
        </WarningMessage>
      )}
      {url && (
        <LinkWrapper>
          or click{' '}
          <StyledLink target="_blank" href={url}>
            download
          </StyledLink>{' '}
          to view them
        </LinkWrapper>
      )}
    </Grid>
  );
};

export default DownloadUploadButtons;

const StyledHeading = styled(H5)`
  margin-top: 1em;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: blue;
`;

const LinkWrapper = styled.p`
  margin-top: 1em;
`;

const HiddenInput = styled.input`
  display: none;
`;
const WarningMessage = styled.p`
  color: red;
`;

type Props = {
  text: string;
  fileName: string;
  building_id: string;
};
