import styled from 'styled-components';
import RayButton from '../../components/RayButton';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { endOfDay, format, max, subYears } from 'date-fns';
import AuditHistoryTable from './AuditHistoryTable';
import { Pagination } from '@material-ui/lab';
import axios from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Loader from 'components/Loader';
import { Grid, Popover, TextField } from '@material-ui/core';

type TDateRanger = {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  key?: string | undefined;
  color?: string | undefined;
  showDateDisplay?: boolean | undefined;
  autoFocus?: boolean | undefined;
};

export type TLog = {
  location: string;
  buildingName: string;
  actionedBy: string;
  role: string;
  fileUrl: string;
  status: string;
  emailSent: boolean;
  auditId: string;
  activity: string;
  lastUpdatedAt: string;
};

export type TAuditLogsData = {
  logs: TLog[];
  loading: boolean;
  pagination: {
    limit: string;
    pageNumber: string;
    totalRecordCount: number;
  };
  filters: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
};

const initDateRange: TDateRanger = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
  color: '#0000ff',
  showDateDisplay: true,
  autoFocus: true,
};

const PaginationWrapper = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: flex-end;
`;

const StyledPopover = styled(Popover)`
  // to hide the input date range
  .rdrDefinedRangesWrapper {
    display: none;
  }
  }
`;

const initialLogsState = {
  logs: [],
  loading: true,
  pagination: {
    limit: '10',
    pageNumber: '0',
    totalRecordCount: 0,
  },
  filters: {
    startDate: undefined,
    endDate: undefined,
  },
};

const releaseDateOfODVOPriceAutomation = '2024-04-09'

const AuditLogs = () => {
  const [selectedDateRange, setSelectedDateRange] = useState<TDateRanger>(
    initDateRange,
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [auditLogsData, setAuditLogs] = useState<TAuditLogsData>(
    initialLogsState,
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
  const { authState } = useOktaAuth();
  const { setToastType } = useAuthContext()!;

  useEffect(() => {
    const fetchAuditLogs = async () => {
      try {
        setAuditLogs(prev => {
          return {
            ...prev,
            loading: true,
          };
        });
        const response = await axios.get(`/api/v1/admin/audit-logs`, {
          params: {
            pageNumber: currentPage,
            startDate: auditLogsData.filters.startDate,
            endDate: auditLogsData.filters.endDate,
            limit: 10,
          },
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        });
        if (response.status === 200) {
          setAuditLogs(prev => {
            return {
              ...prev,
              loading: false,
              logs: response?.data?.data?.auditLogs || [],
              pagination:
                response.data.data.pagination || initialLogsState.pagination,
            };
          });
        }
      } catch (error:any) {
        setAuditLogs(prev => {
          return {
            ...prev,
            loading: false,
            logs: [],
          };
        });
        setToastType({
          show: true,
          type: 'error',
          message: error?.response?.data?.message,
        });
      }
    };
    if (authState?.accessToken?.accessToken) {
      fetchAuditLogs();
    }
  }, [
    currentPage,
    authState?.accessToken?.accessToken,
    setToastType,
    auditLogsData.filters,
  ]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageNo: number,
  ) => {
    setCurrentPage(pageNo - 1);
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetFilter = () => {
    setCurrentPage(0)
    setAuditLogs(prev => {
      return {
        ...prev,
        filters: {
          startDate: undefined,
          endDate: undefined,
        },
      };
    });
    handleClose();
  };

  const applyFilter = () => {
    setCurrentPage(0)
    setAuditLogs(prev => {
      return {
        ...prev,
        filters: {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate ? endOfDay(selectedDateRange.endDate): selectedDateRange.endDate,
        },
      };
    });
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <h1>Audit history</h1>
      <Grid item container xs={4}>
        <h5>Date filter</h5>
        <TextField
          placeholder="Enter date range"
          onClick={handleClick}
          variant="outlined"
          value={
            auditLogsData.filters.startDate && auditLogsData.filters.endDate
              ? format(auditLogsData.filters.startDate, 'dd/MM/yyyy') +
                '-' +
                format(auditLogsData.filters.endDate, 'dd/MM/yyyy')
              : ''
          }
          fullWidth
          inputProps={{ readOnly: true }}
        />

        <StyledPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="flex-end"
            style={{ padding: 10 }}
          >
            <DateRangePicker
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
              rangeColors={['#0000ff']}
              ranges={[selectedDateRange]}
              onChange={item => {
                setSelectedDateRange(item.selection);
              }}
              inputRanges={[]}
              staticRanges={[]}
              showDateDisplay={false}
              moveRangeOnFirstSelection={false}
              minDate={max([subYears(new Date(),1),new Date(releaseDateOfODVOPriceAutomation)])}
              maxDate={new Date()}
              showMonthAndYearPickers={true}
              dragSelectionEnabled={true}
              showPreview={false}
            />
            <Grid item>
              <RayButton
                buttonType="secondary"
                className="cta"
                style={{
                  padding: '12px 16px',
                }}
                onClick={resetFilter}
              >
                Reset
              </RayButton>
              <RayButton
                buttonType="primary"
                style={{
                  padding: '12px 16px',
                }}
                onClick={applyFilter}
              >
                Apply
              </RayButton>
            </Grid>
          </Grid>
        </StyledPopover>
      </Grid>
      {auditLogsData?.loading ? (
        <Loader />
      ) : (
        <AuditHistoryTable auditLogs={auditLogsData.logs} />
      )}
      {!!(auditLogsData?.logs?.length && !auditLogsData?.loading) && (
        <PaginationWrapper>
          <Pagination
            count={Math.ceil(auditLogsData.pagination.totalRecordCount / 10)}
            page={currentPage + 1}
            onChange={handlePageChange}
            shape="rounded"
          />
        </PaginationWrapper>
      )}
    </div>
  );
};

export default AuditLogs;
