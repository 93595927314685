import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import TableContainer from './TableContainer';
import ModalDialog from 'components/ModalDialog';
import ExportCSVForm from 'components/ExportCSVForm';
import { useRef } from 'react';
import CommonFilterComponent from 'components/FilterComponent';
import { Grid } from '@material-ui/core';
import { BuildingOption } from 'containers/Bookings/types';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import { DropdownTypes } from 'containers/PurchasesPage/types';
import { H3 } from 'components/Typography/Heading';
import RayButton from 'components/RayButton';

interface RefObj {
  fetchAgain: () => void;
  refreshLoad: () => void;
}

function PurchasesPage() {
  const [buildingsData] = useFetchRequest('/buildings/get-user-buildings');
  const [detailModal, toggleDetailModal] = useState<boolean>(false);
  const [apiRoute, setApiRoute] = useState<string>('');
  const [secondaryText, setSecondaryText] = useState<string>('');
  const [results, setResults] = useState<number>(10);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedOptionName, setSelectedOptionName] = useState<string>('');
  const [searchString, setSearchString] = useState<string>('');
  const [selectedBuilding, setSelectedBuilding] = useState<number>(-1);
  const [orderType, setOrderType] = useState<DropdownTypes>({
    label: 'ALL',
    value: 'ALL',
  });
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#0000ff',
      showDateDisplay: true,
      autoFocus: true,
    },
  ]);
  const [exportType, setExportType] = useState<DropdownTypes | null>(null);

  const childRef = useRef<RefObj>(null);

  const handleChange = (option: string) => {
    setSelectedOption(option);
    switch (option) {
      case 'parking':
        return setSelectedOptionName('Parking');
      case 'conference-room-seat':
        return setSelectedOptionName('Conference Room');
      case 'printing':
        return setSelectedOptionName('Printing');
      case 'gift-card':
        return setSelectedOptionName('Gift Cards');
      case 'lost-keycard':
        return setSelectedOptionName('Lost Keycard');
      case 'custom-events':
        return setSelectedOptionName('Custom Events');
      case 'event-space':
        return setSelectedOptionName('Event Space');
      case 'all':
        return setSelectedOptionName('');
      default:
        return null;
    }
  };

  useEffect(() => {
    if (exportType) {
      handleChange(exportType && exportType.value);
      refetchData();
    }
  }, [exportType]);

  const OrderOptions = [
    { value: 'PENDING', label: 'PENDING' },
    { value: 'COMPLETED', label: 'COMPLETED' },
    { value: 'CANCELLED', label: 'CANCELLED' },
    { value: 'ALL', label: 'ALL' },
    { value: 'DRAFT', label: 'DRAFT' },
    // { value: 'INITIATED', label: 'INITIATED' },
    { value: 'PAYMENT_IN_PROGRESS', label: 'PAYMENT IN PROGRESS' },
    { value: 'PAYMENT_COMPLETED', label: 'PAYMENT COMPLETED' },
    { value: 'PAYMENT_FAILED', label: 'PAYMENT FAILED' },
    { value: 'PAYMENT_LINK_EXPIRED', label: 'PAYMENT LINK EXPIRED' },
    { value: 'REFUND_IN_PROGRESS', label: 'REFUND IN PROGRESS' },
    { value: 'PARTIALLY_REFUNDED', label: 'PARTIALLY REFUNDED' },
    { value: 'REFUNDED', label: 'REFUNDED' },
    { value: 'REFUND_FAILED', label: 'REFUND FAILED' },
  ];

  useEffect(() => {
    setApiRoute(`/shop/exports`);
    setSecondaryText(`${selectedOptionName} email`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const handleChangeBuilding = (id: string) => {
    if (id) setSelectedBuilding(Number(id));
    else if (id === null) {
      setSelectedBuilding(-1);
    }
    refetchData();
  };

  const refetchData = () => {
    if (childRef.current) {
      childRef.current && childRef.current.refreshLoad();
    }
  };

  return (
    <>
      <Helmet>
        <title>Purchases</title>
        <meta
          name="description"
          content="WeWork On Demand Dashboard - Purchases"
        />
      </Helmet>
      <ButtonRow>
        <H3 mb0>Shop Purchases</H3>

        <RayButton
          buttonType="secondary"
          onClick={() => toggleDetailModal(true)}
        >
          Export Documents
        </RayButton>
      </ButtonRow>
      <SelectResults>
        <Grid item xs={12}>
          <CommonFilterComponent
            setSearchString={setSearchString}
            searchString={searchString}
            refetchData={refetchData}
            buildingList={
              buildingsData && buildingsData.length > 0
                ? buildingsData
                    .filter(({ disabled }: BuildingOption) => !disabled)
                    .concat({ id: -1, name: 'All' })
                : []
            }
            buildingOnChange={handleChangeBuilding}
            setOrderType={setOrderType}
            orderType={orderType}
            OrderOptions={OrderOptions}
            setResults={setResults}
            results={results}
            page="ShopPurchases"
            setSelectedDateRange={setSelectedDateRange}
            selectedDateRange={selectedDateRange}
            setExportType={setExportType}
            exportType={exportType}
            checked={checked}
            setChecked={setChecked}
          />
        </Grid>
      </SelectResults>
      <Wrapper>
        <TableContainer
          queryType={orderType.value}
          hits={results}
          reference={childRef}
          searchString={searchString}
          selectedBuilding={selectedBuilding}
          selectedOption={selectedOption}
          checked={checked}
        />
      </Wrapper>
      <ModalDialog
        isOpen={detailModal}
        showModal={toggleDetailModal}
        fullWidth
        maxWidth="sm"
        title="Email CSV File"
      >
        <ExportCSVForm
          status={orderType && orderType.value}
          selectedOption={selectedOption}
          type="shop-purchases"
          route={apiRoute}
          toggleDetailModal={toggleDetailModal}
          secondaryText={secondaryText}
          showDatePicker={true}
          currentState={orderType && orderType.value}
        />
      </ModalDialog>
    </>
  );
}

export default PurchasesPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: 100%;
  color: #000;
  background-color: #fff;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SelectResults = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
`;
