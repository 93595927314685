import axios from '../axios';

async function fetchUserBuildings(token: string) {
  try {
    const request = await axios.get(`/buildings/get-user-buildings`, {
      headers: { Authorization: token },
    });
    return request.data;
  } catch (err) {
    const error: any = err;
    console.error(error);
    return error.response;
  }
}

export { fetchUserBuildings };