import React, { ChangeEvent } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import styled from 'styled-components';

type OnChangeFunction = (...args: any[]) => void;

const Counter = ({ isDisabled, value, label, onClick }: any) => {
  const handleChange = (onChange: OnChangeFunction) => (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    onChange(e.target.value.replace(/[^0-9]/g, ''));
  };

  const handleIncrement = (onChange: OnChangeFunction, value: number) => () => {
    if (isDisabled) return;
    if (!value) onChange(1);
    else if (value >= 1) onChange(value + 1);
  };

  const handleDecrement = (onChange: OnChangeFunction, value: number) => () => {
    if (isDisabled) return;
    if (value - 1 >= 0) onChange(value - 1);
    else onChange(0);
  };

  return (
    <CounterWrapper>
      <FormControl fullWidth>
        <InputLabel
          disabled={isDisabled}
          htmlFor={label}
          className='input_label'
        >
          {label}
        </InputLabel>
        <OutlinedInput
          id={label}
          label={label}
          type="text" 
          endAdornment={
            <div className={`counter ${isDisabled && 'disabled'}`}>
              <span
                className="counter-btn"
                onClick={handleIncrement(onClick, +value)}
              >
                +
              </span>
              <span
                className="counter-btn"
                onClick={handleDecrement(onClick, +value)}
              >
                -
              </span>
            </div>
          }
          placeholder={label}
          onChange={handleChange(onClick)}
          disabled={isDisabled}
          value={value}
        />
      </FormControl>
    </CounterWrapper>
  );
};

export default Counter;

const CounterWrapper = styled(Grid)`
  position: relative;
  .MuiOutlinedInput-root {
    padding-right: 0;
  }
  legend{
    font-size: 1rem;
  }
  .input_label{
    padding-left: 15px;
    top:-5px !important;
    font-family: "Apercu Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1rem;
  }
  .counter {
    align-self: stretch;
    display: flex;
    cursor: pointer;
    &.disabled {
      /* opacity: 0.5; */
      cursor: not-allowed;
      .counter-btn {
        border-left: 1px solid rgba(0, 0, 0, 0.26);
      }
    }
    .counter-btn {
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-size: 24px;
      background-color: #ededed;
      border-left: 1px solid rgba(0, 0, 0, 0.23);
      user-select: none;

      &:nth-of-type(2) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
`;
