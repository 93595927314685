import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
  width: 100%;
`;

interface Props {
  placeHolder: string;
  options: any[];
  selectedOption: any;
  setSelectedOption: React.Dispatch<any>;
  disabled?: boolean;
  styles?: any
}

const customStyles = {
  control: (base:any) => ({
    ...base,
    height: 55,
  })
};

const DropDownComponent = ({
  placeHolder,
  options,
  selectedOption,
  setSelectedOption,
  disabled=false,
  styles = customStyles
}: Props) => {

  return (
    <DropdownWrapper>
      <Select
        placeholder={placeHolder}
        value={selectedOption}
        onChange={setSelectedOption}
        options={options}
        styles={styles}
        isDisabled={disabled}
    />
    </DropdownWrapper>
  );
};

export default DropDownComponent;
