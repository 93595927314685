import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TableContainer from './TableContainer';
import BulkUpload from './BulkUpload';
// import Select from 'react-select';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import styled from 'styled-components';

export interface Option {
  label: string;
  value: string;
  sample_link?:string;
}

const defaultOptions: Option[] = [
  { label: 'Virtual Office', value: 'VO_PRICE', sample_link:'https://storage.googleapis.com/wework-287517.appspot.com/ondemand-cms/VO.csv' },
  { label: 'Day Pass', value: 'DAY_PASS_PRICE',sample_link:'https://storage.googleapis.com/wework-287517.appspot.com/ondemand-cms/DP.csv' },
  { label: 'Conference Room', value: 'CONF_ROOM_TIER',sample_link:'https://storage.googleapis.com/wework-287517.appspot.com/ondemand-cms/CR.csv' },
  { label: 'Tier Update (Conference Room)', value: 'TIER_UPDATE',sample_link:'https://storage.googleapis.com/wework-287517.appspot.com/ondemand-cms/TIER.csv' },
];

const BuildingWrapper = styled(Grid)`
  justify-content: flex-end;
`;

const BuildingsPage = () => {
  const defaultOption = { label: 'Buildings', value: 'Buildings' };
  const [options, setOptions] = useState<Option[]>(defaultOptions);
  const [uploadType, setUploadType] = useState<Option>(defaultOption);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { userInfo } = useAuthContext()!;

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (uploadType.value !== defaultOption.value) {
      setOptions([...defaultOptions, defaultOption]);
    } else setOptions(defaultOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadType]);

  const handleInput = (value: Option) => {
    setUploadType(value);
    setAnchorEl(null);
  };

  return (
    <BuildingWrapper container item>
      {(userInfo.is_super_admin || userInfo.isBusinessManager) && <Grid item xs={3}>
        <Button
          id="basic-button"
          variant="outlined"
          color="primary"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          endIcon={open ? <AiFillCaretUp /> : <AiFillCaretDown />}
          onClick={handleClick}
          fullWidth
          style={{ width: '100%' }} // Set width to 100%
        >
          Actions
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          PaperProps={{
            style: {
              width: anchorEl ? anchorEl.offsetWidth : 'auto',
            },
          }}
          style={{ top: '45px', width: '100%' }}
        >
          {options.map(option => (
            <MenuItem onClick={() => handleInput(option)} >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </Grid>}
      {uploadType.value === defaultOption.value ? (
        <TableContainer />
      ) : (
        <BulkUpload uploadType={uploadType} />
      )}
    </BuildingWrapper>
  );
};

export default BuildingsPage;
