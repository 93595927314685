import Loader from 'components/Loader';
import React from 'react';
import styled from 'styled-components';
import RayButton from 'components/RayButton';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const Wrapper = styled.div`
  width: 100%;
  table {
    width: 100%;
    font-size: 14px;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  selectRoom: (x: any | null) => void;
  roomsData: any;
  roomsLoader: any;
  roomsError: any;
  currentPage: any;
  setCurrentPage: any;
  lastPage: any;
};

function ConferenceRoomsTable({
  selectRoom,
  roomsData,
  roomsLoader,
  roomsError,
  currentPage,
  setCurrentPage,
  lastPage,
}: Props) {
  const [credits] = useFetchRequest('/shop/get-conference-room-credits');

  const classes = useStyles();
  
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageNo: number,
  ) => {
    setCurrentPage(pageNo);
  };

  if (roomsLoader) return <Loader />;
  if (roomsError) {
    return <p>Couldn't fetch Conference Rooms</p>;
  }
  return (
    <Wrapper>
      <table className="ray-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Building Name</th>
            <th>Capacity</th>
            <th>Covid capacity</th>
            <th>Price</th>
            <th>Room disabled</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {roomsData &&
            roomsData.data &&
            roomsData.data.length > 0 &&
            roomsData.data.map((cell: any, index: number) => (
              <tr key={+index}>
                <td>{cell.name ? cell.name : '--'}</td>
                <td>{cell.building_name ? cell.building_name : '--'}</td>
                <td>{cell.capacity ? cell.capacity : '--'}</td>
                <td>{cell.covid_capacity ? cell.covid_capacity : '--'}</td>
                <td>
                  {cell.creditsPerHour
                    ? cell.creditsPerHour * (credits?.credits || 50)
                    : '--'}
                </td>
                <td>{cell.room_disabled ? 'YES' : 'NO'}</td>
                <td>
                  <RayButton
                    onClick={() => selectRoom(cell)}
                    buttonType="tertiary"
                    compact
                  >
                    Edit
                  </RayButton>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {lastPage && (
        <PaginationWrapper>
          <Pagination
            count={lastPage}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
            classes={{ ul: classes.ul }}
          />
        </PaginationWrapper>
      )}
    </Wrapper>
  );
}

export default ConferenceRoomsTable;

const useStyles = makeStyles(() => ({
  ul: {
    '& .Mui-selected': {
      backgroundColor: '#0000ff',
      color: '#fff',
    },
  },
}));
