import React from "react";
import { Grid } from "@material-ui/core";
import RayButton from "components/RayButton";
import RayBodyText from "components/Typography/Body";

type Props = {
  loading: boolean;
  setModalSection: React.Dispatch<string>;
  triggerRequest: () => void;
};

function WarningSection({ loading, setModalSection, triggerRequest }: Props) {
  return (
    <Grid container spacing={2} justify="flex-end">
      <Grid item xs={12}>
        <RayBodyText>
          Are you sure you want to take this document to the specified state?
        </RayBodyText>
      </Grid>
      <Grid item xs={2}>
        <RayButton
          fullWidth
          onClick={() => setModalSection("REQUEST_SECTION")}
          buttonType="secondary"
          disabled={loading}
        >
          No
        </RayButton>
      </Grid>
      <Grid item xs={3}>
        <RayButton
          disabled={loading}
          fullWidth
          onClick={triggerRequest}
          buttonType="primary"
        >
          Yes
        </RayButton>
      </Grid>
    </Grid>
  );
}

export default WarningSection;
