import React from "react";

type Props = {
  onClick?: () => any;
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
};

const RAY_CLASS = "ray-text--h";

export function H1({ onClick, style, className = "", children }: Props) {
  return (
    <h1
      onClick={onClick}
      style={style}
      className={`${RAY_CLASS}1 ${className}`}
    >
      {children}
    </h1>
  );
}

export function H2({ onClick, style, className = "", children }: Props) {
  return (
    <h2
      onClick={onClick}
      style={style}
      className={`${RAY_CLASS}2 ${className}`}
    >
      {children}
    </h2>
  );
}

export function H3({ onClick, style, className = "", children }: Props) {
  return (
    <h3
      onClick={onClick}
      style={style}
      className={`${RAY_CLASS}3 ${className}`}
    >
      {children}
    </h3>
  );
}

export function H4({ onClick, style, className = "", children }: Props) {
  return (
    <h4
      onClick={onClick}
      style={style}
      className={`${RAY_CLASS}4 ${className}`}
    >
      {children}
    </h4>
  );
}

export function H5({ onClick, style, className = "", children }: Props) {
  return (
    <h5
      onClick={onClick}
      style={style}
      className={`${RAY_CLASS}5 ${className}`}
    >
      {children}
    </h5>
  );
}
export function H6({ onClick, style, className = "", children }: Props) {
  return (
    <h6
      onClick={onClick}
      style={style}
      className={`${RAY_CLASS}6 ${className}`}
    >
      {children}
    </h6>
  );
}
