import axios from './axios';

export const KEY_STATUS_MAP: { [key: string]: any } = {
  VERIFIED: 'KYC is verified',
  UNVERIFIED: 'Key is not verified',
  USER_DONT_HAVE: 'User does not have adhaar',
};

export const getUserKYCDetails = async (token: string, emails: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(`/customers/get-kyc-details`, {
      params: {
        emails,
      },
      headers: {
        Authorization: token,
      },
    });
    return data;
  } catch (err) {
    const error: any = err;
    throw Error(error?.response?.data?.message || 'Error occured');
  }
};

export const getKYCTooltipContent = (userKYCData: any) => {
  if (userKYCData.hasCompletedKYC)
    return userKYCData.kycKey ? 'Has duplicate accounts' : 'KYC Completed';

  if (userKYCData.kycFailed) {
    return userKYCData.kyc_status && userKYCData.kyc_status !== '--'
      ? Object.keys(KEY_STATUS_MAP).includes(userKYCData.kyc_status)
        ? KEY_STATUS_MAP[userKYCData.kyc_status]
        : userKYCData.kyc_status
      : 'KYC Failed';
  } else {
    return 'KYC not attempted';
  }
};
