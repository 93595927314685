import { isCustomAxiosError } from './axios';

export const handleAxiosError = (error: any, fallbackMessage: string) => {
  if (isCustomAxiosError(error)) {
    const errorResponse = error.response?.data;
    const errorData =
      errorResponse && Array.isArray(errorResponse?.data)
        ? errorResponse.data
        : null;

    const errorMessage =
      errorData && errorData.length > 0
        ? errorData[0]
        : errorResponse?.message || fallbackMessage;

    return errorMessage;
  }

  return fallbackMessage;
};
