import { useState, useEffect } from 'react';
import axios from '../axios';
import axiosODE from 'utils/axiosODE';
import { useOktaAuth } from '@okta/okta-react';

// type UseFetchRequestResult = [any, boolean, any];

export const useFetchRequest = (
  queryString: string,
  apiParams: any = {},
  options?: { refetchOn?: string; useODE?: boolean; base_url?: string },
): any => {
  const [loader, setLoader] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const { authState } = useOktaAuth();
  const { refetchOn, useODE } = options || {};

  const performNetworkRequest = async () => {
    setLoader(true);
    try {
      const token = authState?.accessToken?.accessToken;
      let response: any;
      if (useODE)
        response = await axiosODE.get(queryString, {
          headers: { Authorization: token },
          params: { ...apiParams },
        });
      else
        response = await axios.get(queryString, {
          headers: { Authorization: token },
          params: { ...apiParams },
          baseURL: options?.base_url,
        });
      const data = response.data.data ? response.data.data : response.data;
      setData(data);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    performNetworkRequest();
    // eslint-disable-next-line
  }, [queryString, refetchOn]);
  return [data, loader, error];
};
