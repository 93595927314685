import Grid from '@material-ui/core/Grid';
import ModalDialog from 'components/ModalDialog';
import RayButton from 'components/RayButton';
import { VirtualOfficeDocument } from 'containers/VirtualOfficePage/types';
import React, { useState } from 'react';
import { UpdateVODetailsMutation } from './ApiCalls';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import RayInput from 'components/RayInput';
import { useOktaAuth } from '@okta/okta-react';

type ChangeCompanyNameProps = {
  isOpen: boolean;
  setChangeCompanyNameState: React.Dispatch<boolean>;
  documentData: VirtualOfficeDocument;
};

export const ChangeCompanyNameModal = ({
  documentData,
  isOpen,
  setChangeCompanyNameState,
}: ChangeCompanyNameProps) => {
  const [companyName, setCompanyName] = useState<string>(
    documentData.companyInfo?.name || '',
  );
  const { setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();

  const { isLoading, mutateAsync } = UpdateVODetailsMutation({});

  const handleChangeCompanyNameSubmit = () => {
    if (companyName.trim().length <= 3) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Company name should be more than 3 characters',
      });
    } else {
      mutateAsync({
        authToken: authState?.accessToken?.accessToken || '',
        documentId: documentData.id,
        payload: {
          fieldToBeUpdated: 'companyName',
          companyName: companyName,
        },
      }).then(data => {
        setToastType({
          show: true,
          type: 'success',
          message: 'Successfully updated the company name',
        });
        setChangeCompanyNameState(false);
      });
    }
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      showModal={setChangeCompanyNameState}
      fullWidth
      maxWidth="sm"
      title="Edit Company Name"
    >
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12}>
          <RayInput
            fullWidth
            id="name"
            name="name"
            label="Company Name"
            placeholder="Enter Company name"
            type="text"
            value={companyName}
            onChange={e => setCompanyName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-between">
            <RayButton
              buttonType="secondary"
              onClick={() => setChangeCompanyNameState(false)}
            >
              Cancel
            </RayButton>
            <RayButton
              buttonType="primary"
              onClick={handleChangeCompanyNameSubmit}
              loading={isLoading}
            >
              Submit
            </RayButton>
          </Grid>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};
