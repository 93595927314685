import { HOOK_FORM_XSS_REGEX } from 'components/Form/constants';
import { FORM_FIELD_TYPES, FormConfig } from 'components/Form/types';
import { stateToStateCodes } from 'components/Form/utils';
import { format, setHours, setMinutes } from 'date-fns';
import { ProductResponseType } from '.';

export const formConfigBuilder = (
  data: ProductResponseType,
  isPostpaidUser: boolean,
) => {
  //common fields
  const formConfig: FormConfig[] = [
    {
      name: 'First name',
      label: 'First name',
      type: FORM_FIELD_TYPES.TEXT,
      required: true,
      inputTextType: 'text',
      fieldValidations: {
        pattern: {
          value: /^[a-zA-Z]+[a-z A-Z]*$/,
          message: 'Please enter a valid name',
        },
        maxLength: {
          value: 20,
          message: 'Maximum length is 20 characters',
        },
        minLength: {
          value: 3,
          message: 'First Name should be at least 3 characters',
        },
      },
    },
    {
      name: 'Last name',
      label: 'Last name',
      type: FORM_FIELD_TYPES.TEXT,
      inputTextType: 'text',
      required: true,
      fieldValidations: {
        pattern: {
          value: /^[a-zA-Z]+[a-z A-Z]*$/,
          message: 'Please enter a valid name',
        },
        maxLength: {
          value: 20,
          message: 'Maximum length is 20 characters',
        },
        minLength: {
          value: 3,
          message: 'Last Name should be at least 3 characters',
        },
      },
    },
    {
      name: 'number',
      label: 'Phone Number',
      inputTextType: 'tel',
      required: true,
      type: FORM_FIELD_TYPES.NUMBER,
      fieldValidations: {
        maxLength: {
          value: 10,
          message: 'Please enter a valid phone number',
        },
        minLength: {
          value: 10,
          message: 'Please enter a valid phone number',
        },
        pattern: {
          value: /^[6-9][0-9]{9}$/,
          message: 'Please enter a valid phone number',
        },
      },
    },
    {
      name: 'email',
      label: 'Customer Email',
      type: FORM_FIELD_TYPES.EMAIL,
      inputTextType: 'email',
      required: true,
      fieldValidations: {
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: 'Please enter a valid email',
        },
      },
    },
    {
      name: 'Booking Date',
      label: 'Booking date',
      type: FORM_FIELD_TYPES.DATE_PICKER,
      required: true,
      ...(data?.global_uid && {
        shouldDisableWeekends: true,
      }),
    },
    {
      name: 'Checkout Expiry Date',
      label: 'Checkout Expiry Date',
      type: FORM_FIELD_TYPES.DATE_PICKER,
      required: true,
      targetFieldName: 'Payment Mode',
    },
  ];

  //
  if (data?.meta_info?.can_add_invoice_description) {
    formConfig.push({
      name: 'invoice_description',
      label: 'Invoice Description',
      type: FORM_FIELD_TYPES.TEXT,
      inputTextType: 'text',
      required: true,
      fieldValidations: {
        pattern: {
          value: /^[a-zA-Z]+[a-z A-Z]*$/,
          message: 'Please enter a valid Invoice Description',
        },
        maxLength: {
          value: 20,
          message: 'Maximum length is 20 characters',
        },
        minLength: {
          value: 3,
          message: 'Invoice Description should be at least 3 characters',
        },
      },
    });
  }

  //if inventory is available
  if (data?.global_uid) {
    formConfig.push(
      {
        name: 'hours',
        label: 'Hours',
        type: FORM_FIELD_TYPES.COUNTER,
        required: true,
      },
      {
        name: 'Meeting time',
        label: 'Select timeslot',
        fieldValidations: {
          required: 'Field is required',
        },
        type: FORM_FIELD_TYPES.SELECT,
        fetchOptions: true,
        api_meta: {
          apiEndpoint: 'api/v1/admin/conference-rooms/availability',
          fieldsToWatch: ['hours', 'Booking Date'],
          params: {
            id: data?.global_uid,
          },
          mappings: {
            //field: [params to use in api call]
            'Booking Date': ['startTime', 'endTime'],
            hours: ['noOfHrs'],
          },
          transform: (item: any) => {
            const minutes = Number(item.slice(2));
            const hours = Number(item.slice(0, 2));
            const isoDate = setHours(setMinutes(new Date(), minutes), hours);
            return {
              label: format(isoDate, 'h:mm a'),
              value: isoDate,
            };
          },
          sanity: (data: any) => {
            if (data.length === 0) throw new Error('No available timeslots');
          },
          baseURL: process.env.REACT_APP_PUBLIC_GLOBAL_SERVICE_BASE_URL,
        },
      },
    );
  }

  //if payment mode is available
  if (data?.meta_info?.is_offline_payment_available && isPostpaidUser) {
    formConfig.push({
      name: 'Payment Mode',
      label: 'Offline',
      type: FORM_FIELD_TYPES.SWITCH,
      heading: 'Mode of payment',
    });
  }

  //if product is event invoicing
  if (data?.meta_info?.can_add_multiple_items) {
    formConfig.push({
      name: 'multiEvent',
      label: 'Event list',
      type: FORM_FIELD_TYPES.MULTI_PRODUCT_INPUT,
      meta: { maxProducts: 5, appendCTA: '+ Add event' },
      fieldArrayFields: [
        {
          field1: {
            name: 'eventType',
            label: 'Select event type',
            type: FORM_FIELD_TYPES.SELECT,
            required: true,
            options: data?.pricing?.map((variant: any) => ({
              label: variant.variant_name,
              value: variant.selling_price,
              pricing_id: variant.product_pricing_uid,
              selling_price: variant.selling_price,
            })),
          },
          field2: {
            name: 'eventPrice',
            label: 'Price',
            type: FORM_FIELD_TYPES.NUMBER,
            required: true,
            inputTextType: 'number',
            fieldValidations: {
              min: {
                value: 1,
                message: 'Price must be greater than 0',
              },
            },
          },
        },
      ],
    });
  }

  // if single variant is available - it'll only be for conference
  if (
    data?.pricing?.length === 1 &&
    !data?.meta_info?.can_add_multiple_items &&
    !(data?.global_uid && !data?.meta_info?.lead_fields) // don't allow user to change / add pricing for online conference rooms.
  ) {
    formConfig.push({
      name: 'selling_price',
      label: 'Price',
      type: FORM_FIELD_TYPES.NUMBER,
      required: true,
      inputTextType: 'number',
      //price must be grater than 0 and not negative
      fieldValidations: {
        min: {
          value: 1,
          message: 'Price must be greater than 0',
        },
      },
    });
  }

  // for security deposit
  if (data?.meta_info?.has_deposit) {
    formConfig.push({
      name: 'security_deposit',
      label: 'Security deposit',
      type: FORM_FIELD_TYPES.NUMBER,
      inputTextType: 'number',
      fieldValidations: {
        min: {
          value: 0,
          message: 'Please enter a valid security deposit',
        },
      },
      required: true,
    });
  }

  //if product is event space
  if (data?.meta_info?.lead_fields) {
    formConfig.push(
      {
        name: 'Lead source',
        label: 'Lead Source',
        type: FORM_FIELD_TYPES.SELECT,
        required: true,
        options: data?.meta_info?.lead_fields.map((lead: string) => ({
          label: lead,
          value: lead,
        })),
      },
      {
        name: 'Event lead Id',
        label: 'Event Lead Id',
        type: FORM_FIELD_TYPES.TEXT,
        required: true,
        inputTextType: 'text',
        fieldValidations: {
          pattern: {
            value: /^[a-zA-Z0-9]+$/,
            message: 'Please enter a valid event lead id',
          },
        },
      },
    );
  }

  // for postpaid
  if (data?.meta_info?.postpaid_invoice_allowed && isPostpaidUser) {
    formConfig.push({
      name: 'postpaid',
      label: 'Is Postpaid',
      type: FORM_FIELD_TYPES.SWITCH,
      disabled: data?.meta_info?.can_add_invoice_description,
    });
  }

  //if pricing are available
  if (
    data?.pricing?.length > 1 &&
    !data?.meta_info?.can_add_multiple_items &&
    !data?.global_uid
  ) {
    formConfig.push(
      {
        name: 'product',
        label: 'Select type',
        type: FORM_FIELD_TYPES.SELECT,
        required: true,
        options: data?.pricing?.map((variant: any) => ({
          label: variant.variant_name,
          value: variant.selling_price,
          pricing_id: variant.product_pricing_uid,
          selling_price: variant.selling_price,
        })),
      },
      {
        name: 'selling_price',
        label: 'Price',
        type: FORM_FIELD_TYPES.NUMBER,
        required: true,
        inputTextType: 'number',
        //price must be grater than 0 and not negative
        fieldValidations: {
          min: {
            value: 1,
            message: 'Price must be greater than 0',
          },
        },
      },
    );
  }

  // if (
  //   data?.pricing?.length === 1 &&
  //   !data?.meta_info?.can_add_multiple_items &&
  //   !data?.meta_info?.global_uid
  // ) {
  //   formConfig.push({
  //     name: 'Conference name',
  //     label: 'Conference name',
  //     type: FORM_FIELD_TYPES.TEXT,
  //     required: true,
  //     inputTextType: 'text',
  //     fieldValidations: {
  //       pattern: {
  //         value: /^[a-zA-Z0-9]+$/,
  //         message: 'Please enter a valid conference name',
  //       },
  //     },
  //   });
  // }

  //if quantity is required
  if (data?.meta_info?.is_quantity_required) {
    formConfig.push({
      name: 'quantity',
      label: 'Quantity',
      type: FORM_FIELD_TYPES.COUNTER,
      required: true,
    });
  }

  //for products where there is no meta info (products like offline conf room)
  if (!data?.meta_info || Object.keys(data?.meta_info).length === 0) {
    formConfig.push({
      name: 'selling_price',
      label: 'Price',
      type: FORM_FIELD_TYPES.NUMBER,
      required: true,
      inputTextType: 'number',
      fieldValidations: {
        min: {
          value: 1,
          message: 'Price must be greater than 0',
        },
      },
    });
  }

  //common fields but must be displayed at the end
  formConfig.push(
    {
      name: 'gstinToggle',
      label: 'Is GST available',
      type: FORM_FIELD_TYPES.SWITCH,
      helperText:
        'Please fill the below details if user has provided either his gstin or billing address. This is mandatory for postpaid or offline orders.',
    },
    {
      name: 'gstin',
      label: 'GST Input',
      type: FORM_FIELD_TYPES.GST_INPUT,
      fields: [
        {
          name: 'gstin',
          label: 'GSTIN (optional)',
          type: FORM_FIELD_TYPES.TEXT,
          inputTextType: 'text',
          // required: true,
          fieldValidations: {
            pattern: {
              value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z]{1}[0-9A-Z]{1}$/,
              message: 'Please enter a valid gstin in capital letters',
            },
            maxLength: {
              value: 15,
              message: 'GSTIN must be 15 characters long',
            },
          },
          toggle: true, // if toggle true display this field
        },
        {
          name: 'Address Line 1',
          label: 'Address Line 1',
          type: FORM_FIELD_TYPES.TEXT,
          required: true,
          toggle: false, // if toggle false display this field
          fieldValidations: {
            pattern: {
              value: HOOK_FORM_XSS_REGEX,
              message: 'Please enter valid address line 1',
            },
            minLength: {
              value: 3,
              message: 'Address Line 1 must be at least 3 characters',
            },
          },
          inputTextType: 'text',
        },
        {
          name: 'Address Line 2',
          label: 'Address Line 2 (optional)',
          type: FORM_FIELD_TYPES.TEXT,
          toggle: false,
          inputTextType: 'text',
          fieldValidations: {
            pattern: {
              value: HOOK_FORM_XSS_REGEX,
              message: 'Please enter valid address line 2',
            },
            minLength: {
              value: 3,
              message: 'Address Line 2 must be at least 3 characters',
            },
          },
        },
        {
          name: 'Company Name',
          label: 'Company Name (optional)',
          type: FORM_FIELD_TYPES.TEXT,
          toggle: false,
          inputTextType: 'text',
          fieldValidations: {
            pattern: {
              value: HOOK_FORM_XSS_REGEX,
              message: 'Please enter valid company name',
            },
            minLength: {
              value: 3,
              message: 'Company Name must be at least 3 characters',
            },
          },
        },
        {
          name: 'city',
          label: 'City',
          type: FORM_FIELD_TYPES.TEXT,
          required: true,
          toggle: false,
          fieldValidations: {
            pattern: {
              value: HOOK_FORM_XSS_REGEX,
              message: 'Please enter a valid city',
            },
            minLength: {
              value: 3,
              message: 'City must be at least 3 characters',
            },
          },
          inputTextType: 'text',
        },
        {
          name: 'state',
          label: 'State',
          type: FORM_FIELD_TYPES.SELECT,
          options: Object.keys(stateToStateCodes).map(state => ({
            label: state,
            value: state,
          })),
          required: true,
          toggle: false,
        },
        {
          name: 'pincode',
          label: 'Pincode',
          type: FORM_FIELD_TYPES.TEXT,
          required: true,
          toggle: false,
          fieldValidations: {
            pattern: {
              value: /^[1-9][0-9]{5}$/,
              message: 'Please enter a valid pincode',
            },
            maxLength: {
              value: 6,
              message: 'Pincode must be 6 characters long',
            },
          },
          inputTextType: 'number',
        },
      ],
    },
    {
      name: 'notes',
      label: 'Add notes (optional)',
      type: FORM_FIELD_TYPES.TEXT_AREA,
    },
  );

  return formConfig;
};
