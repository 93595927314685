import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import styled from 'styled-components';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsFileEarmarkText } from 'react-icons/bs';
import { useOktaAuth } from '@okta/okta-react';
import { Option } from '.';
import { FiDownload } from 'react-icons/fi';

interface BulkUploadProps {
  uploadType: Option;
}

interface RowErrorType {
  error: string;
  id: string;
  row: number;
}

const BulkUpload = ({ uploadType }: BulkUploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [loader, setLoader] = useState(false);
  const { setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append('file', file as Blob);
      formData.append('type', uploadType.value);
      await axios.post('/api/v1/products/price/bulk-update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: 'File Uploaded Successfully',
      });
    } catch (err:any) {
      const error: any = err;
      console.error(error);
      let errMsg;
      if (error?.response?.data?.data?.length > 0) {
        errMsg = error.response.data.data.reduce(
          (acc: string, cur: RowErrorType) => {
            return (
              acc +
              cur.error +
              ' for building with id ' +
              cur.id +
              ' in row ' +
              cur.row +
              '\n\n'
            );
          },
          [''],
        );
      } else {
        errMsg =
          error?.response?.data?.message ||
          'Error while uploading the CSV file';
      }

      setToastType({
        show: true,
        type: 'error',
        message: errMsg,
      });
    } finally {
      setLoader(false);
      setFile(null);
    }
  };
  const handleRemove = () => {
    setFile(null);
  };

  return (
    <CSVWrapper container>
      <Grid className="label-container" xs={12}>
        <p className="heading">Bulk upload - {uploadType.label}</p>
        <p>(Note- Please upload .csv file)</p>
      </Grid>
      <Grid className="file">
        {file ? (
          <Grid className="file-meta">
            <div className="file-name">
              <BsFileEarmarkText className="icon file-icon" />
              <span>{file.name}</span>
            </div>
            <div onClick={handleRemove}>
              <RiDeleteBinLine className="icon delete-icon" />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loader}
              style={{ cursor: 'pointer' }}
            >
              Save
            </Button>
          </Grid>
        ) : (
          <Grid>
            <input
              id="csv-upload"
              onChange={e => handleFileUpload(e)}
              disabled={loader}
              type="file"
              accept=".csv"
              style={{ display: 'none' }}
            />
          </Grid>
        )}
      </Grid>

      {!file && (
        <Button
          variant="contained"
          color="primary"
          disabled={loader}
          style={{ cursor: 'pointer' }}
        >
          <label htmlFor="csv-upload" style={{ cursor: 'pointer' }}>
            Upload File
          </label>
        </Button>
      )}
      <Grid
        container
        className="sampleDownload"
        onClick={() => window.open(uploadType.sample_link, '_blank')}
      >
        <FiDownload size={20} style={{ marginRight: '1em' }} />
        Download Sample {uploadType.label} Data
      </Grid>
    </CSVWrapper>
  );
};

export default BulkUpload;

const CSVWrapper = styled(Grid)`
  /* color: red; */
  padding-top: 2rem;
  padding-left: 1rem;
  .label-container {
    margin-bottom: 15px;
    .heading {
      font-weight: 600;
      font-size: 1.5rem;
    }
  }

  .file {
    margin-bottom: 10px;
    .icon {
      font-size: 1.2rem;
      display: flex;
    }
    .file-meta {
      display: flex;
      gap: 10px;
      align-items: center;
      .file-name {
        display: flex;
        align-items: center;
        span {
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .file-icon {
          color: #0000ff;
          margin-right: 10px;
        }
      }
      .delete-icon {
        color: #666;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .sampleDownload {
    display: flex;
    align-items: center;
    color: blue;
    cursor: pointer;
    margin: 30px 0;
    font-weight: bold;
  }
`;
