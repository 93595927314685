import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import { PaginationProps } from './types';

const ShopPagination = ({ setPagination, pagination }: PaginationProps) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      xs={12}
      alignItems="center"
    >
      <Grid>
        {pagination.prev_cursor && (
          <Button
            onClick={() => {
              setPagination({
                direction: 'previous',
                cursor: pagination.prev_cursor,
              });
            }}
          >
            <FaArrowAltCircleLeft size={30} />
          </Button>
        )}
      </Grid>
      <Grid>
        {pagination.next_cursor && (
          <Button
            onClick={() => {
              setPagination({
                direction: 'next',
                cursor: pagination.next_cursor,
              });
            }}
          >
            <FaArrowAltCircleRight size={30} />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ShopPagination;
