import React, { ChangeEvent } from 'react';
import { MainDirector, Director } from 'containers/VirtualOfficePage/types';
import { CombinedPlanDetails } from '../ApiCalls/ChangeStatus';
// CONSTANTS

export const OPTIONS = [
  { name: 'Complete Reset', value: 'COMPLETE_RESET' },
  { name: 'Override Payment', value: 'OVERRIDE_PAYMENT' },
  { name: 'Initial KYC', value: 'INITIAL_KYC' },
  { name: 'Directors KYC', value: 'DIRECTORS_KYC' },
  { name: 'Redo Leegality', value: 'REDO_MEMBERSHIP_AGREEMENT' },
  { name: 'Offline Agreement', value: 'MANUAL_AGREEMENT' },
];

export const PAYMENT_OPTIONS = ['Cheque', 'Cash', 'Demand Draft (DD)', 'NEFT'];

// Function for changing request type's note text

export const changeNoteText = (requestType: string | null) => {
  switch (requestType) {
    case 'COMPLETE_RESET':
      return 'Selected Request completely resets the document i.e. it goes back to Building Selection Step';
    case 'INITIAL_KYC':
      return 'Selected Request takes the customer back to the First KYC Step';
    case 'OVERRIDE_PAYMENT':
      return 'Selected Request is useful when customer is going to pay offline';
    case 'DIRECTORS_KYC':
      return "Selected Request takes the customer to the second KYC step i.e. Directors' KYC";
    case 'MANUAL_AGREEMENT':
      return 'Selected Request is useful when customer is going sign the Virtual Office Agreement Offline';
    case 'REDO_DOCUSIGN':
      return 'Selected Request is useful when customer faced issues while signing the agreement on docusign';
    default:
      return '';
  }
};

// types

export type ChangeEventSelectInput = ChangeEvent<{
  name?: string | undefined;
  value: any;
}>;

export type Props = {
  selectedDirector: MainDirector | undefined;
  selectedRequestType: string | null;
  selectedPlanDetails: CombinedPlanDetails | undefined;
  directors: Director[];
  setSelectedRequestType: React.Dispatch<string>;
  setSelectedPlanDetails: React.Dispatch<(CombinedPlanDetails) | undefined>;
  setModalSection: React.Dispatch<string>;
  setSelectedDirector: React.Dispatch<MainDirector>;
  isDocumentExpired: boolean|undefined;
};
