import React from 'react';
import Autocomplete, {
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import TextField from '@material-ui/core/TextField';

type Props = {
  options: any[];
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => void;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  getOptionLabel: (option: any) => string;
  defaultValue?: any;
  value?: any;
  label?: React.ReactNode;
  name?: string;
  style?: React.CSSProperties;
  inputRef?: React.Ref<any>;
  disabled?: boolean;
  renderOption?: (
    option: any,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  error?: boolean;
  helperText?: React.ReactNode;
  multiple?: boolean | undefined;
  key?: string | number | null | undefined;
  groupBy?: any;
  disableClearable?: boolean;
};

function SelectInput({
  options,
  onChange,
  getOptionLabel,
  size = 'small',
  fullWidth = true,
  disabled = false,
  error = false,
  value,
  defaultValue,
  label,
  name,
  inputRef,
  style,
  helperText,
  multiple = false,
  renderOption,
  key,
  groupBy,
  disableClearable,
}: Props) {
  return (
    <Autocomplete
      id={`${name ? name : 'custom'}-select`}
      key={key}
      multiple={multiple}
      style={style}
      options={options}
      groupBy={groupBy}
      disableClearable={disableClearable}
      getOptionLabel={getOptionLabel}
      fullWidth={fullWidth}
      size={size}
      onChange={onChange}
      disabled={disabled}
      renderOption={renderOption}
      defaultValue={defaultValue}
      value={value}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          name={name}
          error={error}
          helperText={helperText}
          inputRef={inputRef}
        />
      )}
    />
  );
}

export default SelectInput;
