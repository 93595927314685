import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import RayButton from 'components/RayButton';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import logo from 'assets/logo.png';
import Loader from 'components/Loader';
import { useOktaAuth } from '@okta/okta-react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: white;
  font-weight: bold;
  color: #fff;
  background-color: #fff;
`;

function LoginPage() {

  const classes = useStyles();
  const { oktaAuth, authState } = useOktaAuth();
  const { userInfo } = useAuthContext()!;

  const login = async () => oktaAuth.signInWithRedirect();

  if (!authState) {
    return <Loader />;
  }

  if (authState.isAuthenticated && userInfo && userInfo.id && userInfo.is_admin && userInfo.is_legality_user)
    return <Redirect to="/users" />;
  else if (authState.isAuthenticated) {
    return <Redirect to="/day-pass-bookings" />;
  }

    return (
      <>
        <Helmet>
          <title>Login</title>
          <meta
            name="description"
            content="WeWork On Demand Dashboard - Login"
          />
        </Helmet>
        <Wrapper>
          <img src={logo} alt="" className={classes.imageSize} />
          <RayButton
            style={{ width: '20%' }}
            onClick={login}
            buttonType="primary"
          >
            Login
          </RayButton>
        </Wrapper>
      </>
    );
}

export default LoginPage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: '1em',
    },
    imageSize: {
      height: '8em',
      marginBottom: '1em',
    },
  }),
);
