import { useState } from 'react';
import RayButton from 'components/RayButton';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Grid } from '@material-ui/core';
import axios from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { format } from 'date-fns';
import { useQueryClient } from 'react-query';
import { handleAxiosError } from 'utils/errorHelper';

const CreateInventory = ({ id }: { id: string }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [inventoryDate, setInventoryDate] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { authState } = useOktaAuth();
  const { setToastType } = useAuthContext()!;
  const handleChange = (e: any) => {
    if (!e) return setInventoryDate(null);
    if (e && e.toDate()) {
      setInventoryDate(e.toDate());
      setOpen(false);
    }
  };

  const handleCreate = async () => {
    try {
      setLoading(true);
      await axios.post(
        `/api/v1/admin/daywise-inventories`,
        {
          product_uid: id,
          inventories: [
            {
              date: format(inventoryDate, 'yyyy-MM-dd'),
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
          baseURL: process.env.REACT_APP_CATALOGUE_BASE_URL,
        },
      );
      setToastType({
        show: true,
        type: 'success',
        message: 'added inventory successfully',
      });
      setInventoryDate(null);

      //to retrigger inventory query
      queryClient.invalidateQueries(['inventory']);
    } catch (error) {
      const errorMessage = handleAxiosError(
        error,
        'Something went wrong when creating the inventory',
      );
      setToastType({
        show: true,
        type: 'error',
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container justifyContent="flex-end" style={{ paddingRight: '20px' }}>
      <Grid container justifyContent="flex-end" style={{ gap: '10px' }}>
        <Grid xs={4} item>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              className="rhf-datepicker__input"
              label={'Date'}
              inputVariant="outlined"
              style={{ width: '100%' }}
              disableToolbar
              variant="inline"
              format="Do MMMM YYYY"
              onChange={handleChange}
              disablePast
              value={inventoryDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3}>
          <RayButton
            type="button"
            buttonType="primary"
            onClick={handleCreate}
            fullWidth
            disabled={!inventoryDate || isLoading}
          >
            {isLoading ? 'Creating...' : 'Create'}
          </RayButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateInventory;
