import { Option } from 'react-select/src/filters';

interface AddDirectorFormState {
  name: string;
  document: Option | null;
  pan: string;
  panError: string;
  passport: string;
  passportError: string;
}

type AddDirectorFormAction =
  | { type: 'SET_NAME'; payload: string }
  | { type: 'SET_DOCUMENT'; payload: Option | null }
  | { type: 'SET_PAN'; payload: string }
  | { type: 'SET_PAN_ERROR'; payload: string }
  | { type: 'SET_PASSPORT'; payload: string }
  | { type: 'SET_PASSPORT_ERROR'; payload: string }
  | { type: 'RESET_FORM'; payload: null };

export const AddDirectorFormInitialState: AddDirectorFormState = {
  name: '',
  document: null,
  pan: '',
  panError: '',
  passport: '',
  passportError: '',
};

export const AddDirectorFormReducer = (
  state: AddDirectorFormState = AddDirectorFormInitialState,
  action: AddDirectorFormAction,
): AddDirectorFormState => {
  switch (action.type) {
    case 'SET_NAME':
      return { ...state, name: action.payload };
    case 'SET_DOCUMENT':
      return { ...state, document: action.payload };
    case 'SET_PAN':
      return { ...state, pan: action.payload };
    case 'SET_PAN_ERROR':
      return { ...state, panError: action.payload };
    case 'SET_PASSPORT':
      return { ...state, passport: action.payload };
    case 'SET_PASSPORT_ERROR':
      return { ...state, passportError: action.payload };
    case 'RESET_FORM':
      return AddDirectorFormInitialState;
    default:
      return state;
  }
};
