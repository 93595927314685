import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import axios from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';
import pdfIcon from '../../../assets/pdf_icon.png';
import styled from 'styled-components';
import { InvoiceData } from 'containers/Bookings/TableContainer';

const Invoice = ({ purchaseData, isCancelledOrder }) => {
  const { authState } = useOktaAuth();
  const [invoiceResponse, setInvoiceResponse] = useState<InvoiceData | null>({
    GSTINValidity: null,
    isDiscountedOrder: null,
    pdfURL: null,
    typeOfInvoice: null,
    checkoutUserEmail: null,
  });

  useEffect(() => {
    const {
      status,
      dashBoardOrderType,
      shopify_data = {},
      draft_order_id,
      orderNumber,
    } = purchaseData;

    const orderId = ['postpaid-event', 'security-deposit'].includes(
      dashBoardOrderType,
    )
      ? draft_order_id || orderNumber
      : shopify_data?.order_id || orderNumber;
    const invoiceURL =
      dashBoardOrderType === 'security-deposit'
        ? '/invoices/deposit-receipt'
        : '/invoices/admin/invoices';

    // Invoice/receipt generated for postpaid & security deposit orders after creation of payment link
    if (
      orderId &&
      (status === 'COMPLETED' ||
        ['postpaid-event', 'security-deposit', 'custom-event'].includes(
          dashBoardOrderType,
        ))
    ) {
      (async () => {
        try {
          const { data } = await axios.get(invoiceURL, {
            params: { order_id: orderId },
            headers: { Authorization: authState?.accessToken?.accessToken },
          });
          setInvoiceResponse(data?.data as InvoiceData);
        } catch (error) {
          console.error('Error fetching Invoice:', error);
          setInvoiceResponse(null);
        }
      })();
      return;
    }
    setInvoiceResponse(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseData]);

  return (
    <>
      {invoiceResponse && (
        <>
          <Grid item xs={4}>
            <h5>
              {isCancelledOrder &&
              (purchaseData.dashBoardOrderType === 'postpaid-event' ||
                purchaseData.dashBoardOrderType === 'custom-event')
                ? 'Credit Note'
                : 'Invoice'}
              :
            </h5>
            {invoiceResponse.isDiscountedOrder &&
              invoiceResponse.pdfURL === null &&
              'Discount Order'}
            {!invoiceResponse.isDiscountedOrder && invoiceResponse.pdfURL && (
              <InvoiceWrapper>
                <a
                  href={invoiceResponse.pdfURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={pdfIcon} alt="" />
                  {/* <span>Type : {invoiceResponse.typeOfInvoice}</span> */}
                  <span>Download Now</span>
                </a>
                {invoiceResponse.GSTINValidity !== null && (
                  <p>{invoiceResponse?.GSTINValidity}</p>
                )}
              </InvoiceWrapper>
            )}
            {(invoiceResponse.isDiscountedOrder === null ||
              !invoiceResponse.isDiscountedOrder) &&
              invoiceResponse.pdfURL === null &&
              '--'}
          </Grid>
          {invoiceResponse.checkoutUserEmail && (
            <Grid item xs={4}>
              <h5>Checkout Email: </h5>
              <p>{invoiceResponse.checkoutUserEmail}</p>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Invoice;

const InvoiceWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    text-decoration: underline;
  }
`;
