import styled from 'styled-components';
import RayButton from 'components/RayButton';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { capitalizeFirstLetter } from 'components/Form/utils';
import { BsArrowRightShort } from 'react-icons/bs';
import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';

type multiEventType = { field1: { label: string }; field2: string };

const OrderSummaryCard = () => {
  const { watch, formState } = useFormContext();
  const allFields = watch();

  const excludeFields = useMemo(() => {
    const baseExclude = [
      'gstinToggle',
      'postpaid',
      'validate',
      'variants',
      'selling_price',
      'security_deposit',
      'pricing_id',
    ];

    const conditionalExclude = !allFields.gstinToggle
      ? [...baseExclude, 'gstin']
      : [
          ...baseExclude,
          'Address Line 1',
          'Address Line 2',
          'state',
          'pincode',
          'Company Name',
          'city',
        ];

    return conditionalExclude;
  }, [allFields.gstinToggle]);

  const includeFields = ['Payment Mode'];

  const getPrice = useCallback(() => {
    if (
      allFields?.multiEvent?.length > 0 &&
      allFields.multiEvent[0].field2 !== ''
    ) {
      return allFields.multiEvent
        .reduce((sum: number, item: multiEventType) => {
          return sum + parseFloat(item?.field2 || '0');
        }, 0)
        .toFixed(2);
    } else if (allFields.selling_price > 0) {
      if (allFields.quantity && allFields.quantity > 0) {
        return (allFields.selling_price * allFields.quantity).toFixed(2);
      }
      return allFields.selling_price;
    } else return 0;
  }, [allFields.multiEvent, allFields.selling_price, allFields.quantity]);

  const renderArrayField = (key: string, value: multiEventType[]) => {
    if (key === 'multiEvent') {
      const field1List = (value as multiEventType[])
        .map(item => item?.field1?.label)
        .filter(label => label) // Filters out falsy values like empty strings
        .map(capitalizeFirstLetter)
        .join(', ');

      return (
        field1List && (
          <Grid container item className="item" key={key}>
            <Grid xs={5}>
              <strong>Event Names:</strong>
            </Grid>{' '}
            <Grid xs={7}>{field1List}</Grid>
          </Grid>
        )
      );
    }
  };

  const getValue = (key: string, value: any) => {
    if (key === 'Payment Mode') {
      return value ? 'Offline' : 'Online';
    } else {
      return value;
    }
  };

  return (
    <Wrapper container>
      <Grid container item className="bold">
        <Grid xs={12}>Price: ₹{getPrice()}</Grid>
        {allFields.security_deposit > 0 && (
          <Grid xs={12} className="mt-1">
            Security deposit: ₹{allFields.security_deposit}
          </Grid>
        )}
      </Grid>
      {Object.entries(allFields).map(([key, value]) =>
        Array.isArray(value) ? (
          <>{renderArrayField(key, value)}</>
        ) : (
          !excludeFields.includes(key) &&
          !!(includeFields.includes(key) || value) && (
            <Grid container className="item" key={key}>
              <Grid xs={5}>
                <strong>{capitalizeFirstLetter(key)}:</strong>
              </Grid>
              <Grid xs={7}>
                {typeof value === 'object'
                  ? value instanceof Date
                    ? format(value, 'do MMM yyyy')
                    : capitalizeFirstLetter(value.label)
                  : getValue(key, value)}
              </Grid>
            </Grid>
          )
        ),
      )}
      <RayButton
        type="submit"
        buttonType="primary"
        fullWidth
        className="buttonRow"
        disabled={formState.isSubmitting}
      >
        <span>
          {allFields['Payment Mode']
            ? 'Place Order'
            : 'Place Order & Send Invoice'}
        </span>
        <BsArrowRightShort />
      </RayButton>
    </Wrapper>
  );
};

export default OrderSummaryCard;

const Wrapper = styled(Grid)`
  border: 1px solid #e3e3e3;
  padding: 16px;
  border-radius: 4px;
  .item {
    padding: 5px 0;
  }
  .bold {
    font-weight: bold;
  }
  .mt-1 {
    margin-top: 5px;
  }
  .buttonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 1.5rem;
    }
  }
`;
