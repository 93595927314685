import axios from 'axios';

export default axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export interface CustomAxiosError extends Error {
  isAxiosError: boolean;
  response?: {
    data: {
      message: string;
      data?: string[] | string;
    };
  };
}

export function isCustomAxiosError(error: any): error is CustomAxiosError {
  return (error as CustomAxiosError).isAxiosError !== undefined;
}
