import { DropdownTypes } from 'containers/VirtualOfficePage/types';
import React, { createContext, useContext, useState } from 'react';

type ContextStructure = {
  results: number;
  setResults: (value: number) => void;
  searchString: string;
  setSearchString: (value: string) => void;
  orderType: any;
  setOrderType: (value: void | null) => void;
  selectedDateRange: any;
  setSelectedDateRange: (value: any) => any;
  selectedBuilding: number;
  setSelectedBuilding: (value: number) => void;
  expiryMonthSelected: DropdownTypes | null;
  setExpiryMonthSelected: (value: DropdownTypes | null) => void;
  expiryYearSelected: DropdownTypes | null;
  setExpiryYearSelected: (value: DropdownTypes | null) => void;
  buildingSelected: any;
  setBuildingSelected: (value: any) => any;
  currentPage: number;
  setCurrentPage: (value: number) => any;
  filterType: any;
  setFilterType: (value: any) => any;
};

export const VOContext = createContext<ContextStructure | undefined>(undefined);
export function useVO() {
  return useContext(VOContext);
}

export function VOProvider({ children }: any) {
  const [results, setResults] = useState<number>(10);
  const [searchString, setSearchString] = useState<string>('');
  const [orderType, setOrderType] = useState<any>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#0000ff',
      showDateDisplay: true,
      autoFocus: true,
    },
  ]);
  const [selectedBuilding, setSelectedBuilding] = useState<number>(-1);
  const [
    expiryMonthSelected,
    setExpiryMonthSelected,
  ] = useState<DropdownTypes | null>(null);
  const [
    expiryYearSelected,
    setExpiryYearSelected,
  ] = useState<DropdownTypes | null>(null);
  const [buildingSelected, setBuildingSelected] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterType, setFilterType] = useState<any>(null);

  return (
    <VOContext.Provider
      value={{
        results,
        setResults,
        searchString,
        setSearchString,
        orderType,
        setOrderType,
        selectedDateRange,
        setSelectedDateRange,
        selectedBuilding,
        setSelectedBuilding,
        expiryMonthSelected,
        setExpiryMonthSelected,
        expiryYearSelected,
        setExpiryYearSelected,
        buildingSelected,
        setBuildingSelected,
        currentPage,
        setCurrentPage,
        filterType,
        setFilterType,
      }}
    >
      {children}
    </VOContext.Provider>
  );
}
