import React from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';
import RayButton from 'components/RayButton';
import { CustomerForm } from './EditCustomerForm';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import CustomerPagination from './CustomerPagination';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import SendKYCEmail from './SendKYCEmail';
import { getKYCTooltipContent } from 'utils/getKYCDetails';

const Wrapper = styled.div`
  width: 100%;
  table {
    width: 100%;
    font-size: 14px;
  }
  .vl {
    border-left: 1px solid #000;
    height: 20px;
  }
  .send-email-text {
    margin-left: 6px;
    color: #0000ff;
    cursor: pointer;
  }
  .disabled {
    color: #9a9af0;
    cursor: not-allowed;
    pointer-events: none;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
  .btn {
    vertical-align: baseline;
  }
  .link {
    margin-left: 5px;
    cursor: pointer;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  selectUser: (x: CustomerForm | null) => void;
  customersData: any;
  customersLoader: any;
  customersError: any;
  currentPage: any;
  setCurrentPage: any;
  searched?: any;
  fetchCustomers?: any;
};

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#EBEBEB',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip);

function CustomersTable({
  selectUser,
  customersData,
  customersLoader,
  customersError,
  currentPage,
  setCurrentPage,
  searched,
  fetchCustomers,
}: Props) {
  const { userInfo } = useAuthContext()!;
  if (customersLoader) return <Loader />;
  return (
    <Wrapper>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12} style={{ minHeight: '70vh' }}>
          {customersError ? (
            customersError.response.data.message === 'no records found' ? (
              <div style={{ textAlign: 'center' }}>
                No users found, please change the search term or go to previous
                page.
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                Couldn't fetch Customers
              </div>
            )
          ) : (
            <table className="ray-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>OD Recurring Customer</th>
                  <th>Account Created</th>
                  <th>KYC</th>
                  {!userInfo.is_legality_user && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {customersData &&
                  customersData.length > 0 &&
                  customersData.map((cell: any, index: number) => (
                    <tr key={+index}>
                      <td>{cell.name ? cell.name : '--'}</td>
                      <td>{cell.email ? cell.email : '--'}</td>
                      <td>{cell.phone ? cell.phone : '--'}</td>
                      <td>
                        {'isODRecurringCustomer' in cell &&
                        cell.isODRecurringCustomer === true
                          ? 'YES'
                          : '--'}
                      </td>
                      <td>{cell?.auth0Id?.length>5 ? 'YES' : 'NO'}</td>
                      <td>
                        <Grid
                          style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                          }}
                        >
                          <Grid>{cell.hasCompletedKYC ? 'YES' : 'NO'}</Grid>
                          <Grid>
                            <LightTooltip
                              title={getKYCTooltipContent(cell)}
                              placement="top"
                            >
                              <div>
                                <AiOutlineInfoCircle
                                  color="#0000FF"
                                  style={{ marginRight: '7px', marginTop: 6 }}
                                />
                              </div>
                            </LightTooltip>
                          </Grid>
                          {cell.isKYCMandate && (
                            <SendKYCEmail email={cell.email} />
                          )}
                        </Grid>
                      </td>
                      {!userInfo.is_legality_user && (
                        <td>
                          <RayButton
                            onClick={() => selectUser(cell)}
                            buttonType="tertiary"
                            compact
                            className="btn"
                          >
                            Edit
                          </RayButton>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </Grid>
        <Grid item xs={12}>
          <PaginationWrapper>
            <CustomerPagination
              fetchBookings={fetchCustomers}
              value={searched}
              counter={currentPage}
              setCounter={setCurrentPage}
              bookings={customersData ? customersData : []}
            />
          </PaginationWrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default CustomersTable;
