import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CustomersTable from './CustomersTable';
import EditCustomerForm, { CustomerForm } from './EditCustomerForm';
import DialogModal from 'components/ModalDialog';
import axios from 'utils/axios';
import { InputAdornment, TextField } from '@material-ui/core';
import { MdSearch } from 'react-icons/md';
import styled from 'styled-components';
import { emailInputRegex } from 'utils/constants';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthContext } from 'utils/hooks/useAuthContext';

function CustomersPage() {
  const limit = 10;

  const [selectedItem, setSelectedItem] = useState<CustomerForm | null>(null);
  const [modalState, toggleModal] = useState<boolean>(false);

  const [customersData, setCustomersData] = useState<any>([]);
  const [customersLoader, setCustomersLoader] = useState(true);
  const [customersError, setCustomersError] = useState<any>('');

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [searchString, setSearchString] = useState<string>('');

  const [searching, setSearching] = useState(false);

  const { authState } = useOktaAuth();
  const { useAlgolia } = useAuthContext()!;
  const selectUser = (data: CustomerForm | null) => {
    setSelectedItem(data);
    toggleModal(true);
  };

  const fetchCustomers = async (pageNo?: any, searched?: boolean) => {
    const url = useAlgolia ? '/customers/get-all' : '/api/v1/admin/customers';
    const queryString = !(searched || searching)
      ? `${url}?page=${pageNo > 0 ? pageNo : 0}&limit=${limit}`
      : `${url}?page=${
          pageNo > 0 ? pageNo : 0
        }&limit=${limit}&searchEmail=${searchString}`;
    setCustomersLoader(true);
    try {
      const token = authState?.accessToken?.accessToken;
      const response = await axios.get(queryString, {
        headers: { Authorization: token },
      });
      const data = useAlgolia ? response?.data?.data : response?.data?.data?.hits;
      setCurrentPage(pageNo);
      setCustomersData(data);
      setCustomersError('');
      setCustomersLoader(false);
    } catch (error) {
      setCustomersError(error);
      setCustomersLoader(false);
      setCustomersData([]);
    }
  };

  useEffect(() => {
    fetchCustomers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCustomersData = async () => {
    fetchCustomers(currentPage);
  };

  const refetchData = async () => {
    if (!searchString) {
      setSearching(false);
      fetchCustomers(0);
    }
    setSearching(true);
    fetchCustomers(0, true);
  };

  const handleSearch = (ev: any) => {
    if (ev.key === 'Enter') {
      if (!searchString) return refetchData();
      if (searchString.trim()) return refetchData();
    }
  };

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid className="text-field-parent" item xs={12} md={8} lg={4}>
            <TextField
              placeholder={`Search by customer's email address`}
              value={searchString}
              variant="outlined"
              fullWidth
              id="search"
              name="search"
              className="searchbar"
              onChange={e =>
                setSearchString(e.target.value.replace(emailInputRegex, ''))
              }
              type="text"
              onKeyDown={ev => handleSearch(ev)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch color="#979797" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <CustomersTable
            selectUser={selectUser}
            customersData={customersData}
            customersLoader={customersLoader}
            customersError={customersError}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searched={searchString}
            fetchCustomers={fetchCustomers}
          />
        </Grid>
      </Grid>
      <DialogModal
        isOpen={modalState}
        title="Update Customer"
        maxWidth="md"
        fullWidth
        showModal={toggleModal}
      >
        <EditCustomerForm
          toggleModal={toggleModal}
          selectedValue={selectedItem}
          updateCustomersData={updateCustomersData}
        />
      </DialogModal>
    </Wrapper>
  );
}

export default CustomersPage;

const Wrapper = styled.div`
  .searchbar {
    margin-bottom: 10px;
  }
  .text-field-parent {
    margin-left: auto;
  }
`;
