import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import RayButton from 'components/RayButton';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { InvoiceData } from './TableContainer';
import styled from 'styled-components';
import pdfIcon from '../../assets/pdf_icon.png';
import SendKYCEmail from 'containers/CustomersPage/SendKYCEmail';
import { LightTooltip } from 'containers/CustomersPage/CustomersTable';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { getKYCTooltipContent, getUserKYCDetails } from 'utils/getKYCDetails';
import { useOktaAuth } from '@okta/okta-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'start',
    },
    tableParent: {
      width: '100%',
      height: '200px',
      overflow: 'scroll',
      marginBottom: '20px',
      padding: '0px 12px 12px 12px',
      '& table': {
        '& thead': {
          position: 'sticky',
          borderBottom: '1px solid #000ff',
          backgroundColor: '#fff',
          top: '0',
          zIndex: '1',
          marginTop: '0px',
        },
      },
    },

    loadingBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '200px',
    },

    rayTable: {
      fontSize: '14px',
      width: '100%',
    },
    addOnBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    userKyc: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
    infoIcon: {
      marginRight: '7px',
      marginTop: 6,
    },
    yesOrNoTextWidth: {
      width: '30px'
    }
  }),
);

const InvoiceWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;

type Props = {
  bookingInfo: any;
  invoiceResponse: InvoiceData;
  showModal: (x: boolean) => void;
};

function ViewBookingDetails({
  bookingInfo,
  invoiceResponse,
  showModal,
}: Props) {
  const classes = useStyles();
  // const [isMemberVaccinated, setIsMemberVaccinated] = useState<boolean>(false);
  const { setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoadingKYCDetails, setIsLoadingKYCDetails] = useState<boolean>(
    false,
  );
  const [memebersKYCDetails, setMembersKYCDetails] = useState<any>({});

  useEffect(() => {
    // getTeamMembersVaccineDetails();
    if (!bookingInfo.is_b2b_order) getKYCDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingInfo]);

  // const getTeamMembersVaccineDetails = async () => {
  //   const EmailList: any[] = bookingInfo.teamMembers.map(
  //     (item: any) => item.email,
  //   );

  //   const vaccination = await axios.get('/shop/vaccination-status', {
  //     params: {
  //       userDetails: EmailList,
  //     },
  //     headers: {
  //       Authorization: authState?.accessToken?.accessToken,
  //     },
  //   });
  //   bookingInfo.teamMembers.forEach((teamMember: any) => {
  //     vaccination.data.userData.map((status: any) => {
  //       if (teamMember.email === status.userId) {
  //         teamMember.vaccinationStatus = status.vaccineStatus;
  //       }
  //       return true;
  //     });
  //     return teamMember;
  //   });
  //   setIsMemberVaccinated(true);
  // };

  // const vaccineStatusUpdate = async () => {
  //   const result = bookingInfo.teamMembers
  //     .filter(
  //       (item: any) => item.vaccinationStatus && item.vaccinationStatus != null,
  //     )
  //     .map((e: any) => ({
  //       userId: e.email,
  //       vaccinationStatus: e.vaccinationStatus,
  //     }));
  //   if (result.length === 0) {
  //     setToastType({
  //       type: 'error',
  //       message: 'Please select the vaccination status before updating.',
  //       show: true,
  //     });
  //     return;
  //   }
  //   try {
  //     await axios.patch(
  //       '/shop/vaccination-status',
  //       {
  //         userDetails: result,
  //       },
  //       {
  //         headers: {
  //           Authorization: authState?.accessToken?.accessToken,
  //         },
  //       },
  //     );
  //     setToastType({
  //       type: 'success',
  //       message: 'Vaccination Status Updated',
  //       show: true,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const cancelDayPassBooking = async () => {
    setDisabled(true);
    try {
      const { data } = await axios.post(`/cancel/daypass-bookings`, null, {
        params: {
          orderId: bookingInfo.orderId,
        },
        headers: {
          Authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: data.message,
      });

      setDisabled(false);
      showModal(false);
    } catch (err) {
      const error: any = err;
      console.log(error);
      setToastType({
        show: true,
        type: 'error',
        message: error.response.data.message || 'Error occured',
      });
      setDisabled(false);
      showModal(false);
    }
  };

  const getKYCDetails = async () => {
    setIsLoadingKYCDetails(true);
    const emails: string =
      bookingInfo?.teamMembers?.map((item: any) => item.email)?.join(',') || '';
    const token = authState?.accessToken?.accessToken
    try {
      const data = await getUserKYCDetails(token as string, emails);
      setMembersKYCDetails(data);
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error?.response?.data?.message,
      });
    } finally {
      setIsLoadingKYCDetails(false);
    }
  };

  return (
    <Grid container className={classes.root} alignItems="flex-end" spacing={3}>
      <Grid item xs={4}>
        <h5>Location Name:</h5>
        <p>{bookingInfo.locationName}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Name:</h5>
        <p>{`${bookingInfo.customerName}`}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Phone:</h5>
        <p>{bookingInfo.customerPhone}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Order No.:</h5>
        <p>{bookingInfo.orderNumber}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Company:</h5>
        <p>{bookingInfo.customerCompany}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Is B2B Order:</h5>
        <p>{bookingInfo.is_b2b_order ? 'Yes' : 'No'}</p>
      </Grid>

      <Grid item xs={invoiceResponse.checkoutUserEmail ? 4 : 12}>
        <h5>Invoice:</h5>
        {invoiceResponse.isDiscountedOrder &&
          invoiceResponse.pdfURL === null &&
          'Discount Order'}
        {!invoiceResponse.isDiscountedOrder && invoiceResponse.pdfURL && (
          <Grid item xs={12}>
            <InvoiceWrapper>
              <a
                href={invoiceResponse.pdfURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={pdfIcon} alt="" />
                {/* <span>Type : {invoiceResponse.typeOfInvoice}</span> */}
                <span>Download Now</span>
              </a>
              {invoiceResponse.GSTINValidity !== null && (
                <p>{invoiceResponse.GSTINValidity}</p>
              )}
            </InvoiceWrapper>
          </Grid>
        )}
        {(invoiceResponse.isDiscountedOrder === null ||
          !invoiceResponse.isDiscountedOrder) &&
          invoiceResponse.pdfURL === null &&
          '--'}
      </Grid>

      {invoiceResponse.checkoutUserEmail && (
        <Grid item xs={4}>
          <h5>Checkout Email: </h5>
          <p>{invoiceResponse.checkoutUserEmail}</p>
        </Grid>
      )}

      <Grid xs={12} className={classes.tableParent}>
        {isLoadingKYCDetails && (
          <p className={classes.loadingBox}>Loading....</p>
        )}
        {!isLoadingKYCDetails &&
          bookingInfo &&
          bookingInfo.teamMembers &&
          bookingInfo.teamMembers.length > 0 && (
            <table className="ray-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Member Name</th>
                  <th>Member Email</th>
                  {!bookingInfo?.is_b2b_order && <th>KYC</th>}
                </tr>
              </thead>
              <tbody>
                {bookingInfo.teamMembers.map((cell: any, index: number) => {
                  const isCreator =
                    invoiceResponse?.checkoutUserEmail &&
                    invoiceResponse?.checkoutUserEmail === cell?.email;
                  return (
                    <tr key={+index}>
                      <td>{index + 1}</td>
                      <td>{cell.name ? cell.name : '--'}</td>
                      <td>{cell.email ? cell.email : '--'}</td>
                      {!bookingInfo.is_b2b_order && (
                        <td>
                          <Grid className={classes.userKyc}>
                            <Grid className={classes.yesOrNoTextWidth}>
                              {memebersKYCDetails &&
                              memebersKYCDetails[cell.email]
                                ? memebersKYCDetails[cell.email].hasCompletedKYC
                                  ? 'YES'
                                  : 'NO'
                                : ''}
                            </Grid>
                            {memebersKYCDetails &&
                              memebersKYCDetails[cell.email] && (
                                <Grid>
                                  <LightTooltip
                                    title={getKYCTooltipContent(memebersKYCDetails[cell.email])}
                                    placement="top"
                                  >
                                    <div>
                                      <AiOutlineInfoCircle
                                        color="#0000FF"
                                        className={classes.infoIcon}
                                      />
                                    </div>
                                  </LightTooltip>
                                </Grid>
                              )}
                            {/* 
                           If creator then check isKYCMandate flag 
                           If team member has an ccount then check hasCompletedKYC flag 
                           If team member doesn't have an account then show Send KYC email button always
                            */}
                            {(!memebersKYCDetails?.[cell.email] ||
                              (isCreator &&
                                memebersKYCDetails?.[cell.email]
                                  ?.isKYCMandate) ||
                              (!isCreator &&
                                !memebersKYCDetails?.[cell.email]
                                  ?.hasCompletedKYC)) && (
                              <SendKYCEmail email={cell.email} />
                            )}
                          </Grid>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
      </Grid>
      <Grid
        container
        xs={12}
        className={classes.addOnBtn}
        justify="flex-end"
        spacing={2}
      >
        {/* <Grid item>
          <button
            className="ray-button ray-button--primary"
            onClick={vaccineStatusUpdate}
          >
            Update
          </button>
        </Grid> */}
        <Grid item>
          <Link
            className="ray-button ray-button--primary"
            to={{
              pathname: '/shop',
              state: {
                addOnState: {
                  customerEmail: bookingInfo.customerEmail,
                  orderNo: bookingInfo.orderNumber,
                  bookingFirebaseId: bookingInfo.id,
                  buildingName: bookingInfo.locationName,
                },
              },
            }}
          >
            Create Add On Order
          </Link>
        </Grid>
      </Grid>
      {false && (
        <Grid item xs={12} className={classes.addOnBtn}>
          <RayButton
            buttonType="secondary"
            danger
            disabled={disabled}
            onClick={cancelDayPassBooking}
          >
            Cancel
          </RayButton>
        </Grid>
      )}
    </Grid>
  );
}

export default ViewBookingDetails;
