import { SelectedPlan, MainDirector } from "containers/VirtualOfficePage/types";
import axios from "utils/axios";

export type PlanDetails = Omit<SelectedPlan, "variantId">;

export type ExtraPlanDetails = {
  paymentMode: string;
  paymentRef: string;
};

export type CombinedPlanDetails = PlanDetails & ExtraPlanDetails;

export interface RequestBody {
  documentId: string;
  requestType: string;
  authToken: string;
  planDetails?: CombinedPlanDetails;
  directorDetails?: MainDirector;
}

export const changeStatus = ({
  documentId,
  requestType,
  planDetails,
  authToken,
  directorDetails,
}: RequestBody) =>
  axios
    .patch(
      "/vo/admin/modify-status",
      {
        doc_id: documentId,
        request_type: requestType,
        plan_details: planDetails,
        director_details: directorDetails,
      },
      {
        headers: {
          Authorization: authToken,
        },
      }
    )
    .then((res) => res.data);
