import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import RayInput from 'components/RayInput';

const CustomInput = ({ label, value, id }: Props) => {
  return (
    <Grid item xs={12}>
      <StyledInput
        id={id}
        type="text"
        fullWidth
        autoMargin={false}
        value={value}
        label={label}
        disabled
      />
    </Grid>
  );
};

export default CustomInput;

const StyledInput = styled(RayInput)`
  margin-bottom: 2em;
`;

type Props = {
  value: string;
  id: string;
  label: string;
};
