import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  data: any;
  index: number;
};

function TableCell({ data, index }: Props) {
  return (
    <tr key={+index} className="purchase_row">
      <td>
        <StyledLink to={`/buildings/${data.id}`}>
          {data.name ? data.name : '--'}
        </StyledLink>
      </td>
      <td>{data.city ? data.city : '--'}</td>
      <td>{data.micromarket ? data.micromarket : '--'}</td>
      <td>{data.parking_disabled ? 'No' : 'Yes'}</td>
      <td>{data.conference_rooms_disabled ? 'No' : 'Yes'}</td>
      <td>{data.display_on_vo ? 'Yes' : 'No'}</td>
    </tr>
  );
}

export default TableCell;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  color: black;
`;
