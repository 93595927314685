import Loader from 'components/Loader';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import Details from './Details';
// import { DOCUMENT_INITIAL_STATE, VODocumentDetail } from "./types";

interface ParamTypes {
  docId: string;
}

function VirtualOfficeDetailPage() {
  const { docId } = useParams<ParamTypes>();
  const { setToastType } = useAuthContext()!;
  const { data: documentData, isLoading: loader, refetch } = useReactQuery(
    ['vo-document-detail', docId],
    {
      url: '/vo/admin/fetch-doc-by-id',
      params: {
        doc_id: docId,
      },
    },
    {
      onError: (error: any) => {
        setToastType({
          show: true,
          type: 'error',
          message:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : 'Error occurred while fetching Document Data',
        });
      },
    },
  );

  const deregistrationVOObject: any = {
    deregistrationDocUrl:
      documentData && documentData.document.deregistrationDocUrl,
    deregistrationRequestCreatedAt:
      documentData && documentData.document.deregistrationRequestCreatedAt,
    hasAppliedForDeregistration:
      documentData && documentData.document.hasAppliedForDeregistration,
    deregistrationDocType:
      documentData && documentData.document.deregistrationDocType,
  };

  return (
    <>
      <Helmet>
        <title>Approve Virtual Office Doc</title>
        <meta
          name="description"
          content="WeWork On Demand Dashboard - Approve Virtual Office Doc"
        />
      </Helmet>
      {loader && <Loader />}
      {!loader && documentData && documentData.document && (
        <Details
          documentData={documentData.document}
          payments={documentData.payments}
          subscriptions={documentData.subscriptions}
          packages={documentData.packages}
          fetchDocument={refetch}
          deregistration={deregistrationVOObject}
        />
      )}
    </>
  );
}

export default VirtualOfficeDetailPage;
