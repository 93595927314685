import React, { useState, useMemo } from 'react';
import axios, { isCustomAxiosError } from 'utils/axios';
import RayButton from 'components/RayButton';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import ModalDialog from 'components/ModalDialog';
import ViewPurchasesDetails from './ViewPurchasesDetails';
import format from 'date-fns/format';
import { useOktaAuth } from '@okta/okta-react';
import MenuItem from '@material-ui/core/MenuItem';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { ClickAwayListener, DialogActions, Popper } from '@material-ui/core';
import { actionTypeMapping } from 'utils/constants';
import {
  REFUND_ELIGIBLE_ORDER_STATUSES,
  RefundDetails,
  TableProps,
} from './types';
import RefundContent from './RefundContent';
import { handleAxiosError } from 'utils/errorHelper';

const TableCell = ({ cell, index, reloadPurchases }: TableProps) => {
  const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);
  const { setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>('');
  const [refundDetails, setRefundDetails] = useState<RefundDetails>({
    amount: null,
    reason: null,
    note: undefined,
  });

  //popper menu related states
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const hideCancelButton = useMemo(
    () =>
      cell.status === 'CANCELLED' ||
      ['Gift Card', 'Gift-card'].includes(cell.product_name) ||
      cell.isGlobalPrintOrder,
    [cell],
  );

  const isDepositPaid =
    cell.productName === 'Security-deposit' && cell.status === 'COMPLETED';

  const actionsArray = useMemo(
    () =>
      [
        {
          type: actionTypeMapping.cancel.type,
          label: actionTypeMapping.cancel.label,
          show: !hideCancelButton && !cell.isGlobalPrintOrder && !isDepositPaid,
        },
        {
          type: actionTypeMapping.resend.type,
          label: actionTypeMapping.resend.label,
          show:
            ['PENDING', 'DRAFT'].includes(cell.status) &&
            cell.paymentMode !== 'OFFLINE',
        },
        {
          type: actionTypeMapping.fullfillment.type,
          label: actionTypeMapping.fullfillment.label,
          show:
            ['PENDING', 'DRAFT'].includes(cell.status) &&
            cell.paymentMode === 'OFFLINE',
        },
        {
          type: actionTypeMapping.refund.type,
          label: actionTypeMapping.refund.label,
          show:
            Object.values(REFUND_ELIGIBLE_ORDER_STATUSES).includes(
              cell.status as REFUND_ELIGIBLE_ORDER_STATUSES,
            ) &&
            cell.order_id !== '--' &&
            cell.paymentMode !== 'OFFLINE', // don't show refund button for offline orders
        },
      ].filter(action => action.show),
    [cell, hideCancelButton, isDepositPaid],
  );

  const handleAction = async (actionType: string) => {
    setIsActionInProgress(true);
    // let actionSuccessful = true;
    try {
      const purchaseId = cell.objectID;
      const order_id = cell.order_id;
      const order_number = cell.order_number;
      const isPartnerOrder = cell?.isPartnerOrder;
      const authorizationHeader =
        'Bearer ' + authState?.accessToken?.accessToken;

      switch (actionType) {
        case actionTypeMapping.cancel.type:
          if (order_id === '--' || !order_id || order_number < 600000)
            await axios.post('/cancel/purchases', null, {
              params: {
                purchaseDocId: cell.objectID,
              },
              headers: { Authorization: authState?.accessToken?.accessToken },
            });
          else
            await axios.post(`/api/admin/orders/${order_id}/cancel`, null, {
              headers: { Authorization: authorizationHeader },
              baseURL: process.env.REACT_APP_OMS_BASE_URL,
            });
          await reloadPurchases();
          setShowModal(false);
          break;
        case actionTypeMapping.resend.type:
          if (order_id === '--' || !order_id || order_number < 600000)
            await axios.post(
              `/shop/resend-checkout-email/${purchaseId}`,
              null,
              {
                params: { isPartnerOrder },
              },
            );
          else
            await axios.post(
              `/api/admin/orders/resend-checkout-email/${order_id}`,
              {
                is_security_order: [
                  'security-deposit',
                  'security_deposit',
                ].includes(cell.dashBoardOrderType),
              },
              {
                headers: { Authorization: authorizationHeader },
                baseURL: process.env.REACT_APP_OMS_BASE_URL,
              },
            );
          break;
        case actionTypeMapping.fullfillment.type:
          await axios.patch(
            `/api/admin/orders/offline-order-status/${order_id}`,
            null,
            {
              headers: { Authorization: authorizationHeader },
              baseURL: process.env.REACT_APP_OMS_BASE_URL,
            },
          );
          break;
        case actionTypeMapping.refund.type:
          // if (refundDetails.amount! > cell.productInformation[0]?.price) {
          //   setToastType({
          //     show: true,
          //     type: 'error',
          //     message: `Refund amount cannot be greater than order amount (${cell.productInformation[0]?.price})`,
          //   });
          //   actionSuccessful = false;
          //   return;
          // }
          if (
            refundDetails.note !== undefined &&
            refundDetails.note !== '' &&
            refundDetails.note.length < 5
          ) {
            setToastType({
              show: true,
              type: 'error',
              message: 'Note must be at least 5 characters long',
            });
            return;
          }
          await axios.post(
            `/api/admin/orders/${order_id}/refund`,
            {
              amount: refundDetails.amount,
              reason: refundDetails.reason?.value,
              note: refundDetails.note !== '' ? refundDetails.note : undefined,
            },
            {
              headers: { Authorization: authorizationHeader },
              baseURL: process.env.REACT_APP_OMS_BASE_URL,
            },
          );
          setRefundDetails({
            amount: null,
            reason: null,
            note: undefined,
          });
          setShowModal(false);
          break;
      }
      setToastType({
        show: true,
        type: 'success',
        message: actionTypeMapping[actionType]?.successMessage,
      });
    } catch (error) {
      let errorMessage = '';
      if (cell.order_id === '--' || !cell.order_id) {
        if (isCustomAxiosError(error)) {
          errorMessage =
            error?.response?.data?.message ||
            actionTypeMapping[actionType]?.defaultError;
        }
      } else {
        errorMessage = handleAxiosError(
          error,
          'Something went wrong while creating order',
        );
      }
      setToastType({
        show: true,
        type: 'error',
        message: errorMessage,
      });
    } finally {
      setIsActionInProgress(false);
      // if (actionSuccessful) {
      //   setShowModal(false);
      // }
    }
  };

  const handleMenuItemClick = (menuItemType: string) => {
    handleClose();
    setActionType(menuItemType);

    if (
      menuItemType === actionTypeMapping.cancel.type ||
      menuItemType === actionTypeMapping.refund.type
    ) {
      setShowModal(true);
    } else {
      handleAction(menuItemType);
    }
  };

  return (
    <>
      <tr key={index}>
        <td>{cell.orderNumber}</td>
        <td>{cell.customerEmail}</td>
        <td>
          {cell.productInformation[0]?.title === 'Security Deposit' &&
          cell.productName === 'Event-space'
            ? cell.productInformation[0]?.title
            : cell.productName}
          {cell.isGlobalPrintOrder && (
            <>
              <br />
              <span className="user-annotation-text green-box">Print Hub</span>
            </>
          )}
          {cell.productName === 'Others' &&
          cell.productInformation?.[0]?.eventTypeOthersDetails
            ? ` (${cell.productInformation[0].eventTypeOthersDetails})`
            : ''}
        </td>
        <td>{cell.buildingName}</td>
        <td>{format(new Date(cell.createdOn), 'do MMM yyyy, hh:mm aaa')}</td>
        <td>
          <RayButton
            compact
            buttonType="secondary"
            disabled={isActionInProgress}
            onClick={() => {
              setActionType('view_details');
              setShowModal(true);
            }}
          >
            View Details
          </RayButton>
        </td>
        <td>
          {actionsArray.length > 0 && (
            <RayButton
              disabled={isActionInProgress}
              compact
              buttonType="secondary"
              onClick={handleClick}
            >
              Actions
              {open ? (
                <AiOutlineCaretUp size={14} />
              ) : (
                <AiOutlineCaretDown size={14} />
              )}
            </RayButton>
          )}
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            disablePortal={false}
            modifiers={{
              flip: { enabled: true },
              preventOverflow: { enabled: true, boundariesElement: 'window' },
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <div
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0px 4px 8px rgba(0,0,0,0.25)',
                  borderRadius: '4px',
                  padding: '8px 0',
                }}
              >
                {actionsArray.map(action => (
                  <MenuItem
                    key={action.type}
                    onClick={() => handleMenuItemClick(action.type)}
                  >
                    {action.label}
                  </MenuItem>
                ))}
              </div>
            </ClickAwayListener>
          </Popper>
        </td>
      </tr>
      <ModalDialog
        isOpen={showModal}
        showModal={setShowModal}
        fullWidth
        maxWidth={
          actionType === actionTypeMapping.view_details.type ? 'md' : 'xs'
        }
        title={actionTypeMapping[actionType]?.label}
      >
        {actionType === actionTypeMapping.view_details.type ? (
          <ViewPurchasesDetails
            order_id={cell.order_id}
            orderId={cell.objectID}
            buildingName={cell.buildingName}
            isCancelledOrder={hideCancelButton}
          />
        ) : actionType === actionTypeMapping.cancel.type ? (
          <>
            <div>Are you sure that you want to cancel this order?</div>
            <DialogActions>
              <RayButton
                compact
                buttonType="secondary"
                onClick={() => setShowModal(false)}
              >
                No
              </RayButton>
              <RayButton
                disabled={isActionInProgress}
                compact
                buttonType="primary"
                onClick={() => handleAction('cancel')}
              >
                Yes
              </RayButton>
            </DialogActions>
          </>
        ) : actionType === actionTypeMapping.refund.type ? (
          <RefundContent
            refundDetails={refundDetails}
            setRefundDetails={setRefundDetails}
            isActionInProgress={isActionInProgress}
            handleAction={handleAction}
          />
        ) : null}
      </ModalDialog>
    </>
  );
};

export default TableCell;
