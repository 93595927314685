import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  TextField,
  Card,
  CardContent,
  Typography,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { MdDelete, MdEdit } from 'react-icons/md';
import RayButton from 'components/RayButton';
import { useOktaAuth } from '@okta/okta-react';
import { format } from 'date-fns';
import { H5, H6 } from 'components/Typography';
import { deleteNote, editNote, getNotes, postComment } from './api';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import DialogModal from 'components/ModalDialog';

type Note = {
  _id: string;
  author: string;
  note_content: string;
  firebaseUserId: string;
  updated_at: Date;
  virtualOfficeId: string;
  author_email: string;
  created_at: string;
};

type NotesProps = {
  userInfo: any;
  virtualOfficeId: string;
  primaryUser: string;
  virtualOfficeAddress: string;
};

const useNotes = (virtualOfficeId: string, authState: any) => {
  const [notes, setNotes] = useState<Note[]>([]);

  const fetchNotes = useCallback(async () => {
    try {
      const notes = await getNotes({ virtualOfficeId, authState });
      setNotes(notes);
    } catch (error) {
      console.error('Failed to fetch notes:', error);
    }
  }, [authState, virtualOfficeId]);

  useEffect(() => {
    if (virtualOfficeId && authState) fetchNotes();
  }, [virtualOfficeId, authState, fetchNotes]);

  return { notes, setNotes, fetchNotes };
};

const AddNote = ({
  content,
  setContent,
  handleSubmit,
}: {
  content: string;
  setContent: (value: string) => void;
  handleSubmit: () => void;
}) => (
  <>
    <Grid item>
      <TextField
        label="Add a note"
        variant="outlined"
        multiline
        minRows={4}
        value={content}
        onChange={e => setContent(e.target.value)}
        fullWidth
      />
    </Grid>
    <Grid item style={{ marginBottom: '1rem' }}>
      <RayButton
        compact
        buttonType="primary"
        style={{ width: 'min-content', marginTop: '1em' }}
        onClick={handleSubmit}
        disabled={!content}
      >
        Add Note
      </RayButton>
    </Grid>
  </>
);

const NoteItem = React.memo(
  ({
    note,
    isBeingEdited,
    editableContent,
    setEditableContent,
    onDelete,
    onEdit,
    onCancelEdit,
    onSubmitEdit,
    canUserMakeChanges,
  }: {
    canUserMakeChanges: boolean;
    note: Note;
    isBeingEdited: boolean;
    editableContent: string;
    setEditableContent: (value: string) => void;
    onDelete: (id: string) => void;
    onEdit: (id: string, content: string) => void;
    onCancelEdit: () => void;
    onSubmitEdit: () => void;
  }) => {
    const inputBoxRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
      inputBoxRef?.current?.focus();
    }, [isBeingEdited]);
    const [showDialog, setShowDialog] = useState(false);
    return (
      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <Grid
          container
          direction="row"
          style={{ gap: '8px' }}
          alignItems="center"
        >
          <Grid item>
            <H5>
              {note.author}{' '}
              <span style={{ color: 'gray', fontWeight: 'lighter' }}>
                {`(${note.author_email})`}
              </span>
            </H5>
          </Grid>
          <Grid item>
            <H6 style={{ color: 'gray' }}>
              {format(new Date(note.created_at), 'dd/MM/yyyy h:mm a')}
            </H6>
          </Grid>
          <Grid item style={{ flex: 1 }} />
          {!isBeingEdited && canUserMakeChanges ? (
            <Grid
              item
              style={{ gap: '8px', display: 'flex', marginBottom: '8px' }}
            >
              <MdDelete
                color="red"
                size={28}
                cursor="pointer"
                onClick={() => setShowDialog(true)}
              />
              <DialogModal
                isOpen={showDialog}
                showModal={setShowDialog}
                title="Are you sure?"
                maxWidth="sm"
                fullWidth
              >
                <>
                  <DialogContent style={{ padding: '8px 12px' }}>
                    <Typography>
                      This action is not reversible. Are you sure you want to
                      delete this note?
                    </Typography>
                  </DialogContent>
                  <DialogActions style={{ marginTop: '4px' }}>
                    <RayButton
                      buttonType="secondary"
                      compact
                      onClick={() => setShowDialog(false)}
                      style={{ marginRight: '0.5rem' }}
                    >
                      Cancel
                    </RayButton>
                    <RayButton
                      danger
                      compact
                      onClick={() => {
                        onDelete(note._id);
                        setShowDialog(false);
                      }}
                    >
                      Delete
                    </RayButton>
                  </DialogActions>
                </>
              </DialogModal>
              <MdEdit
                size={28}
                cursor="pointer"
                onClick={() => onEdit(note._id, note.note_content)}
              />
            </Grid>
          ) : null}
        </Grid>
        <Card
          style={{
            width: '100%',
            border: isBeingEdited ? 'none' : '1px solid #dadada',
            boxShadow: 'none',
          }}
        >
          <CardContent style={isBeingEdited ? { padding: 0 } : {}}>
            {isBeingEdited ? (
              <>
                <textarea
                  value={editableContent}
                  ref={inputBoxRef}
                  style={{
                    width: '100%',
                    border: '1px blue solid',
                    padding: '18px',
                    minHeight: '100px',
                    resize: 'vertical',
                  }}
                  onChange={e => setEditableContent(e.target.value)}
                />
                <RayButton
                  compact
                  buttonType="secondary"
                  style={{
                    margin: '0.5rem 0rem',
                    marginRight: '8px',
                    padding: '0.5rem',
                  }}
                  onClick={onSubmitEdit}
                  disabled={!editableContent}
                >
                  Save
                </RayButton>
                <RayButton
                  compact
                  buttonType="secondary"
                  style={{ margin: '0.5rem 0rem', padding: '0.5rem' }}
                  onClick={onCancelEdit}
                >
                  Cancel
                </RayButton>
              </>
            ) : (
              <Typography
                variant="body2"
                component="p"
                style={{ whiteSpace: 'pre-line' }}
              >
                {note.note_content}
              </Typography>
            )}
          </CardContent>
        </Card>
      </div>
    );
  },
);

const Notes = ({
  userInfo = {},
  virtualOfficeId,
  primaryUser,
  virtualOfficeAddress,
}: NotesProps) => {
  const [content, setContent] = useState('');
  const [editableContent, setEditableContent] = useState('');
  const [noteBeingEdited, setNoteBeingEdited] = useState<string | null>(null);
  const { authState } = useOktaAuth();
  const { setToastType } = useAuthContext()!;
  const { notes, fetchNotes } = useNotes(virtualOfficeId, authState);

  const canUserMakeChanges =
    userInfo.isPartnerAdminUser ||
    userInfo.isBusinessManager ||
    userInfo.is_admin ||
    userInfo.is_super_admin;

  const handleDelete = async (noteId: string) => {
    try {
      await deleteNote({ authState, noteId });
      await fetchNotes();
      setToastType({ show: true, type: 'success', message: 'Note deleted' });
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error?.response?.data?.message,
      });
    }
  };

  const handleEdit = async () => {
    try {
      await editNote({
        authState,
        noteId: noteBeingEdited,
        noteContent: editableContent,
      });
      setNoteBeingEdited(null);
      setEditableContent('');
      await fetchNotes();
      setToastType({ show: true, type: 'success', message: 'Note edited' });
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error?.response?.data?.message,
      });
    }
  };

  const handleAddNote = async () => {
    try {
      const newNote = {
        author: userInfo.name,
        author_email: userInfo.email,
        created_at: new Date(),
        firebaseUserId: userInfo.firebaseUserId,
        note_content: content,
        virtualOfficeId,
        primaryUser,
        virtualOfficeAddress,
      };
      await postComment({ authState, noteObject: newNote });
      setContent('');
      await fetchNotes();
      setToastType({ show: true, type: 'success', message: 'Note added' });
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error?.response?.data?.message,
      });
    }
  };

  return (
    <Grid container direction="column">
      {canUserMakeChanges && (
        <AddNote
          content={content}
          setContent={setContent}
          handleSubmit={handleAddNote}
        />
      )}
      <Grid item container>
        {notes.map(note => (
          <NoteItem
            canUserMakeChanges={canUserMakeChanges}
            key={note._id}
            note={note}
            isBeingEdited={note._id === noteBeingEdited}
            editableContent={editableContent}
            setEditableContent={setEditableContent}
            onDelete={handleDelete}
            onEdit={(id, content) => {
              setNoteBeingEdited(id);
              setEditableContent(content);
            }}
            onCancelEdit={() => {
              setNoteBeingEdited(null);
              setEditableContent('');
            }}
            onSubmitEdit={handleEdit}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default Notes;
