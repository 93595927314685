import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsFileEarmarkText } from 'react-icons/bs';
import { useOktaAuth } from '@okta/okta-react';

const UploadCSV = () => {
  const [file, setFile] = useState<File | null>(null);
  const [loader, setLoader] = useState(false);
  const {  setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append('file', file as Blob);
      await axios.post('/buildings/upload/po-inventories', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: 'File Uploaded Successfully',
      });
    } catch (err) {
      const error: any = err;
      console.error(error);
      const errMsg =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Error while uploading the CSV file';

      setToastType({
        show: true,
        type: 'error',
        message: errMsg,
      });
    } finally {
      setLoader(false);
      setFile(null);
    }
  };
  const handleRemove = () => {
    setFile(null);
  };
  return (
    <CSVWrapper>
      <Grid className="label-container">
        <p className="heading">PO Inventory</p>
        <p>(Note- Please upload .csv file)</p>
      </Grid>
      <Grid className="file">
        {file ? (
          <Grid className="file-meta">
            <div className="file-name">
              <BsFileEarmarkText className="icon file-icon" />
              <span>{file.name}</span>
            </div>
            <div onClick={handleRemove}>
              <RiDeleteBinLine className="icon delete-icon" />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loader}
            >
              Save
            </Button>
          </Grid>
        ) : (
          <Grid>
            <input
              id="csv-upload"
              onChange={e => handleFileUpload(e)}
              disabled={loader}
              type="file"
              accept=".csv"
              style={{ display: 'none' }}
            />
          </Grid>
        )}
      </Grid>

      {!file && (
        <Button variant="contained" color="primary" disabled={loader}>
          <label htmlFor="csv-upload">Upload File</label>
        </Button>
      )}
    </CSVWrapper>
  );
};

export default UploadCSV;

const CSVWrapper = styled(Grid)`
  /* color: red; */
  .label-container {
    margin-bottom: 15px;
    .heading {
      font-weight: 600;
      font-size: 1.5rem;
    }
  }

  .file {
    margin-bottom: 10px;
    .icon {
      font-size: 1.2rem;
      display: flex;
    }
    .file-meta {
      display: flex;
      gap: 10px;
      align-items: center;
      .file-name {
        display: flex;
        align-items: center;
        span {
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .file-icon {
          color: #0000ff;
          margin-right: 10px;
        }
      }
      .delete-icon {
        color: #666;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
`;
